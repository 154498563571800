import React, { useEffect, useState } from 'react';
import Input from '../Input/Input.js';
import AppIcon from '../AppIcon/AppIcon.js';
import { useFormik } from 'formik';
import { registerSchema } from '../Validation/Validation.js';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

function InputRegister() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const initialValues = {
    userName: '',
    email: '',
    password: '',
    confirmPassword: '',
  };
  const [invalidData, setInvalidData] = useState(false);
  const [userNameIsThere, setuserNameIsThere] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema: registerSchema,
    onSubmit: values => {
      const fetch = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/signUp`, {
            signUpUserName: values.userName,
            signUpEmail: values.email,
            signUpPassword: values.password,
            confirmPassword: values.confirmPassword
          });
          console.log(response);
          if (response?.data.message == "Signed up successfully") {
            toast.success(t('InputesTranslation:signUpSuccessfully'), {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            navigate('/moreInfo', { state: { email: values.email } })
          }
          else if (response?.data.message == "Email is already exists") {
            setInvalidData(true);
          }
          else if (response?.data.message == "Username is already exists") {
            setuserNameIsThere(true);
          }
        } catch (error) {
          console.error('Register error:', error);
          toast.error(t('InputesTranslation:loginFailed'), {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      };
      fetch();
    }
  });

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    formik.setFieldTouched(fieldName, false);
    formik.handleChange(e);
  };

  const inpute = [
    {
      id: 'username',
      type: 'text',
      name: 'userName',
      title: t('InputesTranslation:userName'),
      value: formik.values.userName,
    },
    {
      id: 'email',
      type: 'email',
      name: 'email',
      title: t('InputesTranslation:userEmail'),
      value: formik.values.email,
    },
    {
      id: 'password',
      type: 'password',
      name: 'password',
      title: t('InputesTranslation:userPassword'),
      value: formik.values.password,
    },
    {
      id: 'confirmPassword',
      type: 'password',
      name: 'confirmPassword',
      title: t('InputesTranslation:confirmPassword'),
      value: formik.values.confirmPassword,
    },
  ];

  const renderInput = inpute.map((ele, index) => (
    <Input value={ele.value}
      type={ele.type}
      name={ele.name}
      id={ele.id}
      title={ele.title}
      onchange={handleInputChange}
      errors={formik.errors}
      touched={formik.touched}
      key={index}
    />
  ));

  useEffect(() => {
    setInvalidData(false);
  }, [formik.values.email]);

  useEffect(() => {
    setuserNameIsThere(false);
  }, [formik.values.userName]);
  return (
    <>
      <div className="card2  rounded-5 col-sm-10 col-md-12 ">
        <h2 className='card-title text-center mt-1 h2 text-success'>{t('InputesTranslation:createAccount')}</h2>
        <form onSubmit={formik.handleSubmit} style={{height:"670px"}}>
          {renderInput}
          <h4 className="text-center p-1 title4 col-md-13 col-sm-12 mx-auto">{t('InputesTranslation:orConnectVia')}</h4>
          {
            invalidData && (
              <p className="text-center text-danger">{t('InputesTranslation:emailAlreadyExists')}</p>
            )
          }
          {
            userNameIsThere && (
              <p className="text-center text-danger">userName already exists</p>
            )
          }
          <AppIcon />
          <p className='text-center text mt-3 ' style={{ marginLeft: '33px', marginRight: '33px' }}>{t('InputesTranslation:alreadyHaveAccount')} <Link to='/login' className="text-success">{t('InputesTranslation:logIn')}</Link></p>
          <button type="submit" className="d-flex flex-column align-items-center  btn btn-success w-75 p-md-3 mx-auto mb-3"  >{t('InputesTranslation:createAccountButton')}</button>
          <h2> </h2>
        </form>
      </div>
      <Toaster />
    </>
  );
}
export default InputRegister;


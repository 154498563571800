import React from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

const PhoneNumberInput = ({ phone, setPhone, t }) => {
    return (
        <div className="col-md">
            <h5 className="card-title">{t("AddStadiumTranslation:PhoneNumber")}</h5>
            <PhoneInput
                id="phoneInput"
                defaultCountry="ps"
                value={phone}
                onChange={(phone) => setPhone(phone)}
                style={{
                    marginTop: '0px',
                    backgroundColor: 'var(--body_background)',
                    color: 'var(--chat_text_color)',
                    borderColor: 'black',
                    padding: '8px',
                    width: '100%',
                    height: '50px'
                }}
                required
            />
            <div className="invalid-feedback">{t("AddStadiumTranslation:EnterPhoneNumber")}</div>
        </div>
    );
};

export default PhoneNumberInput;

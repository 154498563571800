import React, { Suspense, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.js';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './i18n.js';
import Loading from './Components/Loading/Loading.js';
import { GoogleOAuthProvider } from '@react-oauth/google';


const someAsyncOperation = () => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 2000);
  });
};

const RootComponent = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);

    someAsyncOperation()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });


    setTimeout(() => {
      setLoading(false);
    }, 8000);

  }, []);
  const clientId = process.env.REACT_APP_Client_ID;
  return (
    <React.StrictMode>
      <Suspense fallback={<Loading loading={loading} />}>
        <GoogleOAuthProvider clientId={clientId}>
          <App />
        </GoogleOAuthProvider>
      </Suspense>
    </React.StrictMode>
  );
};

ReactDOM.render(<RootComponent />, document.getElementById('root'));

reportWebVitals();

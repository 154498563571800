import { useState, useEffect } from "react";
import AllCards from "./AllCard.js";
import Format from "./Format.js";
import "./BookingPage.css";
import Header from "./Header.js";
import axios from "axios";
import { useLocation } from "react-router-dom";

const BookingPage = () => {
    const location = useLocation();
    const { city } = location.state || {};

    const [stadiums, setStadiums] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [locations, setLocations] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(city||"");
    const [filteredStadiums, setFilteredStadiums] = useState([]);
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);

    const getStadium = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_BEURL}/stadium/book`
            );
            if (response.data && Array.isArray(response.data.stadium)) {
                setStadiums(response.data.stadium);
                console.log(response.data.stadium);
            } else {
                setStadiums([]);
                console.error(
                    "Expected 'stadium' to be an array, but received:",
                    response.data.stadium
                );
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setStadiums([]);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchLocations = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BEURL}/location/getLocation`);
            const locationOptions = response.data.locData.map(location => ({
                value: location.locatioName,
                label: location.locatioName
            }));
            setLocations(locationOptions);
        } catch (error) {
            console.error("Error in fetching locations:", error);
        }
    };

    const handleFilter = () => {
        let filtered = stadiums.filter((stadium) => {
            const formatMatch =
                selectedFormats.length === 0 ||
                selectedFormats.includes(stadium.stadiumFormat);
            const amenitiesMatch = selectedAmenities.every((amenity) =>
                stadium.Amenities.includes(amenity)
            );
            const locationMatch =
                !selectedLocation || stadium.location === selectedLocation;

            return formatMatch && amenitiesMatch && locationMatch;
        });

        setFilteredStadiums(filtered);
    };

    useEffect(() => {
        getStadium();
        fetchLocations();
    }, []);

    useEffect(() => {
        handleFilter();
    }, [stadiums, selectedLocation, selectedFormats, selectedAmenities]);

    return (
        <div className="booking mt-5">
            <div className="row" style={{ marginTop: "20px" }}>
                <Header
                    onSearch={(location) => setSelectedLocation(location)}
                    stadiumsCount={filteredStadiums.length}
                    locations={locations}
                />
                <Format
                    onFilter={(formats, amenities) => {
                        setSelectedFormats(formats);
                        setSelectedAmenities(amenities);
                    }}
                />
                <AllCards
                    stadiums={filteredStadiums}
                    searchCity={selectedLocation}
                    isLoading={isLoading}
                />
            </div>
        </div>
    );
};

export default BookingPage;

import React from "react";
import Header from "./PlayHeader.js";
import CardList from "./CardList.js";


function PlayPage() {
  return (
    <>
      <Header />
      <div className="mx-auto mb-3">
        <CardList />
      </div>
    </>
  );
}

export default PlayPage;

import React, { useState, useContext } from "react";
import { PermMedia, Label, EmojiEmotions } from "@mui/icons-material";
import './Share.css';
import axios from "axios";
import Cookies from "universal-cookie";
import { UserContext } from "../../../Contexts/UserContext.js";
import { useTranslation } from 'react-i18next';
import { defaultProfilePic } from './../../Player/Profile/ProfileHeader/ProfileHeader.js';

const cookies = new Cookies();
const Share = ({ onShare }) => {
    const [postContent, setPostContent] = useState("");
    const [image, setImage] = useState(null);
    const [postData, setPostData] = useState(null); 
    const Posts = useContext(UserContext);
    const { t } = useTranslation();

    const handleShare = async () => {
        if (postContent.trim() !== "" || image) {
            const formData = new FormData();
            formData.append('postText', postContent ? postContent : " " );
            formData.append('dec', 'Your description');
            formData.append('Ncomments', 0);
            formData.append('Nlikes', 0); 

            if (image) {
                formData.append('image', image);
            }

            if(Posts.userData.email){
            try {
                const response = await axios.post(`${process.env.REACT_APP_BEURL}/post`, formData, {
                    headers: {
                        Authorization: `PRIVATE_USER_HIDDEN__${cookies.get('userToken')?.token}`,
                        visitorId:Posts?.visitorId
                    }
                });
               
                setPostContent("");
                setImage(null);
                if (typeof onShare === "function") {
                    onShare(response.data.post);
                }
            } catch (error) {
                console.error("Error posting data:", error);
            }
        }
        } else {
            console.log("Please enter some content or select an image to post!");
        }
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    return (
        <div className="share mt-3 mb-3">
            <div className="shareWrapper">
                <div className="shareTop">
                    <img className="shareProfileImg mb-5" src={Posts.userData?.profilePic?.secure_url ? Posts.userData?.profilePic?.secure_url : defaultProfilePic } alt="" />
                    <textarea 
                        placeholder={t("ShareTranslation:What's_on_your_mind", { name: Posts.userData?.name })}
                        className="shareInput mt-3"
                        value={postContent}
                        onChange={(e) => setPostContent(e.target.value)}
                    />
                </div>
                {image && (
                    <div className="selectedImage">
                        <img src={URL.createObjectURL(image)} alt="Selected" />
                    </div>
                )}
                <div className="shareBottom">
                    <div className="shareOptions">
                        <label htmlFor="file" className="shareOption">
                            <PermMedia htmlColor="tomato" className="shareIcon" />
                            <span className="shareOptionText">{t("ShareTranslation:Photo_or_Video")}</span>
                            <input
                                style={{ display: "none" }}
                                type="file"
                                id="file"
                                accept=".png,.jpeg,.jpg"
                                onChange={handleImageChange}
                            />
                        </label>
                        {/* <div className="shareOption">
                            <Label htmlColor="blue" className="shareIcon" />
                            <span className="shareOptionText">{t("ShareTranslation:Tag")}</span>
                        </div>
                        <div className="shareOption">
                            <EmojiEmotions htmlColor="goldenrod" className="shareIcon" />
                            <span className="shareOptionText">{t("ShareTranslation:Feelings")}</span>
                        </div> */}
                    </div>
                    <button className="shareButton" onClick={handleShare}>
                        {t("ShareTranslation:Share")}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Share;

import React from 'react'
import { Outlet } from 'react-router-dom'
import { Navbar } from './../Navbar/Navbar.js';
import Sidebar from './../Sidebar/Sidebar.js';
import Footer from './../Footer/Footer.js';
import UserContextProvider from '../Contexts/UserContext.js';
import StadiumContextProvider from '../Contexts/StadiumContixt.js';


const Layout = ({ user, setUser }) => {
  const isdashboard = user === 'adminDashboard' || user === 'stadiumDashboard';
  return (
    <>
      {

        user == "stadiumDashboard" || user == "adminDashboard"
          ? (
            <StadiumContextProvider>
              <Navbar user={user} setUser={setUser} />
            </StadiumContextProvider>
          ) :
          <UserContextProvider>
            <Navbar user={user} setUser={setUser} />
          </UserContextProvider>


      }




      {isdashboard ? <StadiumContextProvider><Sidebar user={user} /></StadiumContextProvider> :
        <>
          <Outlet />
          <Footer />
        </>
      }

    </>
  )
}

export default Layout;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DashboardCard } from './DashboardCard.js';

export const DashboardHeader = ({ stadiumData }) => {
  const [isActive, setIsActive] = useState(true);
  const [todayData, setTodayData] = useState({
    todaysBooking: 0,
    todaysRevenue: 0,
    todaysVisitors: 0
  });

  useEffect(() => {
    const fetchTodayData = async () => {
      try {
        const todayReservationsResponse = await axios.get('/api/reservations/today');
        const todayRevenueResponse = await axios.get('/api/revenue/today');
        const todayVisitorsResponse = await axios.get('/api/visitors/today');

        setTodayData({
          todaysBooking: todayReservationsResponse.data.count,
          todaysRevenue: todayRevenueResponse.data.amount,
          todaysVisitors: todayVisitorsResponse.data.count
        });
      } catch (error) {
        console.error('Error fetching today data:', error);
      }
    };

    fetchTodayData();
  }, []);

  const handleActiveClick = async () => {
    setIsActive(!isActive);
    try {
      // Assuming the endpoint to update stadium status is '/api/stadiums/:stadiumId/activate'
      const response = await axios.put(`/api/stadiums/${stadiumData.id}/activate`, { isActive });
      console.log(response.data);
    } catch (error) {
      console.error('Error updating stadium status:', error);
    }
  };

  return (
    <div className='row position-relative'>
      <div className='position-relative d-xl-flex'>
        <img
          src='https://th.bing.com/th/id/R.bce66d482c9f9158958aa5370efb9ec1?rik=sXO6IBGlZ1Cd6A&pid=ImgRaw&r=0'
          className='col-xl-6 mb-3 col-12'
          alt='Placeholder'
        />
        <div className='d-md-flex col-xl-6'>
          <div className='col-xl-6 col-md-6 ms-xl-3 pe-md-2'>
            <div className='col-xl-11 col-md-12 col-12'>
              <DashboardCard
                img={'assets/img.jpg'}
                text={"Today'sbooking"}
                number={todayData.todaysBooking}
                col1={'#30C7EC'}
                col2={'#168CE3'}
              />
            </div>
            <div className='col-xl-11 col-md-12 col-12'>
              <DashboardCard
                img={'assets/image 6 (1).png'}
                text={"Today'srevenue"}
                number={`$ ${todayData.todaysRevenue}`}
                col1={'#3CBA92'}
                col2={'#0BA360'}
              />
            </div>
          </div>
          <div className='col-xl-6 col-md-6 ps-md-2'>
            
            <div className='col-xl-11 col-md-12 col-12'>
              <DashboardCard img={'assets/img.jpg'} text={'TotalBooking'} number={'0K'} col1={'#2B5876'} col2={'#4E4376'} />
            </div>
            <div className='col-xl-11 col-md-12 col-12'>
              <DashboardCard
                img={'assets/image 6 (1).png'}
                text={'Totalrevenue'}
                number={'$ 0K'}
                col1={'#F98460'}
                col2={'#FF0844'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='col-xl-3 col-md-6 col-12'>
        <DashboardCard img={'assets/image 4.png'} text={"Today'svisitors"} number={todayData.todaysVisitors} col1={'#CCCE86'} col2={'#EEF24B'} />
      </div>
      <div className='col-xl-3 col-md-6 col-12'>
        
        <DashboardCard img={'assets/image 4.png'} text={'Totalvisitors'} number={'0K'} col1={'#BB6969'} col2={'#D436BC'} />
      </div>
      <div className='col'>
        <button className={`btn DashBtn ${isActive ? 'btn-success' : 'btn-secondary'}`} onClick={handleActiveClick}>
          {isActive ? 'Active' : 'Inactive'}
        </button>
      </div>
    </div>
  );
};

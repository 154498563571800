import { useState } from "react";
import { ModelForRequest } from "./ModelForRequest.js";
import {useTranslation} from "react-i18next";
import { defaultProfilePic } from "../../Profile/ProfileHeader/ProfileHeader.js";

export const ListItemWithProfile = ({ imageUrl, playerName,positions,idList ,user,userId}) => {
    const {t}=useTranslation()
    const [showModal, setShowModal] = useState(false);

    const handleRequestJoin = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    return (
        <li>
            <span className="name-Badmintion">
                <img src={defaultProfilePic} alt={`${playerName}'s profile`} className="profile-image" />
                {playerName}
            </span>
            <button className="btn">{t(`ModelRequestTranslation:Host`)}</button>
            <button className="btn btn-success" onClick={handleRequestJoin}>{t(`ModelRequestTranslation:RequestToJoin`)}</button>
            <ModelForRequest show={showModal} handleClose={handleCloseModal} positions={positions} idList={idList} user={user} userId={userId} />
        </li>
    );
}
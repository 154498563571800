import React from 'react';

const TeamMember = (props) => {
    const { name, role, imageSrc, linkedin, github, email } = props;

    return (
        <div className="cardTeam" style={{ height: "420px" }}>
            <div className="imgTeam-container ">
                <img className='imgTeam' src={imageSrc} alt={name} />
            </div>
            <h3 className='h3Team'>{name}</h3>
            <p className='pTeam'>{role}</p>
            <div className="iconsTeam">
                <a className='aTeam' href={linkedin} target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                <a className='aTeam' href={github} target="_blank" rel="noopener noreferrer"><i className="fab fa-github"></i></a>
                <a className='aTeam' href={`mailto:${email}`}><i className="fas fa-envelope"></i></a>
            </div>
        </div>
    );
};

export default TeamMember;
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import React from 'react'

const SkeletoonBookCard = ({ cards }) => {
        return Array(cards)
        .fill(0)
        .map((item,i) => (
            <div className="bookCardSkeletoon" key ={i}>
            <div className="bookSkeleLeft-col">
            <Skeleton variant="rectangular" width={390} height={310} count={1} />
            
            </div>
            <div className="bookSkeleRight-col">

            <Skeleton animation="wave" height={10} width="100%" /> 
            <Skeleton animation="wave" height={5} width="40%" />
            <Skeleton animation="wave" height={10} width="100%" />
            <Skeleton animation="wave" height={5} width="50%" />
            </div>
            </div>
        ));
    };
    

export default SkeletoonBookCard

//line-height: 0.5;
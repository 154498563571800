import React, { useState, useEffect } from "react";
import "./Card.css";
import Card from "./Card.js";
import "./CardList.css";
import axios from "axios";
import { useTranslation } from "react-i18next";
import SkeletonPlayCard from "./SkeletonPlayCard.js"; 

export default function CardList() {
  const { t } = useTranslation();
  const [noElement, setNoElement] = useState(4);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [cardData, setCardData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const loadMore = (event) => {
    event.preventDefault();
    setNoElement(noElement + 4);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BEURL}/reservation/play`
        );

        if (response.data) {
          setCardData(response.data);
          setIsLoading(false); 

        } else {
          console.error(
            "Expected 'stadium' to be an object, but received:",
            response.data.stadium
          );
        }
      } catch (error) {
        setIsLoading(true);
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const slicedCards = cardData.slice(0, noElement);


  return (
    <div>
      <div className="container mt-5 mx-auto">
        <div className="row">
          {isLoading ? (
            Array.from({ length: noElement }).map((index) => (
              <div key={index} className={windowWidth < 1200 ? "col" : "col-4"}>
                <SkeletonPlayCard />
              </div>
            ))
          ) : (
            slicedCards.map((card) => (
              <div key={card._id} className={windowWidth < 1200 ? "col" : "col-4"}>
                
                <Card
                  idReservation={card._id}
                  img={
                    card.stadiumData?.image?.secure_url
                  }
                  title={card.stadiumData?.stadiumName}

                  startDate={card?.start}
                  endDate={card?.end}
                  location={card?.stadiumData?.location}
                  lengthPlayerJoined={
                    card.list[0]?.players?.length > 0 ? card.list[0]?.players?.length : 0
                  }
                  stadiumFormat={card.stadiumData?.stadiumFormat}
                  idStadium={card.stadiumData?._id}
                  userName={card.hostData?.userName}
                  userId={card.hostData?._id}
                  profilePic={card.hostData?.profilePic}
                  idList={card.list[0]?._id}
                />
              </div>
            ))
          )}
        </div>
        {noElement < cardData.length && (
          <div className="center">
            <button
              className="btn btn-link text-success"
              style={{
                color: "green",
                backgroundColor: "transparent",
                border: "none",
              }}
              onClick={loadMore}
            >
              {t("PlayPageTranslation:SeeMore")}
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

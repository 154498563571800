import React, { useState, useEffect, useContext } from 'react';
import './EventCalendar.css';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { StadiumContext } from '../../../../Contexts/StadiumContixt.js';
import Event from './Event.js';
import EventForm from './EventForm.js';

const EventCalendar = () => {
  const { stadiumData } = useContext(StadiumContext);
  const [events, setEvents] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isAddingEvent, setIsAddingEvent] = useState(false);
  const [isUpdatingEvent, setIsUpdatingEvent] = useState(false);
  const [newEvent, setNewEvent] = useState({});
  const [newEventPrice, setNewEventPrice] = useState(0);
  const [selectedCurrency, setSelectedCurrency] = useState('USD');
  const [exchangeRates, setExchangeRates] = useState({});

  useEffect(() => {
    fetchDataFromDatabase();
    fetchExchangeRates();
  }, []);
  const reloadPage = () => {
    window.location.reload();
  };

  const fetchDataFromDatabase = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BEURL}/weeklyEvent/${stadiumData.id}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const fetchExchangeRates = async () => {
    try {
      const response = await axios.get('https://api.exchangerate-api.com/v4/latest/USD');
      setExchangeRates(response.data.rates);
    } catch (error) {
      console.error('Error fetching exchange rates:', error);
    }
  };

  const convertToUSD = (amount, currency) => {
    return amount / exchangeRates[currency];
  };

  const update = (event) => {
    setNewEvent(event);
    setIsUpdatingEvent(true);
  };

  const addEvent = async () => {
    try {
      const priceInUSD = convertToUSD(newEventPrice, selectedCurrency);
      const newEventData = { ...newEvent, price: priceInUSD };
      await axios.post(`${process.env.REACT_APP_BEURL}/weeklyEvent/${stadiumData.id}`, newEventData);
      setIsAddingEvent(false);
      setNewEvent({});
      setNewEventPrice(0);
      await fetchDataFromDatabase();
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  const updateEvent = async () => {
    try {
      const priceInUSD = convertToUSD(newEventPrice, selectedCurrency);
      const updatedEventData = { ...newEvent, price: priceInUSD };
      await axios.put(`${process.env.REACT_APP_BEURL}/weeklyEvent/${stadiumData.id}/${newEvent._id}`, updatedEventData);
      setIsUpdatingEvent(false);
      setNewEvent({});
      setNewEventPrice(0);
      await fetchDataFromDatabase();
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const removeEvent = async (eventId) => {
    try {
      await axios.delete(`${process.env.REACT_APP_BEURL}/weeklyEvent/${stadiumData.id}/${eventId}`);
      await fetchDataFromDatabase();
    } catch (error) {
      console.error('Error removing event:', error);
    }
  };

  const handleCellClick = (day, hour) => {
    setSelectedTime({ day, hour });
    setIsAddingEvent(true);
    setNewEvent({ day, start: hour, end: hour });
    setNewEventPrice(0);
  };

  const moveEvent = async (data, day, hour, eventId) => {
    try {
      const newDay = days[Math.floor(data.x / 80)];
      const newHour = hours[Math.floor(data.y / 50)];
      await axios.put(`${process.env.REACT_APP_BEURL}/weeklyEvent/${stadiumData.id}/${eventId}`, { day: newDay, start: newHour, end: newHour });
      await fetchDataFromDatabase();
    } catch (error) {
      console.error('Error moving event:', error);
    }
  };

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };

  const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const hours = Array.from({ length: 48 }, (_, i) => {
    const hour = Math.floor(i / 2);
    const minutes = i % 2 === 0 ? '00' : '30';
    return `${hour}:${minutes}`;
  });

  return (
    <div className="container mt-4">
      <h2 id="eventH" className="text-center mt-5">Events Calendar</h2>
      <div className="row mb-3 justify-content-end">
        <div className="col-auto">
          <Button variant="success" onClick={() => setIsAddingEvent(true)}>Add Event</Button>
        </div>
        <div className="col-auto">
          <select className="form-select" value={selectedCurrency} onChange={handleCurrencyChange}>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
          </select>
        </div>
      </div>
      <div className="table-container" style={{ overflowX: 'auto', maxHeight: '400px' }}>
        <table className="table table-bordered">
          <thead>
            <tr>
              <th></th>
              {days.map(day => <th key={day}>{day}</th>)}
            </tr>
          </thead>
          <tbody>
            {hours.map(hour => (
              <tr key={hour}>
                <td>{hour}</td>
                {days.map(day => (
                  <td
                    key={`${day}-${hour}`}
                    onDoubleClick={() => handleCellClick(day, hour)}
                    style={{ cursor: 'pointer' }}
                  >
                    {events.map((event) => {
                      if (event.start === hour && event.day === day) {
                        return (
                          <div key={`${day}-${hour}-${event._id}`} style={{ position: 'relative', width: 80, height: 50 }}>
                            <Event event={event} removeEvent={removeEvent} moveEvent={moveEvent} update={update} />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {selectedTime && <p className="text-center">Selected Time: {selectedTime.day}, {selectedTime.hour}</p>}
      <p className="text-center"><Link to="/" className="skipNowLink" onClick={reloadPage}>Skip now</Link></p>
      <Modal show={isAddingEvent} onHide={() => setIsAddingEvent(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{'Add Event'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventForm
            newEvent={newEvent}
            setNewEvent={setNewEvent}
            newEventPrice={newEventPrice}
            setNewEventPrice={setNewEventPrice}
            days={days}
            hours={hours}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setIsAddingEvent(false)}>Close</Button>
          <Button variant="primary" onClick={addEvent}>Add Event</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={isUpdatingEvent} onHide={() => setIsUpdatingEvent(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{'Update Event'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <EventForm
            newEvent={newEvent}
            setNewEvent={setNewEvent}
            newEventPrice={newEventPrice}
            setNewEventPrice={setNewEventPrice}
            days={days}
            hours={hours}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setIsUpdatingEvent(false)}>Close</Button>
          <Button variant="primary" onClick={updateEvent}>Update Event</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventCalendar;

import React, { useState } from 'react'
import './DashboardCard.css'
import { useTranslation } from 'react-i18next';
export const DashboardCard = ({ col1, col2, text, number, img }) => {
  const {t}=useTranslation()
  const color = ` linear-gradient( to right,${col1},${col2})`;
  return (
    <>
      <div className=" row text-center">
        <div className="mb-5">
          <div className=" rounded shadow-sm" style={{ backgroundImage: color }}><img src={img} alt width={100} className=" mt-3 cardImgDash img-fluid rounded-circle img-thumbnail shadow-sm mb-2" />
            <h5 className=" pCardDash">{t(`HeaderDashboardTranslation:${text}`)}</h5>
            <h4 className=" mt-4 pb-3 text-light">{number}</h4>
          </div>
        </div>
      </div>
    </>
  )
}

import React from 'react';

const StadiumFormatButtons = ({ selectedStadiumFormat, setSelectedStadiumFormat, t }) => {
    return (
        <div className="d-flex justify-content-between flex-column flex-md-row align-items-start align-items-md-center">
            <div className="mb-3 mb-md-0">
                <button
                    type="button"
                    className={`btn ${selectedStadiumFormat === '5 a side' ? 'btn-success' : 'btn-outline-success'} me-3`}
                    onClick={() => setSelectedStadiumFormat('5 a side')}
                >
                    {t("AddStadiumTranslation:5ASide")}
                </button>
                <button
                    type="button"
                    className={`btn ${selectedStadiumFormat === '7 a side' ? 'btn-success' : 'btn-outline-success'} me-3`}
                    onClick={() => setSelectedStadiumFormat('7 a side')}
                >
                    {t("AddStadiumTranslation:7ASide")}
                </button>
                <button
                    type="button"
                    className={`btn ${selectedStadiumFormat === '11 a side' ? 'btn-success' : 'btn-outline-success'} me-3`}
                    onClick={() => setSelectedStadiumFormat('11 a side')}
                >
                    {t("AddStadiumTranslation:11ASide")}
                </button>
            </div>
        </div>
    );
};

export default StadiumFormatButtons;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
const UpdateLocationModal = ({ isOpen, onClose, id ,locations}) => {
    const [locatioName, setLocationName] = useState('');
    const [firstLocationName, setFirstLocationName] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        const fetchData = async () => {
            try {
               
                const res = await axios.get(`${process.env.REACT_APP_BEURL}/location/getOneLocation/${id}`);
                setLocationName(res.data.locData.locatioName);  
                setFirstLocationName(res.data.locData.locatioName);

                setTimeZone(res.data.locData.timeZone);
            } catch (err) {
                console.error(err);
            }
        };
        if (id) {
            fetchData();
        }
    }, []);

    const validationForm = () => {
        let isValid = true;
        let Errors = {};
        locations.map((location) => {
            if(location.locatioName==locatioName && location.locatioName !=firstLocationName ) {
                Errors.locatioName = t("AdminLocationTranslation:locationNameErr1");
                isValid = false;
                
            }
        });
        if (!locatioName.trim() || locatioName.trim().length < 3|| locatioName.trim().length >15){
            const temp =locatioName.trim().length;
            if(temp<=3){
            Errors.locatioName = t("AdminLocationTranslation:locationNameErr2");
            }
            else if(temp>=15){
            Errors.locatioName = t("AdminLocationTranslation:locationNameErr3");
            } 
            isValid = false;
        }

        if (!timeZone.trim() || isNaN(timeZone) || Math.abs(Number(timeZone)) >= 20) {
            Errors.timeZone = t("AdminLocationTranslation:timeZoneErr");
            isValid = false;
        }

        setErrors(Errors);
        return isValid;
    };


    const handleUpdate = (e) => {
        e.preventDefault();
        if (validationForm()){

        axios.patch(`${process.env.REACT_APP_BEURL}/location/${id}`, { locatioName, timeZone })

            .then(res => {
                onClose();
                navigate('/location');
            })
            .catch(err => console.log(err));
        }
    };

    if (!isOpen) return null;

    return (
        <div id='locModal' className="modal">
            <div id='locModalContent' className="modal-content">
                <span className="close" onClick={onClose}>&times;</span>
                <form onSubmit={handleUpdate}>
                    <h2>{t("AdminLocationTranslation:updateLocation")}</h2>
                    <div className="mb-2">
                        <label>{t("AdminLocationTranslation:locationName")}</label>
                        <input
                            type="text"
                            className="form-control"
                            value={locatioName}
                            onChange={(e) => setLocationName(e.target.value)}
                        />
                        {errors.locatioName && <div className="text-danger">{errors.locatioName}</div>}
                    </div>
                    <div className="mb-2">
                        <label>{t("AdminLocationTranslation:timeZone")}</label>
                        <input
                            type="number"
                            className="form-control"
                            value={timeZone}
                            onChange={(e) => setTimeZone(e.target.value)}
                        />
                        {errors.timeZone && <div className="text-danger">{errors.timeZone}</div>}
                    </div>
                    <button className="btn btn-success">Update</button>
                </form>
            </div>
        </div>
    );
};

export default UpdateLocationModal;

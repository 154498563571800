import React, { useEffect, useState } from 'react'
import Input from '../Input/Input.js';
import { useFormik } from 'formik';
import { loginSchema } from '../Validation/Validation.js'
import AppIcon from '../AppIcon/AppIcon.js';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import Cookies from 'universal-cookie';
import { useTranslation } from 'react-i18next';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

function InputRegister() {


  const fpPromise = FingerprintJS.load();

  let [visitorId, setVisitorId] = useState(null);

  fpPromise
    .then(fp => fp.get())
    .then(result => {
      // This is the visitor identifier:
      setVisitorId(result.visitorId);

    })
    .catch(error => {
      console.error('Failed to get fingerprint:', error);
    });

  const { t } = useTranslation()
  const navigate = useNavigate();
  const cookies = new Cookies();
  const [invalidData, setInvalidData] = useState(false);
  const initialValues = {
    email: '',
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: values => {
      const fetch = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/signIn`, { signInEmail: values.email, signInPassword: values.password, visitorId });
          console.log(response);
          if (response?.data.message == " logged in successfully ") {
            cookies.set('userToken', { token: response.data.token, role: response.data.role }, { path: '/', secure: true, serverOnly: true });
            toast.success('Login successfully', {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });

            setTimeout(() => {
              navigate('/');
              window.location.reload();
            }, 1000);

            // if (response.data.role == "stadiumDashboard") {
            //   if (response.data.active) {
            //     navigate('/');
            //   }
            // }


          }
          else if (response?.data.message == "plz confirm your email") {
            navigate('/confirmEmail', { state: { email: values.email } });
          }
          else if (response?.data.message == "invalid data") {
            setInvalidData(true);
          }
        } catch (error) {
          console.error('Login error:', error);
          toast.error('Failed to login. Please try again.', {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      };
      fetch();
    }
  });

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    formik.setFieldTouched(fieldName, false);
    formik.handleChange(e);
  };
  const Inputes = [
    {
      id: 'email',
      type: 'email',
      name: 'email',
      title: t('InputesTranslation:email'),
      value: formik.values.email,
    },
    {
      id: 'password',
      type: "password",
      name: 'password',
      title: t('InputesTranslation:password'),
      value: formik.values.password,
    },
  ];

  useEffect(() => {
    setInvalidData(false);
  }, [formik.values.email, formik.values.password]);


  const renderInput = Inputes.map((ele, index) =>
    <Input value={ele.value}
      type={ele.type}
      name={ele.name}
      id={ele.id}
      title={ele.title}
      onchange={handleInputChange}
      errors={formik.errors}
      touched={formik.touched}
      key={index}
    />
  )
  return (
    <div>
      <div className="card2 Regester-card rounded-5 col-sm-12 mt-5">
        <h2 className="card-title text-center mt-3 h2 text-success">{t("InputesTranslation:welcomeBack")}</h2>
        <form onSubmit={formik.handleSubmit}>
          {renderInput}
          <h4 className="text-center p-1 title4 col-md-13 col-sm-12 mx-auto">{t('InputesTranslation:orConnectVia')}</h4>
          <div className="container" style={{ position: "relative" }}>
            <AppIcon />
          </div>
          {invalidData && (
            <p className="text-center text-danger">{t('InputesTranslation:invalidData')}</p>
          )}
          <p className="text-center mt-3">
            <Link to="/ForgetPassword" className="text-success ">{t('InputesTranslation:forgetPassword')}</Link>
          </p>
          <p className="text-center title7 " style={{ marginLeft: '33px', marginRight: '33px' }}>
            {t('InputesTranslation:dontHaveAccount')}
            <Link to="/register" className="text-success"> {t('InputesTranslation:signUp')}</Link>
          </p>
          <button type="submit" className="d-flex flex-column align-items-center mt-6 btn btn-success w-75 p-md-3 mx-auto mb-3">
            {t('InputesTranslation:login')}
          </button>
        </form>
      </div>
      <Toaster />
    </div>
  );
}

export default InputRegister;
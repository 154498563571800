import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import './Format.css';

const Format = ({ onFilter }) => {
    const { t } = useTranslation();
    const [selectedFormats, setSelectedFormats] = useState([]);
    const [selectedAmenities, setSelectedAmenities] = useState([]);

    const formatOptions = [
        {
            title: t('BookTranslation:Format.format'),
            options: [
                t('BookTranslation:Format.5-a-side pitch'),
                t('BookTranslation:Format.5 a side'),
                t('BookTranslation:Format.7 a side'),
                t('BookTranslation:Format.9 a side'),
                t('BookTranslation:Format.9-a-side pitch'),
                t('BookTranslation:Format.11 a side'),
                t('BookTranslation:Format.11-a-side pitch')
            ]
        },
        {
            title: t('BookTranslation:Format.Radius'),
            options: [
                t('BookTranslation:Format.Within 1 mile'),
                t('BookTranslation:Format.Within 3 miles'),
                t('BookTranslation:Format.Within 5 miles'),
                t('BookTranslation:Format.Within 10 miles'),
                t('BookTranslation:Format.Within 75 miles')
            ]
        },
        {
            title: t('BookTranslation:Format.Facilities'),
            options: [
                t('BookTranslation:Format.Changing Room'),
                t('BookTranslation:Format.Floodlights'),
                t('BookTranslation:Format.Parking'),
                t('BookTranslation:Format.Drinking Water'),
                t('BookTranslation:Format.First Aid'),
            ]
        }
    ];

    const handleSelectFormat = (option) => {
        if (selectedFormats.includes(option)) {
            setSelectedFormats(selectedFormats.filter((item) => item !== option));
        } else {
            setSelectedFormats([...selectedFormats, option]);
        }
    };

    const handleSelectAmenity = (option) => {
        if (selectedAmenities.includes(option)) {
            setSelectedAmenities(selectedAmenities.filter((item) => item !== option));
        } else {
            setSelectedAmenities([...selectedAmenities, option]);
        }
    };

    useEffect(() => {
        onFilter(selectedFormats, selectedAmenities);
    }, [selectedFormats, selectedAmenities]);

    return (
        <div className="format col-8 col-md-3 col-sm-3 col-lg-2 col mb-5 g-4" style={{ paddingLeft: "40px" }}>
            {formatOptions.map((category, index) => (
                <div key={index}>
                    <h5>{category.title}</h5>
                    <hr />
                    {category.options.map((option, optionIndex) => (
                        <div key={optionIndex} className="form-check">
                            <input
                                className="form-check-input"
                                type={category.title === t('BookTranslation:Format.Radius') ? 'radio' : 'checkbox'}
                                name={category.title === t('BookTranslation:Format.Radius') ? 'flexRadioDefault' : undefined}
                                id={`option-${index}-${optionIndex}`}
                                checked={category.title === t('BookTranslation:Format.Facilities') ? selectedAmenities.includes(option) : selectedFormats.includes(option)}
                                onChange={() => category.title === t('BookTranslation:Format.Facilities') ? handleSelectAmenity(option) : handleSelectFormat(option)}
                            />
                            <label className="form-check-label" htmlFor={`option-${index}-${optionIndex}`}>
                                {option}
                            </label>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

export default Format;

import React, { useEffect, useState } from 'react';
import Table_book from './BigCalender/Table_book.js';
import Payed from './BookForm/BookForm.js';
import './BookNow.css';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const BookNow = ({user}) => {
  const [events, setEvents] = useState([]);
  const [currantEvent, setCurrantEvent] = useState(null);
  const [lay, setLay] = useState(false);
  const [container, setContainer] = useState("");
  const {id} = useParams();

  const fetchDataFromDatabase = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BEURL}/weeklyEvent/${id}`);
      setEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  useEffect(() => {
    fetchDataFromDatabase();
  }, []);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setContainer("");
    }
  }, [lay]);

  const setCurrantEventId = (eventId) => {
  };
  
  return (
    <div className={container}>
      <div className="book-now-container mt-5 container mb-5">
        <div className="book-component mt-5">
          <h2 className='book-Title'>Book Now !</h2>
          <Table_book
            setLay={setLay}
            setCurrantEventId={setCurrantEventId}
            events={events}
            setCurrantEvent={setCurrantEvent}
            currantEvent={currantEvent}
            stadiumId = {id}
            user={user}
           
          />
        </div>
        {lay && (
          <div className="book-component">
            <Payed
              setLay={setLay}
              currantEvent={currantEvent}
              setCurrantEvent={setCurrantEvent}
              events={events}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BookNow;

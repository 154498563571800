import React from 'react'
import './Header.css'
import { Search } from './Search/Search'
import { useTranslation } from 'react-i18next'
export const Header = () => {
  const {t} = useTranslation();
  return (
    <>
    <div className={`headerImg`}>
        <div className=''>
        <div className={`headerContent d-flex align-items-center justify-content-center fs-1 text-white flex-column`}>
          <div>
            <h1 className={`mb-5 headerText`}>{t("HomeTranslation:Find")} </h1>
            <div className='mt-4'>
            <p className={` pt-5 h5 par`}>{t("HomeTranslation:title")}</p>
            </div>
          </div>
          <Search/>
        </div>
      </div>
      </div>
    </>
  )
}


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { DashboardCard } from './../Stadium/DashboardCard.js';
import Users from './Users.js';
import Stadiums from './Stadiums.js';

const AdminDashboard = () => {
    const [data, setData] = useState({
        todayBooking: 0,
        totalBooking: 0,
        todayRevenue: 0,
        totalRevenue: 0,
        todayVisitors: 0,
        totalVisitors: 0
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const todayBookingsResponse = await axios.get('/api/reservations/today');
                const totalBookingsResponse = await axios.get('/api/reservations/total');
                const todayRevenueResponse = await axios.get('/api/revenue/today');
                const totalRevenueResponse = await axios.get('/api/revenue/total');
                const todayVisitorsResponse = await axios.get('/api/visitors/today');
                const totalVisitorsResponse = await axios.get('/api/visitors/total');

                setData({
                    todayBooking: todayBookingsResponse.data.count,
                    totalBooking: totalBookingsResponse.data.count,
                    todayRevenue: todayRevenueResponse.data.amount,
                    totalRevenue: totalRevenueResponse.data.amount,
                    todayVisitors: todayVisitorsResponse.data.count,
                    totalVisitors: totalVisitorsResponse.data.count
                });
            } catch (error) {
                console.error('Error fetching dashboard data:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="row pt-5 d-flex justify-content-center">
                <div className='col-xl-3 col-md-4 col-6 '>
                    <DashboardCard
                        img={'assets/img.jpg'}
                        text={"Today'sbooking"}
                        number={data.todayBooking}
                        col1={'#30C7EC'}
                        col2={'#168CE3'}
                    />
                </div>
                <div className='col-xl-3 col-md-4 col-6 '>
                    <DashboardCard
                        img={'assets/img.jpg'}
                        text={"TotalBooking"}
                        number={data.totalBooking}
                        col1={'#2B5876'}
                        col2={'#4E4376'}
                    />
                </div>
                <div className='col-xl-3 col-md-4 col-6 '>
                    <DashboardCard
                        img={'assets/image 6 (1).png'}
                        text={"Today'srevenue"}
                        number={`$ ${data.todayRevenue}`}
                        col1={'#3CBA92'}
                        col2={'#0BA360'}
                    />
                </div>
                <div className='col-xl-3 col-md-4 col-6 '>
                    <DashboardCard
                        img={'assets/image 6 (1).png'}
                        text={"Totalrevenue"}
                        number={`$ ${data.totalRevenue}`}
                        col1={'#F98460'}
                        col2={'#FF0844'}
                    />
                </div>
                <div className='col-xl-3 col-md-4 col-6'>
                    <DashboardCard
                        img={'assets/image 4.png'}
                        text={"Today'svisitors"}
                        number={data.todayVisitors}
                        col1={'#CCCE86'}
                        col2={'#EEF24B'}
                    />
                </div>
                <div className='col-xl-3 col-md-4 col-6 '>
                    <DashboardCard
                        img={'assets/image 4.png'}
                        text={"Totalvisitors"}
                        number={data.totalVisitors}
                        col1={'#BB6969'}
                        col2={'#D436BC'}
                    />
                </div>
            </div>
            <Stadiums />
            <Users />
        </>
    );
}

export default AdminDashboard;

import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';

const BookingModel = ({ isOpen, onClose, handleAvailableEvent }) => {
  const [times, setTimes] = useState(1);
  const [selectedOption, setSelectedOption] = useState('');
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);
  const [additionalInputs, setAdditionalInputs] = useState({
    goalkeepers: 2,
    attackers: 2,
    midfielders: 2,
    defenders: 2,
  });

  useEffect(() => {
    setTimes(1);
    setSelectedOption('');
    setShowAdditionalInputs(false);
    setAdditionalInputs({
      goalkeepers: 2,
      attackers: 2,
      midfielders: 2,
      defenders: 2,
    });
  }, [isOpen]);

  const handleTimesChange = (e) => {
    const value = e.target.value;
    if (/^\d+$/.test(value)) {
      setTimes(value);
    }
  };

  const handleOptionChange = (e) => {
    const value = e.target.value;
    setSelectedOption(value);
    setShowAdditionalInputs(value === 'public');
  };

  const handleAdditionalInputChange = (e) => {
    const { name, value } = e.target;
    const updatedInputs = { ...additionalInputs, [name]: parseInt(value) };
    setAdditionalInputs(updatedInputs);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (/^\d+$/.test(times) && selectedOption.trim() !== '') {
      const total = additionalInputs.goalkeepers + additionalInputs.attackers + additionalInputs.midfielders + additionalInputs.defenders;
      if (total < 20) {
        handleAvailableEvent(times, selectedOption, additionalInputs);
        onClose();
      } else {
        alert('The total sum of Goalkeepers, Attackers, Midfielders, and Defenders must be less than 20.');
      }
    } else {
      alert('Please enter a valid number of times and select an option.');
    }
  };

  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Book Event</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit}>
          <div>
            {/* <input
              type="number"
              onChange={handleTimesChange}
              placeholder="Enter times (positive integer)"
              className="form-control"
              min="1"
              value={times}
            /> */}
          </div>
          <div>
            <select
              value={selectedOption}
              onChange={handleOptionChange}
              className="form-control"
              required
            >
              <option value="">Select</option>
              <option value="private">Private</option>
              <option value="public">Public</option>
            </select>
          </div>
          {showAdditionalInputs && (
            <div>
              <label htmlFor="goalkeepers">Goalkeepers:</label>
              <input
                id="goalkeepers"
                type="number"
                name="goalkeepers"
                value={additionalInputs.goalkeepers}
                onChange={handleAdditionalInputChange}
                className="form-control"
                min="1"
              />
              <label htmlFor="attackers">Attackers:</label>
              <input
                id="attackers"
                type="number"
                name="attackers"
                value={additionalInputs.attackers}
                onChange={handleAdditionalInputChange}
                className="form-control"
                min="1"
              />
              <label htmlFor="midfielders">Midfielders:</label>
              <input
                id="midfielders"
                type="number"
                name="midfielders"
                value={additionalInputs.midfielders}
                onChange={handleAdditionalInputChange}
                className="form-control"
                min="1"
              />
              <label htmlFor="defenders">Defenders:</label> {/* Added closing tag here */}
              <input
                id="defenders"
                type="number"
                name="defenders"
                value={additionalInputs.defenders}
                onChange={handleAdditionalInputChange}
                className="form-control"
                min="1"
              />
            </div>
          )}
          <Button variant="primary" type="submit" className="mt-2">
            Book
          </Button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default BookingModel;

import React, { useEffect } from 'react'
import AllPosts from './CommunityPost/AllPosts.js'
import UserContextProvider from '../../Contexts/UserContext.js';




function ChatPage() {
    useEffect(() => {
        document.body.style.backgroundColor = '#eee';
    }, []);

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col text-center" style={{ marginTop: "80px" }}>
                    <UserContextProvider>
                    <AllPosts />
                    </UserContextProvider>
                
                </div>
            </div>
        </div>
    )
}

export default ChatPage;
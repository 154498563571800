


export const FormatTimeRange =(startTimeString, endTimeString)=>{
    const formatTimeRange = (startTimeString, endTimeString) => {
        const startTime = new Date(startTimeString);
        const endTime = new Date(endTimeString);
    
        
        const startHour = startTime.getHours();
        const startMinute = startTime.getMinutes();
    
        
        const endHour = endTime.getHours();
        const endMinute = endTime.getMinutes();
    
        
        const formatHour = (hour) => {
            if (hour === 0) {
                return 12;
            } else if (hour > 12) {
                return hour - 12;
            } else {
                return hour;
            }
        };
    
        
        const formatMinute = (minute) => {
            return (minute < 10 ? '0' : '') + minute;
        };
    
        
        const startSuffix = startHour < 12 ? 'AM' : 'PM';
    
        
        const endSuffix = endHour < 12 ? 'AM' : 'PM';
    
        
        const formattedStartTime = `${formatHour(startHour)}:${formatMinute(startMinute)}${startSuffix}`;
    
        
        const formattedEndTime = `${formatHour(endHour)}:${formatMinute(endMinute)}${endSuffix}`;
    
        return `${formattedStartTime}-${formattedEndTime}`;
    };
    return(
        formatTimeRange(startTimeString, endTimeString)
    )
}
import React from 'react';

const EventForm = ({ newEvent, setNewEvent, newEventPrice, setNewEventPrice, days, hours }) => {
  return (
    <div className="mb-3">
      <select className="form-select mb-3" value={newEvent.day || ''} onChange={e => setNewEvent({ ...newEvent, day: e.target.value })}>
        <option value="">Select Day</option>
        {days.map(day => <option key={day} value={day}>{day}</option>)}
      </select>
      <select className="form-select mb-3" value={newEvent.start || ''} onChange={e => setNewEvent({ ...newEvent, start: e.target.value })}>
        <option value="">Select Start Time</option>
        {hours.map(hour => <option key={hour} value={hour}>{hour}</option>)}
      </select>
      <select className="form-select" value={newEvent.end || ''} onChange={e => setNewEvent({ ...newEvent, end: e.target.value })}>
        <option value="">Select End Time</option>
        {hours.map(hour => <option key={hour} value={hour}>{hour}</option>)}
      </select>
      <div className="mb-3">
        <label htmlFor="priceInput" className="form-label">Price:</label>
        <input
          id="priceInput"
          type="number"
          className="form-control"
          placeholder="Price"
          value={newEventPrice}
          onChange={(e) => setNewEventPrice(parseFloat(e.target.value))}
        />
      </div>
    </div>
  );
};

export default EventForm;

import React, { useState, useEffect, useContext } from 'react';
import './Notifications.css';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import NotificationsSkeleton from './NotificationsSkeleton.js';
import {StadiumContext} from '../../Contexts/StadiumContixt.js';
import {UserContext} from '../../Contexts/UserContext.js';

const Notifications = () => {
    const stadiumContext = useContext(StadiumContext);
    const userContext = useContext(UserContext);
    const userData = userContext ? userContext.userData : null; 
    const stadiumData = stadiumContext? stadiumContext.stadiumData : null;
    
    
    const [id,setId]=useState('')
    const [type,setType] =useState('')
    const [notifications, setNotifications] = useState([]);
    const [allDismissed, setAllDismissed] = useState(false);
    const { t } = useTranslation();
    useEffect(()=>{
        if(userData){
            setId(userData?.id)
            setType('user')
        }
        else {
            setId(stadiumData?.id)
            setType('stadium')
        }
        
    },[userData,stadiumData])
useEffect(() => {
        if (id) {
            axios.get(`${process.env.REACT_APP_BEURL}/notifications/${id}/${type}`)
                .then(res => {
                    setNotifications(res.data);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }, [id]); 

const dismissNotification = async (e, id) => {
    e.preventDefault();
    try {
        await axios.delete(`${process.env.REACT_APP_BEURL}/notifications/${id}`);
    } catch (error) {
        console.log(error);
    }
}

const dismissNotificationOnAcc = async (e, id) => {
    e.preventDefault();
    try {
        await axios.delete(`${process.env.REACT_APP_BEURL}/notifications/${id}/accept`);
    } catch (error) {
        console.log(error);
    }
}

const dismissAllNotifications = async (e) => {
    e.preventDefault();
    const notificationCards = document.querySelectorAll('.notification-card');
    notificationCards.forEach(async (card) => {
        card.classList.add('d-none');
        const id = card.getAttribute('data-id');
        try {
            await axios.delete(`${process.env.REACT_APP_BEURL}/notifications/${id}`);
        } catch (error) {
            console.log(error);
        }
    });
    setAllDismissed(true);
}

return (
    <div className="container mt-5 center">
        <div className="notifications-row row notification-container">
        <h2 className="text-center">{t("NotificationsTranslation:My_Notifications")}</h2>
        
            <p className="dismiss text-right" style={{ direction: 'rtl' }}>
            <a id="dismiss-all" href="#" onClick={dismissAllNotifications}>{t("NotificationsTranslation:Dismiss_All")}</a>
            </p>
            { notifications.map((notification, index) => (
                
                <div key={index} data-id={notification._id} className={`card notification-card notification-${notification.type}`}>
                    <div className="card-body">
                        <table>
                            <tbody>
                                <tr>
                                    <td style={{ width: '70%' }}>
                                        <div className="card-title">{notification.content}</div>
                                    </td>
                                    <td style={{ width: '30%' }}>
                                        <a className="btn btn-danger dismiss-notification" onClick={(e) => dismissNotification(e, notification._id)}>{t("NotificationsTranslation:Dismiss")}</a>
                                        <a className="btn btn-success accept-notification"onClick={(e) => dismissNotificationOnAcc(e, notification._id)}>{t("NotificationsTranslation:Accept")}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            ))}
            {allDismissed && (
                <p className="text-center ALL">{t("NotificationsTranslation:All_caught_up!")}</p>
            )}
        </div>
    </div>
);
}

export default Notifications;

import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import "./ProfileHeader.css"
import { UserContext } from "../../../../Contexts/UserContext.js";
import { useTranslation } from "react-i18next";


export const defaultProfilePic = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg";
const defaultCoverPic = "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9kKxFORe9pa0OhDDuriKT29YP5il_2X_Z-7FvXTDtIA&s.jpg";



function ProfileHeader() {
    const { t } = useTranslation();
    const { id } = useParams();
    const currantUser = useContext(UserContext)
    const [userData, setUserData] = useState({});
    const [active, setActive] = useState("t(`ChatTranslation:Posts`)");

    const navItems = [
        { label: t(`ChatTranslation:Posts`), path: "" },
        // { label: t(`ChatTranslation:About`), path: "" },
        // { label: t(`ChatTranslation:Videos`), path: "" },
        { label: t(`ChatTranslation:Events`), path: "books" },
        // { label: t(`ChatTranslation:Activity`), path: "" }
    ];
    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BEURL}/user/${id}`);
            setUserData(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
    }, [id]);

    const formatDate = (dateString) => {
        if (!dateString) return "";
        const date = new Date(dateString);
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const formattedDate = date.toLocaleDateString(undefined, options);
        const monthName = date.toLocaleString(undefined, { month: 'long' });
        return formattedDate.replace(monthName, t(`ChatTranslation:${monthName}`));
    };

    return (
        <div className="headerCard card py-3 px-3 mx-auto" style={{ borderRadius: "10px", marginTop: "60px", width: "99%" }}>
            <div className="profileBackground background-image">
                <img
                    className="card-img-top big-profile-photo"
                    src={userData?.coverPic?.secure_url || defaultCoverPic}
                    alt="..."
                    style={{ width: "100%", maxHeight: "400px" }}
                />
            </div>
            <div className="headerCardBody card-body py-0">
                <div className="d-sm-flex align-items-start text-center text-sm-start">
                    <div>
                        <div className="profileAvatar avatar-xxl mt-n5 mb-3" style={{ width: "210px", height: "190px", marginTop: "-68px" }} >
                            <img
                                className="avatar-img rounded-circle border border-white border-3"
                                src={userData?.profilePic?.secure_url || defaultProfilePic}
                                alt=""
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                    </div>
                    <div className="ms-sm-4 mt-sm-3">
                        <h1 className="ProfileName mb-0 h4">
                            {userData?.userName}
                        </h1>
                    </div>
                    {currantUser.userData?.id == id && (
                        <div className="d-flex mt-3 justify-content-center ms-sm-auto">
                            <Link to="/editProfile" className="btn btn-danger-soft me-2" style={{ background: "#fae8eb", color: "#dc3545" }}>
                                <i className="bi bi-pencil-fill pe-1" /> {t(`ChatTranslation:Edit_Profile`)}
                            </Link>
                        </div>)
                    }
                </div>
                <ul className="profileInfo list-inline mb-0 text-center text-sm-start mt-4 mt-sm-2 ">
                    <li className="list-inline-item">
                        <i className="bi bi-person"></i> {userData?.position || t(`ChatTranslation:No_location`)}
                    </li>
                    <li className="list-inline-item">
                        <i className="bi bi-geo-alt me-1" /> {userData?.location || t(`ChatTranslation:No_position`)}
                    </li>
                    <li className="list-inline-item">
                        <i className="bi bi-calendar2-plus me-1" /> {formatDate(userData?.createdAt)}
                    </li>
                </ul>
            </div>
            <div className="bottomSideCard card-footer mt-3 pt-2 pb-0 mx-3">
                <ul id="responsive-nav" className="nav nav-bottom-line align-items-center justify-content-center justify-content-md-start mb-0 border-0">
                    {navItems.map((item, index) => (
                        <li key={index}>
                            <Link
                                to={item.path}
                                className={`nav-link ${active === item.label ? "active" : "non-active"}`}
                                onClick={() => setActive(item.label)}
                            >
                                {item.label}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}

export default ProfileHeader;

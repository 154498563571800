import {useState} from 'react';
import './TableOfUsers.css';
import UserRow from './UserRow';
import * as XLSX from 'xlsx';
import {useTranslation} from 'react-i18next';


export const TableOfUsers = (props) => {
    const {t}=useTranslation()
    const [filter, setFilter] = useState({
        id: '',
        name: '',
        email: '',
        totalSpent: '',
        bookingInfo: ''
    });

    const [showFilter, setShowFilter] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;

    const handleFilterChange = (e) => {
        const {name, value} = e.target;
        setFilter({...filter, [name]: value});
    };

    const toggleFilter = () => {
        setShowFilter(!showFilter);
    };

    const filteredUsers = props.usersData.filter(user => {
        return (
            user.id.toString().includes(filter.id) &&
            user.userName.toLowerCase().includes(filter.name.toLowerCase())
            // user.email.includes(filter.email) &&
            // user.totalSpent.includes(filter.totalSpent)
        );
    });

    const totalPages = Math.ceil(filteredUsers.length / itemsPerPage);

    const paginatedUsers = filteredUsers.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
    const exportToExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(props.usersData);
        XLSX.utils.book_append_sheet(wb, ws, "UsersData");
        XLSX.writeFile(wb, "users_data.xlsx");
    };

    return (

        <div style={{marginTop: '80px'}}>
            <div className='container mt-5 mb-3'>
                <div className='card table-card'>
                    <div className='panel panel-primary filterable'>
                        <div className='panel-heading'>
                            <h4 className='panel-title' style={{float: 'left'}}>{t(`BodyDashboardTranslation:${props.title}`)}</h4>
                            <button className='btn btn-default btn-xs btn-filter' style={{float: 'right'}} onClick={toggleFilter}>
                                <i className="bi bi-filter"></i>
                            </button>
                            <button className="btn btn-default btn-xs btn-filter" onClick={exportToExcel} style={{float: 'right', marginRight: '10px'}}>
                                <i className="bi bi-download"></i>
                            </button>
                        </div>
                        <table className='table tableofusers table-striped'>
                            <thead>
                                {!showFilter && (
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col" style={{whiteSpace: "nowrap"}}>{t('BodyDashboardTranslation:Name')}</th>
                                        <th scope="col" style={{whiteSpace: "nowrap"}}>{t('BodyDashboardTranslation:Email')}</th>
                                        <th scope="col" style={{whiteSpace: "nowrap"}}>{t('BodyDashboardTranslation:TotalSpent')}</th>
                                        {props.showBookingInfo && <th scope="col" style={{whiteSpace: "nowrap"}}>{t('BodyDashboardTranslation:BookingInfo')}</th>}
                                        {props.action && (<th scope="col">{t('BodyDashboardTranslation:Action')}</th>)} {/* Add Action column */}
                                    </tr>
                                )}
                                {showFilter && (
                                    <tr className='filters'>
                                        <th>
                                            <input type='text' className='form-control' placeholder='#' name='id' value={filter.id} onChange={handleFilterChange} style={{width: '50px'}} />
                                        </th>
                                        <th>
                                            <input type='text' className='form-control' placeholder={t('BodyDashboardTranslation:Name')} name='name' value={filter.name} onChange={handleFilterChange} style={{width: 'auto'}} />
                                        </th>
                                        <th>
                                            <input type='text' className='form-control' placeholder={t('BodyDashboardTranslation:Email')} name='email' value={filter.email} onChange={handleFilterChange} style={{width: 'auto'}} />
                                        </th>
                                        <th>
                                            <input type='text' className='form-control' placeholder={t('BodyDashboardTranslation:TotalSpent')} name='totalSpent' value={filter.totalSpent} onChange={handleFilterChange} style={{width: 'auto'}} />
                                        </th>
                                        {props.showBookingInfo && <th> <input type='text' className='form-control' placeholder={t('BodyDashboardTranslation:BookingInfo')} name='bookingInfo' value={filter.bookingInfo} onChange={handleFilterChange} style={{width: 'auto'}} /></th>}
                                        <th></th> {/* Empty cell for Action column in filter row */}
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {paginatedUsers.map((user, index) => (
                                    <tr key={user.id}>
                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td> {/* Display adjusted index */}
                                        <UserRow
                                            id={user.id}
                                            avatarSrc={user.avatarSrc}
                                            userName={user.userName}
                                            email={user.email}
                                            totalSpent={user.totalSpent}
                                            showBookingInfo={props.showBookingInfo}
                                            bookingInfo={user.bookingInfo}
                                            privateID={user.privateID}
                                            setDeletedUser={props.setDeletedUser}
                                            deletedUser={props.deletedUser}
                                            action={props.action}
                                        />
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <nav aria-label="Page navigation example">
                            <ul className="pagination pageNumber">
                                <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setCurrentPage(currentPage - 1)}>{t('BodyDashboardTranslation:Previous')}</button>
                                </li>
                                {Array.from({length: totalPages}, (_, index) => (
                                    <li key={index} className={`page-item ${currentPage === index + 1 ? 'active' : ''}`}>
                                        <button className="page-link" style={currentPage === index + 1 ? {color: 'white', backgroundColor: 'rgba(49, 193, 90, 0.9)'} : {color: 'black'}} onClick={() => setCurrentPage(index + 1)}>{index + 1}</button>
                                    </li>
                                ))}
                                <li className={`page-item ${currentPage === totalPages ? 'disabled' : ''}`}>
                                    <button className="page-link" onClick={() => setCurrentPage(currentPage + 1)}>{t('BodyDashboardTranslation:Next')}</button>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableOfUsers;

import React from 'react';

const AboutStadiumTextarea = ({ aboutStadium, setAboutStadium, t }) => {
    const aboutStadiumPlaceholder = t("AddStadiumTranslation:AddMoreAboutStadium");

    return (
        <div className="card-body">
            <h5 className="card-title">{t("AddStadiumTranslation:AboutStadium")}</h5>
            <textarea
                className="Register-form-control green-border mb- mb-md-0 custom-input"
                placeholder={aboutStadiumPlaceholder}
                value={aboutStadium}
                onChange={(e) => setAboutStadium(e.target.value)}
            ></textarea>
        </div>
    );
};

export default AboutStadiumTextarea;

import React from 'react'
import InputRegister from '../Register/InputRegister.js'
import InputLogin from '../LogIn/InputLogin.js'
import './../LogIn/Login.css'
import InputInformation from '../MoreInfo/InputInformation.js'
import ConfirmEmail from '../ConfirmEmail/ConfirmEmail.js'
import InputStadium from '../AddStadium/InputStadium.js'
import InputForgetPassword from '../ForgetPassword/InputForgetPassword.js'
import DarkMode from '../../../DarkMode/DarkMode.js'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

export default function Base({ inputType }) {
  const { t } = useTranslation();


  return (
    <div>
      <div className=" backgroug">
        <div className='d-flex'>
          <button className="btn text-center mt-1" style={{ width: "auto", color: "gray", marginLeft: "10px" }}>
            <Link to="/" className="text text-decoration-none">
              <i className="bi bi-arrow-left-circle arrrr" style={{ fontSize: "20px" }}></i>
            </Link>
          </button>
          <div style={{ marginTop: "12px" }}>
            {!(inputType === 'InputStadium') && <DarkMode />}
          </div>
          <div className="nav-item dropdown  m-3 auth-language" style={{marginBottom:"-5px"}}>
            <a href='' className="bi bi-translate auth-language-icon" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
            <ul className="dropdown-menu dropdown-menu-end auth-language-dropdown" aria-labelledby="navbarDropdown">
              <li><button className="dropdown-item auth-language-item" onClick={() => { i18n.changeLanguage("ar") }} >{t("HomeTranslation:ar")}</button></li>
              <li><hr className="dropdown-divider auth-language-divider" /></li>
              <li><button onClick={() => { i18n.changeLanguage("en") }} className="dropdown-item auth-language-item" >{t("HomeTranslation:en")}</button></li>
            </ul>
          </div>
        </div>
        <div className="d-flex justify-content-center align-content-center p-4">
          <div className="col-md-5  col-sm-10  ">
            {inputType === 'Inputes' && <InputRegister />}
            {inputType === 'InputesLogin' && <InputLogin />}
            {inputType === 'InputesInformation' && <InputInformation />}
            {inputType === 'InputesEmail' && <ConfirmEmail />}
            {inputType === 'InputStadium' && <InputStadium />}
            {inputType === 'ForgetPasswords' && <InputForgetPassword />}
          </div>
        </div>
      </div>
    </div>
  )
}

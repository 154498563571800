
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import Images from './Images';
import Location from './Location';
import Down from './Down';
import './AllStadiumBookPage.css';
import axios from 'axios';
import Loading from '../../../Loading/Loading';
import StadiumSkeletonCard from './StadiumSkeletonCard.js';


function AllStadiumBookPage({ user }) {

    const [stadium, setStadium] = useState(null);
    
    let {id}=useParams();
    const [images, setImages] = useState([]);
    const [amenities, setAmenities] = useState([]);
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/staduemBook/${id}`);
                if (response.data && response.data.stadium) {
                    const stadiumData = response.data.stadium;
                    setStadium(stadiumData);
                    setImages(stadiumData.image || []);
                    setAmenities(stadiumData.Amenities || []);
                    
                } else {
                    console.error("Expected 'stadium' to be an object, but received:", response.data.stadium);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, []);

    if (!stadium) {
        return <StadiumSkeletonCard cards={1} />;
    }

    return (
        <div className='container position-relative justify-content-center h-auto mt-5' style={{ margin: 'auto' }}>
            <div className='all-page container position-relative d-flex justify-content-center align-items-center' >
                <Images stadiumData={images} />
                <Location user={user} stadiumUrl={stadium.locationURL} stadiumName={stadium.stadiumName} stadiumFormat={stadium.stadiumFormat} stadiumLocation={stadium.location} aboutStadium={stadium.stadiumFormat} id={stadium._id} averageRating={stadium.averageRating} />
            </div>
            <div className='container justify-content-center' style={{ paddingLeft: '0px' , marginTop:"80px"}}>
                <Down stadiumAmenities={amenities} aboutStadium={stadium.aboutStadium}/>
            </div>
        </div>
    );
}

export default AllStadiumBookPage;
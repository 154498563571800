import React from 'react'
import './AppIcon.css'
import Icon from '../Icon/Icon.js'
export default function AppIcon() {
  return ( 
  <>
 
  <Icon/>

  </>
  )
}

import { useState } from 'react';
import axios from 'axios';
import './PasswordForm.css';
import Cookies from 'universal-cookie';
import {useTranslation} from "react-i18next";
    

const PasswordForm = ({email,id,}) => {
    const cookies = new Cookies();
    const {t}=useTranslation()
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [showPassword, setShowPassword] = useState(false);
    const [message, setMessage] = useState('');

    const hidePassword = () => {
        setShowPassword(!showPassword);
    };

    const validatePasswords = () => {
        if (!currentPassword) {
            return 'Current password is required';
        }
        if (!newPassword) {
            return 'New password is required';
        }
        if (newPassword.length < 6) {
            return 'New password must be at least 6 characters long';
        }
        if (newPassword !== confirmPassword) {
            return 'New password and confirm password do not match';
        }
        return ;
    };

    const handleChangePassword = async (e) => {
        e.preventDefault();
        setMessage('');

        const validationError = validatePasswords();
        if (validationError) {
            setMessage(validationError);
            return;
        }

        try {
            const token = cookies.get('userToken');
            if (!token) {
                setMessage('User token is missing');
                return;
            }

            const response = await axios.patch(
                `${process.env.REACT_APP_BEURL}/user/updatePassword`,
                {
                    currentPassword,
                    newPassword,
                    confirmPassword
                },
                {
                    headers: {
                        authorization: `PRIVATE_USER_HIDDEN__${token.token}`
                    }
                }
            );

            setMessage(response.data.message);
        } catch (error) {
            console.error('Error updating password:', error);
            setMessage(error.response?.data?.message || 'An error occurred');
        }
    };

    return (
        <div className="card-border">
            <div className="card-header border-bottom">
                <h4 className="card-header-title-email">{t('SettingsTranslation:UpdatePassword') }</h4>
                <p className="mb-0"> {t('SettingsTranslation:EnterEmail') }<span className="text-primary">{email}</span></p>
            </div>
            <form className="card-body" onSubmit={handleChangePassword}>
                <div className="mb-3">
                    <label className="form-label">{t('SettingsTranslation:CurrentPassword') }</label>
                    <input
                        className="form-control"
                        type="password"
                        placeholder={t('SettingsTranslation:EnterCPassword') }
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                    />
                </div>
                <div className="mb-3">
                <label className="form-label"> {t('SettingsTranslation:EnterNewPassword') }</label>  
                </div>
                <div className="mb-3">
                    <div className="input-group">
                        <input
                            className="form-control"
                            placeholder={t('SettingsTranslation:EnterNewPassword') }
                            type={showPassword ? "text" : "password"}
                            id="psw-input"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <span className="input-group-text p-0 bg-transparent" onClick={hidePassword}>
                            <i id='iconShowPass' className={`fakepasswordicon fas ${showPassword ? "fa-eye" : "fa-eye-slash"} cursor-pointer p-2`} />
                        </span>
                    </div>
                </div>
                <div className="mb-3">
                    <label className="form-label">{t('SettingsTranslation:ConfirmPassword') }</label>
                    <input
                        className="form-control"
                        type="password"
                        placeholder={t('SettingsTranslation:ConfirmPassword') }
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                </div>
                {message && <div className="alert alert-info">{message}</div>}
                <div className="text-end">
                    <button className="btn btn-primary mb-0 settings-button" type="submit">{t('SettingsTranslation:ChangePassword') }</button>
                </div>
            </form>
        </div>
    );
};

export default PasswordForm;

import React, { useState, useEffect } from "react";
import Loading from './Components/Loading/Loading.js';
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import roots from "./Components/Layout/Roots.js";
import Cookies from "universal-cookie";

const cookies = new Cookies();

function App() {

  const fetchDataFromDatabase = async () => {
    try {
      const response = await fetch("#");
      const data = await response.json();
      return data;
    } catch (error) {
      throw new Error("Error fetching data from the database");
    }
  };


  let [user, setUser] = useState(cookies.get('userToken')?.role); // null || 1 : for player || "stadiumDashboard" : for stadium || "adminDashboard" : for admin


  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    fetchDataFromDatabase()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
    setTimeout(() => {
      setLoading(false);
    }, 8000);
  }, []);


  const routs = roots(user, setUser);
  const router = createBrowserRouter(routs
  );
  
  return (
    <>
      {loading && <Loading loading={loading} />}
      <RouterProvider router={router} />
    </>
  );
}

export default App;

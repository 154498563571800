import React from 'react'
import Base from '../Base/Base.js'

export default function () {
  return (
    <div>
    <Base inputType={'InputesEmail'}/>
    </div>
  )
}

import Header from "../AddstadiumInfo/Header.js";
import InputForm from "../AddstadiumInfo/InputForm.js";


function EditStadium() {
  return (
    
    <div className='mt-5'>
    <Header text ={'Edit'}/>
    <InputForm  />
    </div>
  )
}

export default EditStadium;
import React from 'react';
import './PlayHeader.css';


import { useTranslation } from 'react-i18next';



export default function Header() {
    const { t } = useTranslation();
    const placeholderSearch = t("PlayPageTranslation:Select");
    return (
        <div className="all SearchPlayPageContainer mt-5">
            <div className="container mx-auto ">
                <nav className="navbar mt-5 justify-content-center navbar-custom search-playPage">
                    <div className="container-fluid text-inside-search-playPage">
                        <a className="navbar-brand fw-bold fs-4 location-StaduimsInSearchPlay" href='#'> {t("PlayPageTranslation:LocationStadiums")}</a>
                        <form className="d-flex mt-3 form-search-play-page">
                            <input className="form-control me-2" type="search" placeholder={placeholderSearch} aria-label="Search" />
                            <button className="btn btn-outline-success h-75" type="submit">{t("PlayPageTranslation:Search")}</button>
                        </form>
                    </div>
                </nav>
            </div>
        </div>
    );
}

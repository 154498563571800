import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import CommunityPost from "./CommunityPost";
import Share from './../Share/Share';
import ShareModal from './../Share/ShareModal';
import Cookies from "universal-cookie";
import { useLocation, useParams } from 'react-router-dom';
import { UserContext } from "../../../Contexts/UserContext.js";
import { defaultProfilePic } from "../../Player/Profile/ProfileHeader/ProfileHeader.js";

const cookies = new Cookies();

const AllPosts = (props) => {
    
    const [sampleData, setSampleData] = useState([]);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [selectedPostId, setSelectedPostId] = useState(null);
    const [selectedPostText, setSelectedPostText] = useState('');
    const [deletedPost, setDeletedPost] = useState(false);
    const [action,setAction]=useState(false);

    const location = useLocation();
    const Posts = useContext(UserContext);
    const {id}=useParams();
    const fetchData = async () => {
        try {
            let response;
            if (location.pathname === `/profile/${id}`) {
                response = await axios.get(`${process.env.REACT_APP_BEURL}/post/AllUsers/${id}`);

            } else {
                response = await axios.get(`${process.env.REACT_APP_BEURL}/post`);
            }

            if (Array.isArray(response.data)) {
                setSampleData(response.data);
            } else if (response.data && Array.isArray(response.data.posts)) {
                setSampleData(response.data.posts);
            }
        } catch (error) {
            console.log('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    useEffect(() => {
        fetchData();
    }, [action]);

  
    const handleAddPost = (newPost) => {
        setSampleData([newPost, ...sampleData]);
        setAction(!action);
    };

    const getSelectedPostText = (postId) => {
        const selectedPost = sampleData.find(post => post.id === postId);
        return selectedPost ? selectedPost.postText : '';
    };

    const handleUpdatePost = (postId) => {
        setSelectedPostId(postId);
        const postText = getSelectedPostText(postId);
        setSelectedPostText(postText);
        setIsShareModalOpen(true);
    };
 
    
 
    
    const handleDeletePost = (postId) => {
        const updatedPosts = sampleData.filter(post => post.id !== postId);
        setSampleData(updatedPosts);
        setAction(!action);
        
 
    };
    

    const formatTime = (isoDateString) => {
        const postDate = new Date(isoDateString);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - postDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }
    }

  

    return (
        <>
          {((location.pathname === `/profile/${id}` && Posts.userData?.id != id)) ? (
                <p></p>
            ):<Share onShare={handleAddPost} />}
            
        
       
            {sampleData.map((post) => (
                <div key={post._id}>
                    <CommunityPost
                        id={post.userId?._id}
                        profileImage={post.userId?.profilePic ? post.userId?.profilePic?.secure_url : defaultProfilePic}
                        userName={post.userId?.userName}
                        time={formatTime(post.createdAt)}
                        desc={post.desc}
                        postText={post.postText}
                        image={post.image}
                        like={post.Nlikes}
                        numberComment={post.Ncomments}
                        share={post.share}
                        postId={post._id}
                        onUpdatePost={(postId) => handleUpdatePost(postId)}
                        onDeletePost={(postId) => handleDeletePost(postId)}
                        darkMode={props.darkMode}
                        userImage={Posts.userData?.profilePic ? Posts.userData?.profilePic?.secure_url : defaultProfilePic}
                        position={post.userId?.position ? post.userId?.position : ' '}
                    />
                </div>
            ))}
            <ShareModal
                isOpen={isShareModalOpen}
                onClose={() => setIsShareModalOpen(false)}
                onShare={(newPostText) => {
                    if (selectedPostId && newPostText !== selectedPostText) {
                        axios.patch(`${process.env.REACT_APP_BEURL}/post/update/${selectedPostId}`, { postText: newPostText })
                            .then(response => {
                            })
                            .catch(error => {
                                console.error('Error updating post:', error);
                            });
                    }
                    setIsShareModalOpen(false);
                }}
                text={selectedPostText}
            />
        </>
    )
}

export default AllPosts;

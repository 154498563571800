import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Table_book.css';
import BookingModel from '../Models/BookingModel.js';
import axios from 'axios';
import cookiesjs from 'js-cookie';

const localizer = momentLocalizer(moment);

const Table_book = ({ setLay, events: propsEvents, setCurrantEvent, currantEvent, stadiumId, user }) => {
  const lng = cookiesjs.get("i18next") || "en";
  const [container, setContainer] = useState("");
  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const fetchDataFromDatabase = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BEURL}/reservation/${stadiumId}`);
        const dbEvents = response.data;
        dbEvents.forEach(event => {
          event.title = event.type = event.status;
          event.start = new Date(event.start);
          event.end = new Date(event.end);
        });
        const generatedEvents = generateEvents(dbEvents);
        let allEvents = [...dbEvents, ...generatedEvents];

        if (user !== 'stadiumDashboard') {
          const now = new Date();
          allEvents = allEvents.filter(event => event.start > now);
        }

        setEvents(allEvents);
      } catch (error) {
        console.error('Error fetching events:', error);
      }
    };

    fetchDataFromDatabase();
  }, [propsEvents, stadiumId, user]);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setContainer("");
    }
  }, []);

  const generateEvents = (existingReservations) => {
    const startDate = moment();
    const endDate = moment().add(30, 'days');
    const generatedEvents = [];
    let currentDate = startDate.clone();

    while (currentDate.isSameOrBefore(endDate)) {
      const dayName = currentDate.format('dddd');
      const matchingEvents = propsEvents.filter(event => event.day === dayName);

      matchingEvents.forEach(event => {
        const start = moment(currentDate)
          .set({ hour: moment(event.start, 'HH:mm:ss').hour(), minute: moment(event.start, 'HH:mm:ss').minute() })
          .toDate();
        const end = moment(currentDate)
          .set({ hour: moment(event.end, 'HH:mm:ss').hour(), minute: moment(event.end, 'HH:mm:ss').minute() })
          .toDate();

        const isOverlapping = existingReservations.some(
          res => (start < new Date(res.end) && end > new Date(res.start))
        );

        if (!isOverlapping) {
          generatedEvents.push({
            ...event,
            start,
            end,
            type: 'available',
            title: 'available',
          });
        }
      });

      currentDate.add(1, 'day');
    }

    return generatedEvents;
  };

  const eventStyleGetter = (event) => {
    let style = {};
    switch (event.type) {
      case 'available':
        style = {
          backgroundColor: '#BEE3F8',
          color: 'black',
          border: '1px solid #4F80E1',
          borderRadius: '0px',
          opacity: 0.8,
          display: 'block'
        };
        break;
      case 'joinable':
        style = {
          backgroundColor: '#C6F6D5',
          color: 'black',
          border: '1px solid #51C994',
          borderRadius: '0px',
          opacity: 0.8,
          display: 'block'
        };
        break;
      case 'booked':
        style = {
          backgroundColor: '#FF6B6B',
          color: 'black',
          border: '1px solid #E25454',
          borderRadius: '0px',
          opacity: 0.8,
          display: 'block'
        };
        break;
      default:
        break;
    }
    return { style };
  };

  const handleSelectEvent = (event) => {
    setCurrantEvent(event);
    if (event.type === 'available') {
      if(user!='stadiumDashboard')
        {
          setOpen(true);
        }
      
    } else if (event.type === 'joinable') {
      window.location.href = `/game`;
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  const handleAvailableEvent = (times, selectedOption, additionalInputs) => {
    currantEvent.times = times;
    currantEvent.eventType = selectedOption;
    currantEvent.additionalInputs = additionalInputs;
    setLay(true);
  };

  const handleDoubleClickEvent = (event) => {
    if (event.type === 'available') {
      handleAvailableEvent(event.times, event.eventType);
    }
  };

  return (
    <div className={container}>
      <div className="book-now-container">
        <div className="book-component">
          <Calendar
            localizer={localizer}
            events={events}
            defaultView="week"
            selectable
            onSelectEvent={handleSelectEvent}
            eventPropGetter={eventStyleGetter}
            onDoubleClickEvent={handleDoubleClickEvent}
            style={{ height: 500 }}
          />
          {open && <BookingModel user={user} isOpen={true} onClose={onClose} handleAvailableEvent={handleAvailableEvent} />}
        </div>
      </div>
    </div>
  );
};

export default Table_book;



export const FormatDate =(dateString)=>{
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        
        
        const monthNames = ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December"];
        
        
        const day = date.getDate();
        const monthIndex = date.getMonth();
        
        
        const formattedDate = `${day} ${monthNames[monthIndex]}`;
        
        return formattedDate;
    };
    return(
        formatDate(dateString)
    )
}
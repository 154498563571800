import {useNavigate} from "react-router-dom";
import "./SidebarProfile.css";
import Cookies from 'universal-cookie';
import {useTranslation} from "react-i18next";
import { defaultProfilePic } from "../../Web/Player/Profile/ProfileHeader/ProfileHeader.js";

const Sidebar = ({activeForm, setActiveForm , email, name , profilePicture }) => {
    const cookies = new Cookies();
    const navigate = useNavigate();
    const {t} =useTranslation();
    return (
        <div className=" profD  col-lg-4 col-xl-3">
            <div className="offcanvas-lg offcanvas-end" tabIndex={-1} id="offcanvasSidebar">
                <div className="offcanvas-header justify-content-end pb-2">
                    <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="offcanvas"
                        data-bs-target="#offcanvasSidebar"
                        aria-label="Close"
                    />
                </div>
                <div className="offcanvas-body p-3 p-lg-0">
                    <div className="card bg-light w-100">
                        <div className="position-absolute top-0 end-0 p-3">
                            {/* <button className="text-primary-hover btn" data-bs-toggle="tooltip" data-bs-title="Edit profile">
                                <i className="bi bi-pencil-square" style={{color: "black"}} />
                            </button> */}
                        </div>
                        <div  className="cardD p-3">
                            <div className="text-center mb-3">
                                <div className="avatar avatar-xl mb-2">
                                    <img
                                        className="avatar-img profile-img rounded-circle border border-2 border-white"
                                        src={profilePicture || defaultProfilePic}
                                        alt="User avatar"
                                    />
                                </div>
                                <h6 id="nameForCardDK" className="mb-0 profile-name">{name}</h6>
                                <p id="emailForCardDK" className="text-reset text-primary-hover small">{email}</p>
                                <hr />
                            </div>
                            <ul className="nav nav-pills-primary-soft flex-column">
                                <li className="nav-item">
                                    <button className={`nav-link btn ${activeForm === t('SettingsTranslation:Security') ? 'active' : ''}`} onClick={() => setActiveForm(t('SettingsTranslation:Security'))}>
                                        <i  id="secDarck" class="bi bi-shield fa-fw me-2">
                                        {t('SettingsTranslation:Security')}
                                        </i>
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link btn ${activeForm === t('SettingsTranslation:PaymentDetails') ? 'active' : ''}`} onClick={() => setActiveForm(t('SettingsTranslation:PaymentDetails'))}>
                                        <i className="bi bi-wallet fa-fw me-2" />
                                        {t('SettingsTranslation:PaymentDetails')}
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link btn ${activeForm === t('SettingsTranslation:deleteProfile') ? 'active' : ''}`} onClick={() => setActiveForm(t('SettingsTranslation:deleteProfile'))}>
                                        <i className="bi bi-trash fa-fw me-2" />
                                        {t('SettingsTranslation:deleteProfile')}
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link text-danger bg-danger-soft-hover btn ${activeForm === t('SettingsTranslation:SignOut') ? 'active' : ''}`}  onClick={() => {
                                        cookies.remove("userToken");
                                        navigate("/");
                                        window.location.reload();
                                    }}>
                                        <i className="fas fa-sign-out-alt fa-fw me-2" />
                                        {t('SettingsTranslation:SignOut')}
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;

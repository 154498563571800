import axios from "axios";
import {useContext, useState} from "react";
import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../../../Contexts/UserContext";
import {useTranslation} from "react-i18next";
import './custom-request-model.css'
export const ModelForRequest = ({show, handleClose, positions, idList, user,userId}) => {
    const {t} = useTranslation()
    const userContext = useContext(UserContext);
    const userData = userContext ? userContext.userData : null;
    
    const navigate = useNavigate();
    const [selectedPosition, setSelectedPosition] = useState('');
    const [positionsData, setSelectedPositions] = useState(positions);
    const handlePositionChange = (event) => {
        setSelectedPosition(event.target.value);
    };
    
    const handleRequestSubmit = async (event) => {
        event.preventDefault();

        try {

            const response = await axios.patch(`${process.env.REACT_APP_BEURL}/playPage/listOfPlayers/${idList}`, {
                playerName:userData.name,
                hostId:userId,
                position: selectedPosition,
                player: userData?.id
            });

            console.log("Request submitted ", response.data);
            setSelectedPositions([
                {name: 'Goalkeeper', playersLeft: response.data.updatedListOfPlayers.Goalkeeper},
                {name: 'Defender', playersLeft: response.data.updatedListOfPlayers.Defender},
                {name: 'Midfielder', playersLeft: response.data.updatedListOfPlayers.Midfielder},
                {name: 'Attacker', playersLeft: response.data.updatedListOfPlayers.Attacker},
                {name: 'Other', playersLeft: 5}
            ])
            setSelectedPosition('')
            handleClose();
        } catch (error) {

            console.error("Error submitting request:", error);
        }
    };
    if (!user) {
        navigate(`/login`);
        return null;
    }
    return (
        <Modal show={show} onHide={handleClose} className="custom-request-model">
            
            <Modal.Header closeButton className="custom-request-model-header">
                <Modal.Title>{t("ModelRequestTranslation:RequestToJoin")}</Modal.Title>
            </Modal.Header>
            <Modal.Body className="custom-request-model-body">
                <form onSubmit={handleRequestSubmit}>
                    <div className="form-group">
                        <label htmlFor="positionSelect">{t(`ModelRequestTranslation:SelectPosition`)}</label>
                        <select className="form-control model-select-form" id="positionSelect" value={selectedPosition} onChange={handlePositionChange}>
                            <option value="" className="model-select-form">{t("ModelRequestTranslation:Select")}</option>
                            {positionsData.map(pos => (
                                <option key={pos.name} value={pos.name} disabled={pos.playersLeft === 0} className="model-select-form">
                                    {t(`ModelRequestTranslation:${pos.name}`)} ({pos.playersLeft} {t("ModelRequestTranslation:left")})
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="footer-form">
                        <button type="submit" className="btn btn-success mt-3" disabled={!selectedPosition}>
                            {t("ModelRequestTranslation:SendRequest")}
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};

import { useContext, useEffect, useState } from 'react';
import './Navbar.css';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { defaultProfilePic } from '../Web/Player/Profile/ProfileHeader/ProfileHeader.js';
import i18n from "i18next";
import cookiesjs from "js-cookie";
import DarkMode from '../DarkMode/DarkMode.js';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../Contexts/UserContext.js';
import { StadiumContext } from '../Contexts/StadiumContixt.js';
import Loading from '../Loading/Loading.js';


const cookies = new Cookies();
export const logOut = () => {
  cookies.remove("userToken");
  window.location.replace('/');
  
};

export const Navbar = ({ user, setUser }) => {
  const { t } = useTranslation();
  const lng = cookiesjs.get("i18next") || "en";
  const navigate = useNavigate();
  const stadiumContext = useContext(StadiumContext);
  const [stadiumAddedInfo, setStadiumAddedInfo] = useState(0);

  const userContext = useContext(UserContext);
  const userData = userContext?.userData || {
    profilePic: {
      secure_url: defaultProfilePic
    }
  };
  const loading = userContext?.loading;
  const { id } = useParams();
  const location = useLocation();
  

  const applyDirection = (language) => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      if (i18n.dir(language) === 'rtl') {
        navbar.classList.add('rtl');
      } else {
        navbar.classList.remove('rtl');
      }
    }
  };

  useEffect(() => {
    if (stadiumContext?.stadiumData?.name) {
      setStadiumAddedInfo(1);
    }
  }, [stadiumContext]);

  useEffect(() => {

    applyDirection(lng);
  }, [location, lng]);


  if (loading) {
    return <Loading/>
  }

  const isLinkActive = (path) => {
    return location.pathname === path ? 'active' : '';
  };

 
  const isdashboard = user === 'adminDashboard' || user === 'stadiumDashboard';

  
  const shouldShowLinks = !(user === "stadiumDashboard" && !stadiumAddedInfo);

  return (
    <>
    {console.log(userData)}
      <nav className={`navbar navbar-expand-md fixed-top mt-0 mx-auto ${(isLinkActive('/') && (user == 1 || !user)) && "mt-3"} ${isdashboard ? 'navbar-dashboard' : ''}`} style={{ width: "98%" }}>
        <div className="container-fluid">
          <Link className="navbar-brand me-auto" to=""><img
            className="logo ms-4"
            src="https://ccccccccccoooooooooooooooooopppppppppppp.onrender.com/assets/coPlayLogo.png"
            alt="Logo"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://ccccccccccoooooooooooooooooopppppppppppp.onrender.com/assets/coPlayLogo.png";
            }}
          />
          </Link>
          <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"><img
                className="logo ms-4"
                src="https://ccccccccccoooooooooooooooooopppppppppppp.onrender.com/assets/coPlayLogo.png"
                alt="Logo"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://ccccccccccoooooooooooooooooopppppppppppp.onrender.com/assets/coPlayLogo.png";
                }}
              />
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" />
            </div>
            <div className="offcanvas-body">
              {shouldShowLinks && (
                <ul className={`navbar-nav ${isdashboard ? 'justify-content-start' : 'justify-content-center'} flex-grow-1 pe-3`}>
                  <li className={`nav-item ${isdashboard && 'dashboardItem'}`}>
                    <Link to='' className={`nav-link me-lg-2 ${isLinkActive('/')}`} aria-current="page"> {t("HomeTranslation:home")}</Link>
                  </li>
                  <li className="nav-item">
                    {!isdashboard && <Link className={`nav-link me-lg-2 ${isLinkActive(`/play`) || isLinkActive(`/game/${id}`)}`} to="play">{t("HomeTranslation:play")}</Link>}
                  </li>
                  <li className="nav-item">
                    {!isdashboard && <Link className={`nav-link me-lg-2 ${isLinkActive(`/book`) || isLinkActive(`/bookNow/${id}`) || isLinkActive(`/staduemBook/${id}`)}`} to="book">{t("HomeTranslation:book")}</Link>}
                  </li>
                  <li className="nav-item">
                    {user &&!isdashboard&& <Link className={`nav-link me-lg-2 ${isLinkActive('/chat')}`} to="chat" id='chat'>{t("HomeTranslation:chat")}</Link>}
                  </li>
                  {user && !isdashboard && <li className="nav-item ">
                    <div className=" notification">
                      <div className="bell-container">
                        <Link className='bell' to='notifications'></Link>
                      </div>
                    </div>
                  </li>}
                  <li>
                    <DarkMode />
                  </li>
                  <div className="nav-item dropdown mt-2 m-3 ">
                    <a href='' className=" bi bi-translate " id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"></a>
                    <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                      <li><button className="dropdown-item" onClick={() => { i18n.changeLanguage("ar") }} >{t("HomeTranslation:ar")}</button></li>
                      <li><hr className="dropdown-divider" /></li>
                      <li><button onClick={() => { i18n.changeLanguage("en") }} className="dropdown-item" >{t("HomeTranslation:en")}</button></li>
                    </ul>
                  </div>
                </ul>
              )}
            </div>
          </div>
          {isdashboard && (
            <li className="nav-item list-unstyled">
              <div className="notification">
                <div className="bell-container">
                  <Link className='bell' to='notifications' ></Link>
                </div>
              </div>
            </li>
          )}
          {!user ? <Link to='login' className={`login-button `}> {t("HomeTranslation:login")}</Link> :
            <>
              <div className="nav-item dropdown ">
                <a href='' className="dropdown-toggle me-2" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {!isdashboard ? <img className='proImg' src={userData?.profilePic?.secure_url ? userData?.profilePic?.secure_url : defaultProfilePic} alt='Profile' />
                  : <img className='proImg' src={stadiumContext?.stadiumData?.image ? stadiumContext?.stadiumData?.image : defaultProfilePic} alt='Profile' />}
                </a>
                {console.log(stadiumContext?.stadiumData)}
                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
                  {!isdashboard && <li><Link className="dropdown-item" to={`profile/${userData?.id}`}>{t("HomeTranslation:profile")}</Link></li>}
                  <li><Link to='settings' className="dropdown-item" >{t("HomeTranslation:settings")}</Link></li>
                  <li><Link to='/' onClick={logOut} className="dropdown-item" >{t("HomeTranslation:logout")}</Link></li>
                </ul>
              </div>
            </>
          }
          <button className={`navbar-toggler pe-0`} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
            <span className={`navbar-toggler-icon`} />
          </button>
        </div>
      </nav>
    </>
  );
};
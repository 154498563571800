    import React, { useState, useEffect } from 'react';
    import axios from 'axios';
    import { Link, useNavigate } from 'react-router-dom';
    import UpdateLocationModal from './UpdateLoc.js';
    import AddLocationModal from './AddLoc.js';
    import './location.css';
    import { useTranslation } from 'react-i18next'
    const ListLocations = () => {
        const [locations, setLocations] = useState([]);
        const [isAddModalOpen, setIsAddModalOpen] = useState(false);
        const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
        const [currentId, setCurrentId] = useState(null);
        const {t} = useTranslation();

        useEffect(() => {

            axios.get(`${process.env.REACT_APP_BEURL}/location/getLocation`)

                .then(res => {
                    setLocations(res.data.locData || []);
                })
                .catch(err => {
                    console.log(err);
                });
        }, [locations]);

        const handleDelete = (id) => {

            axios.delete(`${process.env.REACT_APP_BEURL}/location/${id}`)

                .then(res => {
                    
                })
                .catch(err => console.log(err));
        };

        return (
            <>
                <div  className="d-flex vh-100 bg-gray justify-content-center align-items-center"> 
                    <div className="w-50 rounded p-3" >
                        <button className={`btn btn-success`} onClick={() => setIsAddModalOpen(true)}>{t("AdminLocationTranslation:addLocation")}</button>
                            <table className="table" >
                                <thead>
                                <tr>
                            <th>{t("AdminLocationTranslation:locationName")}</th>
                        <th>{t("AdminLocationTranslation:timeZone")}</th>
                    <th>{t("AdminLocationTranslation:action")}</th>
                </tr>
                        </thead>
                            <tbody>
                                {locations.map((location, index) => (
                                    <tr key={index}>
                                        <td>{location.locatioName}</td>
                                        <td>{location.timeZone}</td>
                                        <td>
                                            <button onClick={() => { setCurrentId(location._id); setIsUpdateModalOpen(true); }} className={`btn btn-sm btn-success me-2`}>{t("AdminLocationTranslation:updateLocation")}</button>
                                            <button onClick={() => handleDelete(location._id)} className={`btn btn-sm btn-danger`}>{t("AdminLocationTranslation:deleteLocation")}</button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                    <AddLocationModal isOpen={isAddModalOpen}  onClose={(setErrors) => { setIsAddModalOpen(false); setErrors({}); }} locations={locations}/>
                {isUpdateModalOpen && <UpdateLocationModal isOpen={isUpdateModalOpen} onClose={() => setIsUpdateModalOpen(false)} id={currentId} locations = {locations} />}
            </>
        );
    };
    export default ListLocations;

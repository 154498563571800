import React, { useState } from 'react';
import "./Input.css";

export default function Input({ type = 'text', title, id, name, value, onchange, errors, touched}) {

  const [showPassword, setShowPassword] = useState(false);
  const hidePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className="mb-3">
        <label htmlFor={id}></label>
        <div className="d-flex position-relative">
          <input
            type={id === "password" ? (showPassword ? "text" : "password") : type}
            name={name}
            value={value}
            id={id}
            onChange={onchange}
            className="register-form-control form-control w-75 p-md-3 container"
            placeholder={title}
          />
          {id === "password" ? (
            <span className="input-eye-text position-absolute top-50 translate-middle-y " onClick={hidePassword}>
              <i id="iconShowPass" className={`fas ${showPassword ? "fa-eye" : "fa-eye-slash"}`} />
            </span>
          ) : null}
        </div>
      </div>
      {touched[name] && errors[name] ? (
        <div className="error mx-5 text-danger">{errors[name]}</div>
      ) : null}
    </>
  );
}

import React from 'react'
import './History.css';

function CardHistory(props) {
return (
    <div className="Bookingcard">
        <img src={props.imageUrl} className="card-img-top" alt="Booking" />
        <div className="History-card-body">
        <div className="text-section">
            <h6 className="card-title fw-bold">{props.title}</h6>
            <p className="History-card-text">{props.location}<br />{props.time}</p>
        </div>
        <div className="vertical-line"></div>
        <div className="cta-section">
            <small>{props.players}</small>
            <ul className='ul'>
            {props.sport.map((item, index) => (
                <li key={index}>
                <svg className='svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="16" height="16">
                    <path d={item.icon} />
                </svg>
                {item.name}
                </li>
            ))}
            </ul>
        </div>
        </div>
    </div>
    );
    }
    
export default CardHistory

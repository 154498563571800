import React from 'react';
import './Card.css';
import './CardList.css';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol } from '@fortawesome/free-solid-svg-icons';
import { FormatDate } from './FormatDate.js';
import { FormatTimeRange } from './FormatTimeRange.js';
import { useTranslation } from 'react-i18next';


const Card = ({ img, title, startDate, endDate, location, lengthPlayerJoined, stadiumFormat, idReservation, idStadium, userName, profilePic, idList ,userId }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    
    const formattedDate = FormatDate(startDate);
    const timeRange = FormatTimeRange(startDate, endDate);

    const handleDetailsClick = () => {
        navigate(`/game/${idReservation}/${idStadium}`, {
            state: {
                stadiumName: title,
                stadiumLocation: location,
                startDate,
                endDate,
                lengthPlayerJoined,
                userName,
                profilePic,
                idList,
                userId
            }
        });
    };

    return (
        <div className="card playCard mt-3">
            
            <div className="content">
                <p className="title">{title}</p>
                <div>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-calendar2-check" viewBox="0 0 16 16">
                            <path d="M10.854 8.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 0 1 .708-.708L7.5 10.793l2.646-2.647a.5.5 0 0 1 .708 0" />
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M2 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1z" />
                            <path d="M2.5 4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H3a.5.5 0 0 1-.5-.5z" />
                        </svg> {formattedDate}
                    </p>
                    <p>
                        <svg className='svg' xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
                            <path d="M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm0-14c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1z" />
                        </svg> {timeRange}
                    </p>
                    <p>
                        <svg className='svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="16" height="16">
                            <path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" />
                        </svg> {location}
                    </p>
                    <p>
                        <svg className='svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" width="16" height="16">
                            <path d="M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z" />
                        </svg> {lengthPlayerJoined} {t("PlayPageTranslation:PlayerJoined")}
                    </p>
                    <p>
                        <FontAwesomeIcon icon={faFutbol} style={{ fontSize: "15px" }} /> {stadiumFormat}
                    </p>
                </div>
                <button className="button" onClick={handleDetailsClick}>{t("PlayPageTranslation:DetailsButton")}</button>
            </div>
            <img src={img} className="card__image" alt={title} />
        </div>
    );
};

export default Card;

import React from 'react';
import { FaStar, FaStarHalfAlt } from "react-icons/fa";
import styled from 'styled-components';
import { AiOutlineStar } from "react-icons/ai";

const Star = ({stars}) => {
    const ratingStar = Array.from({length:5}, (elem, index) => {
        let number = index + 0.5;
        return (
            <span key={index}>
                {
                    stars >= index + 1 
                    ? ( <FaStar className='icon me-1' /> )
                    : stars >= number 
                    ? ( <FaStarHalfAlt className='icon me-1' /> )
                    : ( <AiOutlineStar  className='icon me-1'/> )
                    
                }
            </span>
        );
    });

    return (
        <Wrapper>
            <div className='icon-style'>
                {ratingStar}
            </div>
        </Wrapper>
    );
};

const Wrapper = styled.section`
    .icon-style {
        display: flex;
        gap: 0.3rem;
        align-items: center;
        justify-content: flex-start;

        .icon {
            font-size: 1.8rem;
            color: #FFD700;
        }

    }
`;

export default Star;

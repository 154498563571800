import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import HttpApi from 'i18next-http-backend';
i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    //.use(HttpApi)
    .use(HttpBackend)
    .init({
        fallbackLng: "en",
        supportedLngs: ["en", "ar"],


        ns: ['BookTranslation',"InputesTranslation" ,'HomeTranslation',"HeaderDashboardTranslation",'NotificationsTranslation','ShareTranslation'
            ,'NonBookingTranslation','BookingTranslation','TeamTranslation','ChatTranslation','AdminLocationTranslation'
            ,"EditProfileTranslation","AddStadiumTranslation","PlayPageTranslation",'HomeBodyTranslation','BodyDashboardTranslation'
            ,"SettingsTranslation","StadiumPageTranslation","ModelRequestTranslation"],


        detection: {
            order: [
                'cookie',
                'htmlTag',
                'localStorage',
                'sessionStorage',
                'navigator',
                'path',
                'subdomain'],
            caches: ["cookie"],
        },
        backend: {
            loadPath: `${process.env.REACT_APP_BEURL}/locales/{{lng}}/{{ns}}.json`
        }

    });
import React from 'react';
import './History.css';
import CardHistory from './CardHistory';
import { useTranslation } from 'react-i18next';

function History() {
    const { t } = useTranslation();
    const bookings = [
        {
            imageUrl: "https://www.burohappold.com/wp-content/uploads/2021/10/EducationStadium_04_Pattern-DowPhotography.jpg",
            title: "Miesten E viikkotreenit",
            location: "Padel Uno, Limingantulli",
            time: "8:00 PM",
            players: "0/4 players",
            sport: [
                { name: "Mixed", icon: "M112 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm40 304V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V256.9L59.4 304.5c-9.1 15.1-28.8 20-43.9 10.9s-20-28.8-10.9-43.9l58.3-97c17.4-28.9 48.6-46.6 82.3-46.6h29.7c33.7 0 64.9 17.7 82.3 46.6l58.3 97c9.1 15.1 4.2 34.8-10.9 43.9s-34.8 4.2-43.9-10.9L232 256.9V480c0 17.7-14.3 32-32 32s-32-14.3-32-32V352H152z" },
                { name: "3h", icon: "M12 2c-5.523 0-10 4.477-10 10s4.477 10 10 10 10-4.477 10-10-4.477-10-10-10zm0 18c-4.418 0-8-3.582-8-8s3.582-8 8-8 8 3.582 8 8-3.582 8-8 8zm0-14c-.552 0-1 .448-1 1v6c0 .552.448 1 1 1s1-.448 1-1v-6c0-.552-.448-1-1-1z" },
                { name: "Football", icon: "M256 8C119.3 8 8 119.3 8 256s111.3 248 248 248 248-111.3 248-248S392.7 8 256 8zm164 114.4c29.5 36 47.4 82 47.8 132-7-1.5-77-15.7-147.5-6.8-5.8-14-11.2-26.4-18.6-41.6 78.3-32 113.8-77.5 118.3-83.5zM396.4 97.9c-3.8 5.4-35.7 48.3-111 76.5-34.7-63.8-73.2-116.2-79-124 67.2-16.2 138 1.3 190.1 47.5zm-230.5-33.3c5.6 7.7 43.4 60.1 78.5 122.5-99.1 26.3-186.4 25.9-195.8 25.8C62.4 147.2 106.7 92.6 165.9 64.6zM44.2 256.3c0-2.2 0-4.3 .1-6.5 9.3 .2 111.9 1.5 217.7-30.1 6.1 11.9 11.9 23.9 17.2 35.9-76.6 21.6-146.2 83.5-180.5 142.3C64.8 360.4 44.2 310.7 44.2 256.3zm81.8 167.1c22.1-45.2 82.2-103.6 167.6-132.8 29.7 77.3 42 142.1 45.2 160.6-68.1 29-150 21.1-212.8-27.9zm248.4 8.5c-2.2-12.9-13.4-74.9-41.2-151 66.4-10.6 124.7 6.8 131.9 9.1-9.4 58.9-43.3 109.8-90.8 142z" }
            ]
        },
    ];

    return (
        <div className="container-Booking">
            <b className="reservations_title medium-title">{t("NonBookingTranslation:My_bookings")}</b>
            <hr className='hr' />
            <div className="reservations_menu">
                <div className="tab-menu">
                <p className='p'>{t("NonBookingTranslation:Upcoming")}</p>
                <p className='tab-menu_link--active p'>{t("NonBookingTranslation:History")}</p>
                </div>
            </div>
            {bookings.map((booking, index) => (
                <CardHistory
                    key={index}
                    imageUrl={booking.imageUrl}
                    title={booking.title}
                    location={booking.location}
                    time={booking.time}
                    players={booking.players}
                    sport={booking.sport}
                />
            ))}
        </div>
    );
}

export default History;

import React from 'react'
import Base from '../Base/Base.js'


export default function AddStadium() {
  return (
<>
<Base inputType={'InputStadium' }/>
</>
  )
}

import React from 'react'
import { Header } from './Header/Header.js'
import HomeBody from './HomeBody/HomeBody.js';
import Team from './../AboutUs/Team';

const Home = () => {
  return (
    <>
      <Header />
      <HomeBody />
      <Team />
    </>
  )
}

export default Home;

import React, { useState, useEffect, useContext } from "react";
import "./Posty.css";
import axios from "axios";
import Cookies from "universal-cookie";
import { UserContext } from "../../../Contexts/UserContext.js";
import { defaultProfilePic } from "../../Player/Profile/ProfileHeader/ProfileHeader.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const cookies = new Cookies();
const CommunityPost = (props) => {
    const { t } = useTranslation();
    const [commentShow, setCommentShow] = useState(false);
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [commentCount, setCommentCount] = useState(props.numberComment);
    const [likeCounter, setLikeCounter] = useState(props.like);
    const [likeColor, setLikeColor] = useState("#677e92");
    const [error, setError] = useState('');

    const Posts = useContext(UserContext)

    useEffect(() => {
        if (commentShow) {
            const fetchComments = async () => {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_BEURL}/post/comment/${props.postId}`, {
                        headers: {
                            Authorization: `PRIVATE_USER_HIDDEN__${cookies.get('userToken')?.token}`
                        }
                    });
                    ;

                    if (response.data && Array.isArray(response.data.comments)) {
                        setComments(response.data.comments);
                    } else {

                    }
                } catch (error) {
                    console.error('Error fetching comments:', error);
                    setError('Failed to fetch comments');
                } finally {
                    //setLoading(false);
                }
            };
            fetchComments();
        }
    }, [commentShow]);
    useEffect(() => {

    }, []);
    const toggleComments = () => {
        setCommentShow(!commentShow);
    };

    const handleLikeClick = async () => {
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BEURL}/post/like/${props.postId}`, {}, {
                headers: {
                    Authorization: `PRIVATE_USER_HIDDEN__${cookies.get('userToken')?.token}`
                }
            });
            if (response.data && response.data.post) {
                const updatedPost = response.data.post;
                setLikeCounter(updatedPost.Nlikes);
                setLikeColor(updatedPost.like.includes(props.userId) ? "#4467da" : "#1f7cd3");
            }
        } catch (error) {
            console.error('Failed to like the post:', error);
        }
    };

    const addComment = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BEURL}/post/${props.postId}/comment`, {
                text: newComment
            }, {
                headers: {
                    Authorization: `PRIVATE_USER_HIDDEN__${cookies.get('userToken')?.token}`
                }
            });


            setComments([...comments, { ...response.data.comment }]);
            setCommentCount(commentCount + 1);
            setNewComment("");
        } catch (error) {
            console.error('Error posting comment:', error);
        }
    };


    const deletePost = async () => {
        try {
            const response = await axios.delete(`${process.env.REACT_APP_BEURL}/post/${props.postId}`);
            if (response.status === 200) {
                window.location.reload();
                console.log('Post deleted successfully!');
            }
        } catch (error) {
            console.log('Failed to delete the post:', error);
            if (error.response && error.response.status === 404) {
                console.log('Post not found');
            } else {
                console.log('Error deleting the post');
            }
        }
    };

    const handleUpdatePost = () => {
        props.onUpdatePost(props.id);
    };

    const formatTime = (isoDateString) => {
        const postDate = new Date(isoDateString);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - postDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return `${days} day${days !== 1 ? 's' : ''} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes !== 1 ? 's' : ''} ago`;
        } else {
            return `${seconds} second${seconds !== 1 ? 's' : ''} ago`;
        }
    }

    return (
        <div className={`posty card mx-auto my-4 ${props.darkMode ? "dark-mode" : ""}`} style={{ borderRadius: "5px" }}>
            <div className="card-head d-flex my-3 mx-3">
                <div className="d-flex align-items-center">
                    <img src={props.profileImage} className="rounded-circle me-3" alt=""

                        style={{ width: "50px", height: "50px" }}
                    />
                </div>
                <div style={{ lineHeight: "0" }}>
                    <h5 className="accountName-post" style={{ float: "left", clear: "both", fontWeight: "bold" }}>
                        <Link to={`/profile/${props.id}`} style={{ textDecoration: "none" }}>{props.userName}</Link>
                        <span className="post-time" style={{ fontSize: "15px" }}>
                            <i className="bi bi-dot m-1" style={{ fontSize: "18px" }}></i>
                            {props.time}</span></h5>
                    <p style={{ float: "left", clear: "both", color: "#a29b9e" }}>{props.position}</p>
                </div>
                {Posts.userData && Posts.userData.id === props?.id && (
                    <div className="dropdown ms-auto">
                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <li><button className="dropdown-item" onClick={handleUpdatePost}>{t(`ChatTranslation:Update`)}</button></li>
                            <li><button className="dropdown-item" onClick={deletePost}>{t(`ChatTranslation:Delete`)}</button></li>
                        </ul>
                    </div>
                )}
            </div>
            <div className="card-body post-bodyy">
                <p style={{ textAlign: "left", fontSize: "20px" }}>
                    {props.postText}
                </p>
                <div className="bg-image">
                    <img className="img-fluid" src={props.image} style={{ borderRadius: "7px" }} alt="" />
                </div>
            </div>
            <div className="card-footer buttomCardy" style={{ border: "0" }}>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center" style={{ marginBottom: "10px" }} >
                        <a href="#!" className="btn mt-auto post-button-like" style={{ color: likeColor, fontSize: "18px" }} onClick={handleLikeClick}>
                            <i className="bi bi-hand-thumbs-up-fill" style={{ paddingRight: "5px" }}></i>
                            {t(`ChatTranslation:Like`)} ({likeCounter})
                        </a>
                        <a href="#!" className="btn" style={{ color: "#677e92 ", fontSize: "18px" }} onClick={toggleComments}>
                            <i className="bi bi-chat-dots-fill" style={{ paddingRight: "5px" }}></i>
                            {t(`ChatTranslation:Comment`)} ({commentCount})
                        </a>
                    </div>
                </div>
                <div className="d-flex my-2 pt-2 " style={{ height: "60px" }}>
                    <div className="small-pic" style={{ height: "40px", width: "40px" }}>
                        <img
                            className="rounded-circle shadow-1-strong me-3"
                            src={props.userImage}
                            alt="avatar"
                            width={42}
                            height={40}
                        />
                    </div>
                    <div className="comment-bar d-flex align-items-center" style={{ marginLeft: "11px", maxWidth: "90%", marginBottom: "12px" }}>
                        <textarea
                            className="form-control text-area add-comment-textarea"
                            rows={1}
                            cols={100}
                            placeholder={t(`ChatTranslation:textareaSpace`)}
                            style={{ width: "100%", resize: "none" }}
                            value={newComment}
                            onChange={(e) => setNewComment(e.target.value)}
                        />

                        <i className="bi bi-send-fill ms-2 mt-3" style={{ fontSize: "1.5rem", color: "#567ebe", cursor: "pointer", marginBottom: "21px", transform: "rotate(43deg)" }} onClick={addComment}></i>
                    </div>
                </div>
                {commentShow && comments.length > 0 && (
                    <div style={{ marginTop: "30px", overflowX: "hidden" }}>
                        {comments.map((comment, index) => (

                            <div key={index} className="comment-card d-flex flex-start mb-2" style={{ maxWidth: "100%" }}>
                                {console.log(comment)}
                                <img
                                    className="rounded-circle shadow-1-strong me-3"
                                    src={comment.userId?.profilePic ? comment.userId?.profilePic?.secure_url : defaultProfilePic}
                                    alt="avatar"
                                    width={35}
                                    height={35}
                                />
                                <div className="flex-grow-1 flex-shrink-1">
                                    <div className="comment-body" style={{ background: "#eff0f2", padding: "10px", borderRadius: "9px" }}>
                                        <div className="d-flex justify-content-between">
                                            <div style={{ float: "left", clear: "both", fontSize: "17px", fontWeight: "bold", color: 'black' }}>
                                                <Link to={`/profile/${comment.userId?._id}`} style={{ textDecoration: "none" }}>{comment.userId?.userName}</Link>

                                                <span style={{ color: "#a29b9e", fontSize: "13px" }}>
                                                    <i className="bi bi-dot m-1" style={{ fontSize: "15px" }}></i>
                                                    {formatTime(comment.createdAt) || "1 hr"} {/* Default time if not specified */}
                                                </span>
                                            </div>
                                        </div>
                                        <p className="small comment-text mt-2" style={{ textAlign: "left", color: "#7d7c7c", fontSize: "16px" }}>
                                            {comment.text}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );

};

export default CommunityPost;

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import Input from '../Input/Input';
import { ForgetPassword } from '../Validation/Validation';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';


function InputForgetPassword() {

    const [invalidData, setInvalidData] = useState(false);

    const { t } = useTranslation();
    const navigate = useNavigate();
    const initialValues = {
        email: '',
    };

    const formik = useFormik({
        initialValues,
        validationSchema: ForgetPassword,
        onSubmit: values => {
            const fetch = async () => {
                try {
                    const response = await axios.patch(`${process.env.REACT_APP_BEURL}/auth/forgetPassword`, { email: values.email });
                    console.log(response);
                    if (response?.data.message == "Email sent with new password") {
                        toast.success('Email Sent successfully , check your email for the new password', {
                            position: "top-right",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                    else if (response?.data.message == "User not found") {
                        setInvalidData(true);
                        toast.error('Your email is not signed up', {
                            position: "top-right",
                            autoClose: 1000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "dark",
                        });
                    }
                } catch (error) {
                    console.error('Login error:', error);
                    toast.error('Failed to send email. Please try again.', {
                        position: "top-right",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            };
            fetch();
        }
    });

    const handleInputChange = (e) => {
        const fieldName = e.target.name;
        formik.setFieldTouched(fieldName, false);
        formik.handleChange(e);
    };

    useEffect(() => {
        setInvalidData(false);
    }, [formik.values.email]);

    const inputFields = [
        {
            id: 'email',
            type: 'email',
            name: 'email',
            title: t('InputesTranslation:email'),
            value: formik.values.email,
        }
    ];

    const renderInput = inputFields.map((ele, index) => (
        <Input
            value={ele.value}
            type={ele.type}
            name={ele.name}
            id={ele.id}
            title={ele.title}
            onchange={handleInputChange}
            errors={formik.errors}
            touched={formik.touched}
            key={index}
        />
    ));

    return (
        <div>
            <div className="card2 Register-card rounded-5 col-sm-12 mt-5" style={{ height: "550px" }}>
                <div className="d-flex justify-content-center">
                    <i className="fa-solid fa-circle-exclamation text-success fa-4x mt-4"></i>
                </div>
                <h2 className="h4 text-center mt-3" style={{color: "var(--body_color)"}}>{t('InputesTranslation:passwordReset')}</h2>
                <div className="col-12">
                    <h2 className="fs-6 fw-normal text-center text-secondary m-0 px-md-5 mt-3">
                        {t('InputesTranslation:provideEmail')}
                    </h2>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <div className="row gy-3 gy-md-4 overflow-hidden mt-3">
                        {renderInput}
                        {invalidData && (
                                <p className="text-center text-danger my-0">{t('InputesTranslation:emailIsNotThere')}</p>
                            )}
                        <div className="col-12">
                            <div className="d-grid">
                                <button
                                    className="d-flex flex-column align-items-center mt-6 btn btn-success w-75 p-md-3 mx-auto mb-3"
                                    type="submit"
                                >
                                    {t('InputesTranslation:resetPassword')}
                                </button>
                                <h4 className="fs-6 fw-normal ms-3 text-secondary m-0 px-md-5 text-center">
                                    {t('InputesTranslation:checkEmail')}
                                </h4>
                            </div>
                        </div>
                    </div>
                </form>
                <p className="text-center mt-3  text-success">
                    <i className="fa-solid fa-arrow-left text"></i>
                    <Link className="text-success" to="/login">
                        {t('InputesTranslation:backToLogin')}
                    </Link>
                </p>
            </div>
            <Toaster />
        </div>
    );
}

export default InputForgetPassword;

import React, { useState, useRef } from 'react';
import Modal from 'react-modal';
import './Location.css';
import { Link, useLocation } from 'react-router-dom';
import Badminton from '../Play/Badmintion/Badmintion.js';
import Star from './Star.js';
import { QRCodeCanvas } from 'qrcode.react';
import { useReactToPrint } from 'react-to-print';
import { useTranslation } from 'react-i18next';

const PrintComponent = React.forwardRef(({ children }, ref) => (
    <div ref={ref}>
        {children}
    </div>
));

function Location({ user, stadiumUrl, stadiumName, stadiumFormat, stadiumLocation, id, averageRating }) {
    const [showModal, setShowModal] = useState(false);
    const componentRef = useRef();

    const handleShare = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: document.title,
                    text: 'Check out this page!',
                    url: window.location.href,
                });
                setShowModal(true);
                console.log('Successfully shared');
            } catch (error) {
                console.error('Error sharing', error);
            }
        } else {
            console.log('Web Share API not supported.');
            setShowModal(true);
        }
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleDownloadQR = () => {
        const canvas = document.querySelector("canvas");
        const url = canvas.toDataURL("image/png").replace(/^data:image\/[^;]/, 'data:application/octet-stream');
        const link = document.createElement("a");
        link.href = url;
        link.download = "qr-code.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    const {t}=useTranslation()

    const location = useLocation();
    const path = location.pathname;

    return (
        <div className="eerrr all container px-0 justify-content-center text-center " style={{ position: "relative" }}>
            {path === `/staduemBook/${id}` ? (
                <>
                    <h2 className="stadName p-2">{stadiumName}</h2>
                    <div className="d-flex align-items-center justify-content-center"><Star stars={averageRating} /></div>
                    <div className="d-flex align-items-center justify-content-center">
                        <img src="https://cdn-icons-png.flaticon.com/128/13797/13797450.png" alt="icon" style={{ width: "35px", marginLeft: "5px", marginBottom: "3px" }} />
                        <h6 className="p-2" style={{ fontSize: "20px" }}>{t(`BookTranslation:Format.${stadiumFormat}`)}</h6>
                    </div>
                </>
            ) : (
                <div className='container w-75'>
                    <Badminton user={user} />
                </div>
            )}

            <div className="locationCont container h-auto border border_radius border-border_color py-2 mx-auto">
                <h1 className="font-semibold text-md md:text-lg" style={{ fontSize: "20px" }}>{t("StadiumPageTranslation:Location")}</h1>
                <h2 className="my-2" style={{ fontSize: "14px", color: "var(--body_color)" }}>
                    {stadiumLocation}
                </h2>

                <div className="innerLocation">
                    <iframe
                        className='locationIframe'
                        title="playo-map"
                        loading="lazy"
                        width="100%"
                        id="myIframe"
                        src={stadiumUrl}
                        allowFullScreen=""
                    />
                </div>
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={() => setShowModal(false)}
                className="modal-content"
                style={{
                    overlay: { backgroundColor: 'rgba(0, 0, 0, 0.5)',zIndex:2 },
                    content: {
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        maxWidth: '400px',
                        padding: '20px',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                    }
                }}
            >
                <div className="qr-code-modal">
                    <h2 style={{ marginBottom: '20px' }}>Share QR Code</h2>
                    <div className="qr-code">
                        <PrintComponent ref={componentRef}>
                            <QRCodeCanvas value={window.location.href} />
                        </PrintComponent>
                    </div>
                    <div className="modal-buttons" style={{ marginTop: '20px', textAlign: 'center' }}>
                        <button className="btn btn-primary m-1" onClick={handlePrint}>Print</button>
                        <button className="btn btn-secondary" onClick={handleDownloadQR}>Download</button>
                        <button className="btn btn-close" onClick={() => setShowModal(false)}></button>
                    </div>
                </div>
            </Modal>

            <div className="container mt-4 relative">
                {path === `/staduemBook/${id}` && (
                    <div className="row">
                        <div className="col-12">
                            {user === 'stadiumDashboard' ?
                                <Link to={`/editStadium/${id}`}  type="button" className="btn btn-secondary btn-block px-0" style={{ width: "80%", maxWidth: "80%", left: "50%", backgroundColor: "green", borderColor: "green" }}>
                                    {t("StadiumPageTranslation:Edit")}
                                </Link> :
                                <Link to={`/bookNow/${id}`} type="button" className="btn btn-secondary btn-block px-0" style={{ width: "80%", maxWidth: "80%", left: "50%", backgroundColor: "green", borderColor: "green" }}>
                                    {t("StadiumPageTranslation:Booknow")}
                                </Link>
                            }
                        </div>
                    </div>
                )}
                <div className="row">
                    <div className="col-12">
                        <div style={{ textAlign: 'center' }}>
                            <button
                                type="button"
                                className="btn btn-secondary btn-block px-0 mt-2"
                                style={{ width: "80%", maxWidth: "80%", left: "50%", color: "black", backgroundColor: "white" }}
                                onClick={handleShare}
                            >
                                <i className="bi bi-share-fill" />
                                {t("StadiumPageTranslation:Share")}
                            </button>
                        </div>
                    </div>
                </div>
        </div>
        </div>
    );
}

export default Location;

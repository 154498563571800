import React from 'react';
import TeamMember from './TeamMember';
import '../AboutUs/Team.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper/modules';
import { useTranslation } from 'react-i18next';

const teamMembers = [
    {
        name: "TeamTranslation:Ruaa_Muhamad",
        role: "TeamTranslation:Developer_HI",
        imageSrc: "./assets/rr.jpeg",
        linkedin: 'https://www.linkedin.com/in/ruaa-muhammad-3b2033303?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        github: 'https://github.com/ruaamuhmaad',
        email: 'ruaamuhammad836@gmail.com',
    },
    {
        name: "TeamTranslation:Hossam_Shehadeh",
        role: "TeamTranslation:Team_Leader_Developer",
        imageSrc: "./assets/h.jpeg",
        linkedin: 'https://www.linkedin.com/in/hossam-shehadeh-879580249/',
        github: 'https://github.com/Ho576',
        email: 'hossam.15.2004@gmail.com',
    },
    {
        name: "TeamTranslation:Shahd_Khatabeh",
        role: "TeamTranslation:Developer_DM",
        imageSrc: "./assets/sh.jpeg",
        linkedin: "https://www.linkedin.com/in/shahd-kh-a460702b9?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app",
        github: "https://github.com/shahd-khatatbeh",
        email: "shahdkhatatbeh2004@gmail.com",
    },
    {
        name: "TeamTranslation:Abdullah_Jamal",
        role: "TeamTranslation:Developer_M",
        imageSrc: "./assets/ab.jpeg",
        linkedin: 'https://www.linkedin.com/in/abdullah-jamal-636615308/',
        github: 'https://github.com/AboodJamal',
        email: 'abood.jamal005@gmail.com',
    },
    {
        name: "TeamTranslation:Bara_Mhana",
        role: "TeamTranslation:Developer_AC",
        imageSrc: "./assets/b.jpeg",
        linkedin: 'https://www.linkedin.com/in/bara-mhana-abb29624b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
        github: 'https://github.com/baramhana10',
        email: 'baramhana10@gmail.com',
    },
    {
        name: "TeamTranslation:Suhaila_Isaa",
        role: "TeamTranslation:Developer_CC",
        imageSrc: "./assets/img1.jpeg",
        linkedin: 'https://www.linkedin.com/in/suhaila-issa-408777252?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
        github: 'https://github.com/suhailaissa-eng',
        email: 'suhailaissa610@gmail.com',
    },
    {
        name: "TeamTranslation:Amro_Eid",
        role: "TeamTranslation:Developer_QA",
        imageSrc: "./assets/a.jpeg",
        linkedin: 'https://www.linkedin.com/in/amro-eid-296613308/',
        github: 'https://github.com/AmroEid77',
        email: 'amro.eidd@gmail.com',
    },
    {
        name: "TeamTranslation:Mais_Arman",
        role: "TeamTranslation:Developer_MM",
        imageSrc: "./assets/m2.jpeg",
        linkedin: 'https://www.linkedin.com/in/mais-mutasem-7243702ba/',
        github: 'https://github.com/mais-arman',
        email: 'maisarman122@gmail.com',
    },
];

const Team = () => {
    const { t } = useTranslation();

    return (
        <section>
            <div className="container">
                <h1 className='h1Team'>{t("TeamTranslation:Our_Team_Members")}</h1>
                <Swiper
                    spaceBetween={30}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    modules={[Navigation, Pagination]}
                    breakpoints={{
                        768: { slidesPerView: 2 },
                        992: { slidesPerView: 3 },
                    }}
                >
                    {teamMembers.map((member, index) => (
                        <SwiperSlide key={index}>
                            <TeamMember
                                name={t(member.name)}
                                role={t(member.role)}
                                imageSrc={member.imageSrc}
                                linkedin={member.linkedin}
                                github={member.github}
                                email={member.email}
                            />
                        </SwiperSlide>
                    ))}
                </Swiper>
            </div>
        </section>
    );
};

export default Team;
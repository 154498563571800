import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import "./SkeletonPlayCard.css";
import React from 'react'

const SkeletoonPlayCard = ({ cards }) => {
        return Array(cards)
        .fill(0)
        .map((item,i) => (
            <div className="playCardSkeletoon" key ={i}>
            
            <div className="skeleRight-col">
            <Skeleton variant="rectangular" width={373.4} height={268.4} count={1} />
            </div>
            </div>
        ));
    };
    

export default SkeletoonPlayCard
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFutbol } from '@fortawesome/free-solid-svg-icons';

import './Card.css';
import { useTranslation } from 'react-i18next';

const Card = ({ onClick, stadiumData }) => {
  
  const {t} = useTranslation();
  const handleCardClick = () => {
    if (onClick) {
      onClick(stadiumData); 
      
    }
  };
  return (
    
    <div className="bookCard col-12 col-md-6 col-lg-4 col mb-5" style={{ padding: '10px' }} onClick={handleCardClick}>
      <div className="card  bookcard" style={{ borderRadius: '16px' }}>
        <img
          src={stadiumData.image[0]?.secure_url }
          className="card-img-top bookable-image"
          alt="..."

        />
        <div className="badge text-white position-absolute heartcard" style={{ top: "0rem", right: "0rem" }}>
          <i className="bi bi-heart" />
        </div>
        <div className="card-body">
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h5 className="card-title">{stadiumData.stadiumName}</h5>
            <span style={{ display: 'flex' }}>
              <i className="bi bi-star-fill" style={{ color: '#ffb300' }} />
              <span>4.5 (3037)</span>
            </span>
          </div>
          <p className="card-text">
            {stadiumData.location} (<span className="Distance">~5.2km</span>)
          </p>

          <div>
            <FontAwesomeIcon icon={faFutbol} style={{ fontSize: "20px" }} />
            {stadiumData.Amenities.map((amenity, index) => (
              <span key={index} className="badge rounded-pill custom-ameneties-badge ">{t(`BookTranslation:Format.${amenity}`)}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;



import React, { useState, useTransition } from 'react';
import style from './Search.module.css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export const Search = () => {
  const navigate =useNavigate()
  const {t}=useTranslation()
  const [city, setCity] = useState('');

  const handleChange = (e) => {
    setCity(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (city) {
      navigateToBookingPage();
    }
  };

  const navigateToBookingPage = () => {

    navigate('/book', { state: { city } });
    // window.location.href = `/book?city=${city}`;
  };

  return (
    <>
      <div className={`mx-auto shadow rounded p-4 mt-5  ${style.backColor} ${style.w}`}>
        <h5 className={`mb-3 text-start ${style.text}`}>{t("HomeTranslation:help")}</h5>
        <form className='row g-3' onSubmit={handleSubmit}>
          <div className='col-md-10'>
            <div className='input-group'>
              <input
                className={`form-control form-control-lg me-1 pe-5 `}
                type="text"
                placeholder={t("HomeTranslation:Where")}
                value={city}
                onChange={handleChange}
                id='Where'
              />
            </div>
          </div>
          <div className='col-md-2 d-grid'>
            <button
              type="submit"
              className={`btn btn-lg btn-success text-white`}
              disabled={!city}
            >
              {t("HomeTranslation:Search")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

import React, { useEffect, useState ,useContext } from 'react'

import { FaStar } from "react-icons/fa";
import { AiOutlineStar } from "react-icons/ai";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../../Contexts/UserContext.js';
import Cookies from "universal-cookie";
export const Rate = () => {
  const cookies = new Cookies();
    const {t}=useTranslation()
    const {id}=useParams()
    const [rate,setRate]=useState(null)
    const [hover,setHover]=useState(null)
    const user=useContext(UserContext)
      console.log(user)
    useEffect(() => {
      const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BEURL}/rating/getRate/${user._id}` , {
              headers: {
                  Authorization: `PRIVATE_USER_HIDDEN__${cookies.get('userToken')?.token}`
              }
          });
            if (response.data) {
              setRate(response.data.rating.rate)
                console.log(response.data.rating);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setRate(null);
        }
    };
      fetchData();
  }, []);

  const handleRate = async (currentRating) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_BEURL}/rating/addRate/${id}`, { rate: currentRating });
        await axios.get(`${process.env.REACT_APP_BEURL}/rating/averageRating/${id}`);
        if (response.data.message === "success") {
            setRate(currentRating);
        }
    } catch (error) {
        console.error("Error posting rating:", error);
    }
};

  return (
    <>
        <p style={{ fontWeight: "bold" }}>{t("HomeTranslation:rate")} </p>
        <div className='mb-2'>
        {[...Array(5)].map((star,index)=>{
            const currentRating=index+1
            return (
            <label >
                <input type='radio' name='rating' value={currentRating} onClick={() => handleRate(currentRating)}  style={{ display: 'none' }} />
                {
                  currentRating <= (hover || rate) ?
                  <FaStar 
                size={33} 
                color={ "#FFD700"} 
                onMouseEnter={()=>setHover(currentRating)}
                onMouseLeave={()=>setHover(null)}
                />: <AiOutlineStar size={33} 
                color={ "#FFD700"} 
                onMouseEnter={()=>setHover(currentRating)}
                onMouseLeave={()=>setHover(null)}
                /> 
        }
            </label>
        )
        })}
        </div>
    </>
  )
}
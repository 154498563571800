import React from 'react';
import Select from "react-select";

const LocationSelector = ({ selectedLocation, setSelectedLocation, locations, t }) => {
    return (
        <div className="card-body">
            <h5 className="card-title">{t("AddStadiumTranslation:Location")}</h5>
            <Select
                id="location"
                name="location"
                options={locations}
                onChange={(option) => setSelectedLocation(option)}
                value={selectedLocation}
                classNamePrefix="react-select"
                isClearable
            />
        </div>
    );
};

export default LocationSelector;

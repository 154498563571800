import React from "react";
import Layout from "./Layout.js";
import Home from "./../Home/Home.js";
import PlayPage from "./../Web/Player/Play/Play Page/PlayPage.js";
import AllStadiumBookPage from "../Web/Player/Stadium/AllStadiumBookPage.js";
import BookingPage from "./../Web/Player/Book/BookingPage/BookingPage.js";
import BookNow from "./../Web/Player/Book/BookNow/BookNow.js";
import ChatPage from "./../Web/ChatPage/ChatPage.js";
import Notifications from "./../Web/Notifications/Notifications";
import ProfilePostsPage from "./../Web/ChatPage/CommunityPost/ProfilePostsPage.js";
import History from "./../Web/Player/History/History.js";
import AllPosts from "../Web/ChatPage/CommunityPost/AllPosts.js";
import Dashboard from "../Web/Dashboard/Stadium/Dashboard.js";
import AddStadiumInfo from "../Web/Dashboard/Stadium/AddstadiumInfo/AddStadium.js";
import Reservation from "./../Web/Dashboard/Stadium/Reservation/Reservation.js";
import EditStadium from "./../Web/Dashboard/Stadium/Editstadium/EditStadium.js";
import EventCalendar from "./../Web/Dashboard/Stadium/EventCalendar/EventCalendar";
import AdminDashboard from "./../Web/Dashboard/Admin/AdminDashboard";
import TableOfUsers from "../Web/Dashboard/TableOfUsers/TableOfUsers.js";
import AddStadium from "../Web/Auth/AddStadium/AddStadium.js";
import ErrorPage from "./../ErrorPage/ErrorPage.js";
import Register from "./../Web/Auth/Register/Register.js";
import Login from "../Web/Auth/LogIn/Login.js";
import MoreInformation from "./../Web/Auth/MoreInfo/MoreInformation.js";
import ConfirmEmail from "../Web/Auth/ConfirmEmail/ConEmail.js";
import LocationTable from "../Web/Dashboard/Admin/LocationTable.js";
import UserContextProvider from "../Contexts/UserContext.js";
import EditProfile from "../Web/Player/Profile/ProfileHeader/EditProfile.js";
import AboutUs from "../AboutUs/Team.js";
import ForgetPassword from "../Web/Auth/ForgetPassword/ForgetPassword.js";
import Settings from "../Settings/Settings.js";
import StadiumContextProvider from "../Contexts/StadiumContixt.js";
import Stadiums from "../Web/Dashboard/Admin/Stadiums.js";
import Users from "../Web/Dashboard/Admin/Users.js";


const navigateThroughWorlds = (user, setUser) => {
    return (
        !user ? [
            {
                path: "",
                element: <Layout user={user} setUser={setUser} />,
                children: [
                    {index: true, element: <Home />},
                    {path: "play", element: <PlayPage />},
                    {path: "game/:idReservation/:id", element: <AllStadiumBookPage user={user} />},
                    {path: "book", element: <BookingPage />},
                    {path: "staduemBook/:id", element: <AllStadiumBookPage user={user} />},
                    {path: "bookNow/:id", element: <UserContextProvider><BookNow user={user} /></UserContextProvider>},
                    {path: "about", element: <AboutUs />},
                    {path: "*", element: <ErrorPage />},
                ]
            },
            {path: "register", element: <Register />},
            {path: "moreInfo", element: <MoreInformation />},
            {path: "login", element: <Login />},
            {path: "confirmEmail", element: <ConfirmEmail />},
            {path: "ForgetPassword", element: <ForgetPassword />},
        ] : user === 1 ? [
            {
                path: "",
                element: <Layout user={user} setUser={setUser} />,
                children: [
                    {index: true, element: <Home />},
                    {path: "play", element: <PlayPage />},
                    {path: "game/:idReservation/:id", element: <UserContextProvider><AllStadiumBookPage user={user} /> </UserContextProvider>},
                    {path: "book", element: <BookingPage />},
                    {path: "staduemBook/:id", element: <AllStadiumBookPage user={user} />},
                    {path: "bookNow/:id", element: <UserContextProvider><BookNow user={user} /></UserContextProvider>},
                    {path: 'chat', element: <ChatPage />},
                    {path: "notifications", element: <UserContextProvider><Notifications /></UserContextProvider>},
                    {
                        path: "profile/:id",
                        element: user ?
                            <UserContextProvider>
                                <ProfilePostsPage />
                            </UserContextProvider>
                            : <div className="mt-5"><Login /></div>,
                        children: [
                            {path: "books", element: <History />},
                            {
                                path: "/profile/:id", element:
                                    <UserContextProvider>
                                        <AllPosts />
                                    </UserContextProvider>
                            },
                        ]
                    },
                    {
                        path: "editProfile",
                        element:
                            <UserContextProvider>
                                <EditProfile />
                            </UserContextProvider>
                    },
                    {path: "about", element: <AboutUs />},
                    {
                        path: "settings", element: <UserContextProvider>
                            <Settings />
                        </UserContextProvider>
                    },
                    {path: "*", element: <ErrorPage />},
                ]
            }
        ] : user === "stadiumDashboard" ? [
            {
                path: "",
                element: <Layout user={user} setUser={setUser} />,
                children: [
                    {
                        index: true, element:
                            <StadiumContextProvider>
                                <Dashboard />
                            </StadiumContextProvider>
                    },
                    {path: 'chat', element: <ChatPage />},
                    {path: "notifications", element: <Notifications />},
                    {path: "reservation", element: <StadiumContextProvider><Reservation /> </StadiumContextProvider>},
                    {path: "staduemBook/:id", element: <AllStadiumBookPage user={user} />},
                    {path: 'editStadium/:id', element: <StadiumContextProvider> <EditStadium /></StadiumContextProvider>},
                    {path: 'stadiumCalender', element: <StadiumContextProvider><EventCalendar /></StadiumContextProvider>},
                    {path: "about", element: <AboutUs />},
                    {
                        path: "settings", element:
                            <StadiumContextProvider>
                                <Settings />
                            </StadiumContextProvider>
                    },
                    {path: "*", element: <ErrorPage />},
                ]
            },
        ] : user === "adminDashboard" ? [
            {
                path: "",
                element: <Layout user={user} setUser={setUser} />,
                children: [
                    {index: true, element: <AdminDashboard />},
                    {path: 'chat', element: <ChatPage />},
                    {path: "notifications", element: <Notifications />},
                    {path: 'users', element: <Users/>},
                    {path: 'stadiums', element: <Stadiums/>},
                    {path: 'addStadium', element: <AddStadium />},
                    {path: "location", element: <LocationTable />},
                    {path: "about", element: <AboutUs />},
                    {path: "*", element: <ErrorPage />},
                ]
            }
        ] : [
            {
                path: "*",
                element: <ErrorPage />,
            }
        ]
    );
}

export default navigateThroughWorlds;

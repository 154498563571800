import React from 'react'
import Base from '../Base/Base.js'
import './Login.css';


export default function Login() {
  return (
<>
<Base inputType={'InputesLogin'}/>
</>
  )
}

        import React from 'react'
        import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
        import './StadiumSkeletonCard.css';

        const StadiumSkeletonCard = ({cards}) => {
                return Array(cards)
                .fill(0)
                .map((item, i) => (
                    <div className="stadiumCardSkeleton" key={i}>
                    <div className="stadiumSkeletonLeftCol">
                        <Skeleton variant="rectangular" width={600} height={300} />
                    </div>
                    <div className="stadiumSkeletonRightCol">
                        <Skeleton animation="wave" height={60} width="97%" className="titleSkeleton" />
                        <Skeleton animation="wave" height={20} width="30%" className="ratingSkeleton" />
                        <Skeleton animation="wave" height={9} width="40%" />
                        <Skeleton animation="wave" height={10} width="20%" />
                        <Skeleton animation="wave" height={9} width="10%" />
                        
                    </div>
                    
                    </div>
                    
                ));
            };
    export default StadiumSkeletonCard
import React, { useEffect, useState } from 'react'
import TableOfUsers from '../TableOfUsers/TableOfUsers.js';
import axios from 'axios';
import { defaultProfilePic } from '../../Player/Profile/ProfileHeader/ProfileHeader.js';

export default function Stadiums() {
    const [stadiumsInfo, setStadiumsInfo] = useState([]);

    useEffect(() => {
        const fetchStadiumsData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/getStadiumsData`);
                const data = response.data;
                console.log(data);
                setStadiumsInfo(data.map((stadium, index) => ({
                    id: index + 1,
                    // avatarSrc: stadium.image[0], 
                    avatarSrc: defaultProfilePic, 
                    userName: stadium.stadiumName?stadium.stadiumName:"stadium not define",
                    totalSpent: "0",
                    email: stadium.email
                })));
            } catch (error) {
                console.log(error);
            }
        };

        fetchStadiumsData();
    }, []);
  return (
    <TableOfUsers title="Stadiums" showBookingInfo={false} usersData={stadiumsInfo} action={false} />
  )
}

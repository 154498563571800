import React from 'react';

const StadiumNameInput = ({ stadiumName, setStadiumName, t }) => {
    const stadiumNamePlaceholder = t("AddStadiumTranslation:AddStadiumName");

    return (
        <div className="card-body">
            <h5 className="card-title">{t("AddStadiumTranslation:StadiumName")}</h5>
            <input
                type="text"
                id="stadiumNameInput"
                className="Regester-form-control green-border mb-3 mb-md-0 custom-input"
                placeholder={stadiumNamePlaceholder}
                required
                value={stadiumName}
                onChange={(e) => setStadiumName(e.target.value)}
            />
            <div className="invalid-feedback">{t("AddStadiumTranslation:EnterStadiumName")}</div>
        </div>
    );
};

export default StadiumNameInput;

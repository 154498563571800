import React, { useContext, useEffect, useState } from 'react';
import PaymentModal from '../Models/PaymentModle.js';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from '../../../../../Contexts/UserContext.js';

const BookingForm = ({ setLay, currantEvent  }) => {

  const {userData}= useContext(UserContext);
  const navigate =useNavigate()
  const [discountCode, setDiscountCode] = useState(1);
  const [total, setTotal] = useState(currantEvent.price * currantEvent.times * discountCode);
  const [isPaid, setIsPaid] = useState(false);
  const [open, setOpen] = useState(false);
  const [stadiumData, setStadiumData] = useState({});
  const { id } = useParams();
  

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/staduemBook/${id}`);
        if (response.data && response.data.stadium) {
          setStadiumData(response.data.stadium);
          
        } else {
          console.error("Expected 'stadium' to be an object, but received:", response.data.stadium);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [id]);

  const handleDiscountCodeChange = (event) => {
    setDiscountCode(event.target.value);
  };

  const handleApplyDiscount = () => {
    if (discountCode === 'SAVE10') {
      setTotal(total * 0.9); // Apply a 10% discount
    }
  };

  const handleConfirmAndPay = async () => {
    try {
      let eventStatus;
      if (currantEvent.eventType === 'private') {
        eventStatus = 'booked';
      } else {
        eventStatus = 'joinable';
      }
      const response = await axios.post(`${process.env.REACT_APP_BEURL}/reservation`, {
        status: eventStatus,
        price: currantEvent.price,
        start: currantEvent.start,
        end: currantEvent.end,
        hostId: userData.id, 
        stadiumId: id
      });
      if (response.data && response.data.stadium) {
        setStadiumData(response.data.stadium);
      } else {
        console.error("Expected 'stadium' to be an object, but received:", response.data.stadium);
      }
      if( eventStatus =='joinable')
        {
          const responsePlay = await axios.post(`${process.env.REACT_APP_BEURL}/playPage/listOfPlayers`, {
            Goalkeeper: currantEvent.additionalInputs.goalkeepers,
            Defender: currantEvent.additionalInputs.defenders,
            Midfielder: currantEvent.additionalInputs.midfielders,
            Attacker: currantEvent.additionalInputs.attackers,
            game:response.data._id
          });
        
        }
        window.location.reload();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleCancel = () => {
    setLay(false);
    setDiscountCode('');
    setTotal(39.00);
  };

  const handleClose = (flag) => {
    if (flag) {
      setIsPaid(true);
      handleConfirmAndPay();
    }
    setOpen(false);
    setLay(false);
  };

  const bookNowClick = ()=>{
    if (!userData?.email){
      navigate('/login');
    }
    else{
      setOpen(true);
    }
  }
  const formattedStartDate = new Date(currantEvent?.start);
  const formattedEndDate = new Date(currantEvent?.end);

  return (
    <>
      <form className="container mt-5">
        <h1>{stadiumData ? stadiumData?.stadiumName : 'Stadium Name'}</h1>
        <img
          src={`${stadiumData?.image && stadiumData.image.length > 0 ? stadiumData.image[0]?.secure_url : 'https://www.thefa.com/-/media/images/thefaportal/my-football/106786878_td_1320_d46ee4a4f94d977e62b7d151d8ae3a68.ashx?as=0&dmc=0&thn=0'}`}
          alt="Stadium"
          className="stadium-image w-25"
        />
        <p className="lead">Sport: Football</p>
        <p className="lead">Format: {stadiumData?.stadiumFormat}</p>
        {/* <p className="lead">Times: {currantEvent?.times}</p> */}
        <p className="lead">Date: {formattedStartDate?.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
        <p className="lead">Time: {formattedStartDate?.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}-{formattedEndDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric' })}</p>
        <p className="lead">Duration: {Math.abs(formattedEndDate - formattedStartDate) / 60000} min</p>
        <p className="lead">Price: ${currantEvent?.price.toFixed(2) * currantEvent?.times}</p>
        <div className="input-group mb-3">
          <input type="text" className="form-control" value={discountCode} onChange={handleDiscountCodeChange} placeholder="Discount Code" />
          <button className="btn btn-primary h-75" type="button" onClick={handleApplyDiscount}>APPLY</button>
        </div>
        <p className="lead">Total: ${total.toFixed(2)}</p>
        {isPaid ? (
          <p className="lead">Payment confirmed. Thank you!</p>
        ) : (
          <>
            <button className="btn btn-success mr-2" type="button" onClick={bookNowClick}>CONFIRM AND PAY ${total.toFixed(2)}</button>
            <button className="btn btn-danger" type="button" onClick={handleCancel}>CANCEL</button>
          </>
        )}
      </form>
      {open && <PaymentModal isOpen={open} onClose={handleClose} handleAvailableEvent={handleConfirmAndPay} total={total} />}
    </>
  );
};

export default BookingForm;

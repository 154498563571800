import React, { useEffect, useState } from 'react'
import TableOfUsers from '../TableOfUsers/TableOfUsers.js';
import axios from 'axios';
import { defaultProfilePic } from '../../Player/Profile/ProfileHeader/ProfileHeader.js';

export default function Users() {
    const [usersData, setUsersData] = useState([]);
    const [deletedUser, setDeletedUser] = useState(false);
  

    const fetchUsersData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BEURL}/user`);
            const data = response.data;
            console.log(data)
            setUsersData(data.map((user, index) => ({
                id: index + 1,
                avatarSrc: user.profilePic?.secure_url || defaultProfilePic,
                userName: user.userName,
                email: user.email,
                totalSpent: "0",
                privateID: user._id
            })));
        } catch (error) {
            console.log(error);
        }
    };
    useEffect(() => {
        fetchUsersData();
    }, []);

    useEffect(() => { fetchUsersData(); }, [deletedUser]);
  return (
    <TableOfUsers title="Users" showBookingInfo={false} usersData={usersData} setDeletedUser={setDeletedUser} deletedUser={deletedUser} action={true} />
  )
}

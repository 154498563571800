import './PaymentDetails.css'
import {useTranslation} from "react-i18next";
const PaymentDetails = () => {
    const {t}=useTranslation()
    return (
        <div className="card bg-transparent border">
            {/* Card header */}
            <div className="card-header bg-transparent border-bottom">
                <h4 className="card-header-title">{t('SettingsTranslation:PaymentDetails') }</h4>
            </div>
            {/* Card body START */}
            <div className="card-body p-2 p-sm-4">
                <h5>{t('SettingsTranslation:SavedCard') }</h5>
                <div className="row g-4">
                    {/* Visa card */}
                    <div className="col-md-6">
                        <div className="card">
                            <div className="bg-primary p-4 rounded-3">
                                <div className="d-flex justify-content-between align-items-start">
                                    <img className="w-40px" src="https://booking.webestica.com/assets/images/element/visa.svg" alt="Visa" />
                                    {/* Card action START */}
                                    <div className="dropdown">
                                        <a
                                            className="text-white"
                                            href="#"
                                            id="creditcardDropdown"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false"
                                        >
                                            {/* Dropdown Icon */}
                                            <svg width={24} height={24} fill="none">
                                                <circle fill="currentColor" cx="12.5" cy="3.5" r="2.5" />
                                                <circle fill="currentColor" opacity="0.5" cx="12.5" cy="11.5" r="2.5" />
                                                <circle fill="currentColor" opacity="0.3" cx="12.5" cy="19.5" r="2.5" />
                                            </svg>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="creditcardDropdown">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-credit-card-2-front-fill me-2 fw-icon" />
                                                    Edit card
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-calculator me-2 fw-icon" />
                                                    Currency converter
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Card action END */}
                                </div>
                                <h4 className="text-white mt-4">**** **** **** 1569</h4>
                                <div className="d-flex justify-content-between text-white">
                                    <span>{t('SettingsTranslation:ValidThru') }: 12/26</span>
                                    <span>CVV: ***</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Master card */}
                    <div className="col-md-6">
                        <div className="card">
                            <div className="bg-danger p-4 rounded-3">
                                <div className="d-flex justify-content-between align-items-start">
                                    <img className="w-40px imggg" src="https://booking.webestica.com/assets/images/element/visa.svg" alt="MasterCard" />
                                    {/* Card action START */}
                                    <div className="dropdown">
                                        <a
                                            className="text-white"
                                            href="#"
                                            id="creditcardDropdown2"
                                            role="button"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="outside"
                                            aria-expanded="false"
                                        >
                                            {/* Dropdown Icon */}
                                            <svg width={24} height={24} fill="none">
                                                <circle fill="currentColor" cx="12.5" cy="3.5" r="2.5" />
                                                <circle fill="currentColor" opacity="0.5" cx="12.5" cy="11.5" r="2.5" />
                                                <circle fill="currentColor" opacity="0.3" cx="12.5" cy="19.5" r="2.5" />
                                            </svg>
                                        </a>
                                        <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="creditcardDropdown2">
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-credit-card-2-front-fill me-2 fw-icon" />
                                                    Edit card
                                                </a>
                                            </li>
                                            <li>
                                                <a className="dropdown-item" href="#">
                                                    <i className="bi bi-calculator me-2 fw-icon" />
                                                    Currency converter
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* Card action END */}
                                </div>
                                <h4 className="text-white mt-4">**** **** **** 5620</h4>
                                <div className="d-flex justify-content-between text-white">
                                    <span>{t('SettingsTranslation:ValidThru') }: 02/35</span>
                                    <span>CVV: ***</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Add new card START */}
                <div  className="card border mt-4">
                    <div className="card-header border-bottom">
                        <h5 className="card-header-title">{t('SettingsTranslation:AddNewCard') }</h5>
                    </div>
                    <div className="card-body">
                        <form className="row text-start g-4">
                            {/* Card number */}
                            <div className="col-12">
                                <label className="form-label">
                                {t('SettingsTranslation:CardNumber') }<span className="text-danger">*</span>
                                </label>
                                <div className="position-relative">
                                    <input type="text" className="form-control" placeholder="xxxx xxxx xxxx xxxx" />
                                    
                                </div>
                            </div>
                            {/* Expiration Date */}
                            <div className="col-md-6">
                                <label className="form-label">
                                {t('SettingsTranslation:ExpirationDate') }<span className="text-danger">*</span>
                                </label>
                                <div className="input-group">
                                    <input type="text" className="form-control" maxLength={2} placeholder={t('SettingsTranslation:Month') } />
                                    <input type="text" className="form-control" maxLength={4} placeholder={t('SettingsTranslation:Year') } />
                                </div>
                            </div>
                            {/* Cvv code  */}
                            <div className="col-md-6">
                                <label className="form-label">
                                    CVV / CVC<span className="text-danger">*</span>
                                </label>
                                <input type="text" className="form-control" maxLength={3} placeholder="xxx" />
                            </div>
                            {/* Card name */}
                            <div className="col-12">
                                <label className="form-label">
                                {t('SettingsTranslation:NameOnCard') }<span className="text-danger">*</span>
                                </label>
                                <input type="text" className="form-control" aria-label="name of card holder" placeholder={t('SettingsTranslation:EnterName') }/>
                            </div>
                            {/* Button */}
                            <div className="col-12">
                                <button className="btn btn-primary mb-0 settings-button">{t('SettingsTranslation:AddCard') }</button>
                            </div>
                        </form>
                    </div>
                </div>
                {/* Add new card END */}
            </div>
            {/* Card body END */}
        </div>
    );
};

export default PaymentDetails;
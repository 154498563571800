import React, { useEffect, useState } from 'react';
import Input from '../Input/Input.js';
import { useFormik } from 'formik';
import { loginSchema } from '../Validation/Validation.js';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function InputStadium() {
  const { t } = useTranslation();
  const initialValues = {
    email: '',
    password: '',
    locationURL: '',
  };
  const [exists, setExists] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: values => {
      console.log('Form submitted:', values);
      const fetch = async () => {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BEURL}/admin/addStadium`, { stadiumEmail: values.email, stadiumpassword: values.password, stadiumLocationURL: values.locationURL });
          
          if (response?.data.message == "Stadium added succesfuly") {
            navigate('/stadiums');
            // window.location.reload();
          }
          else if (response?.data.message === "email is already exists") {
            setExists(true);
            formik.resetForm();
          }
        } catch (error) {
          console.error('Login error:', error);
          toast.error('Failed to login. Please try again.', {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      };
      fetch();
      formik.setValues({
        email: '',
        password: '',
        locationURL: " ",
      });
    }
  });

  useEffect(() => {
    setExists(false);
  }, [formik.values.email]);

  const handleInputChange = (e) => {
    const fieldName = e.target.name;
    formik.setFieldTouched(fieldName, false);
    formik.handleChange(e);
  };

  const Inputes = [
    {
      id: 'email',
      type: 'email',
      name: 'email',
      title: t('InputesTranslation:email'),
      value: formik.values.email,
    }, 
    {
      id: 'password',
      type: 'password',
      name: 'password',
      title: t('InputesTranslation:password'),
      value: formik.values.password,
    },
    {
      id: 'url',
      type: 'url',
      name: 'locationURL',
      title: t('InputesTranslation:locationURL'),
      value: formik.values.locationURL,
    },
  ];

  const renderInput = Inputes.map((ele, index) =>
    <Input 
      value={ele.value}
      type={ele.type}
      name={ele.name}
      id={ele.id}
      title={ele.title}
      onchange={handleInputChange}
      errors={formik.errors}
      touched={formik.touched}
      key={index} 
    />
  );

  return (
    <div className="card2 rounded-5 col-sm-12 col-md-12 mt-5">
      <h2 className='card-title text-center mt-3 h2 text-success' style={{ marginLeft: '30px', marginRight: '30px' }}>{t('InputesTranslation:addNewStadium')}</h2>
      <form onSubmit={formik.handleSubmit} className='mt-3 mb-3' style={{height:"450px"}}>
        {renderInput}
        {
          exists && (
            <p className="text-center text-danger">{t('InputesTranslation:emailExists')}</p>
          )
        }
        <div className="mt-5">
          <button type="submit" className="d-flex mt-5 mb-4 flex-column align-items-center btn btn-success w-75 p-md-3 mx-auto">{t('InputesTranslation:add')}</button>
        </div>
      </form>
    </div>
  );
}

export default InputStadium;

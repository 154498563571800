import React, { useState } from "react";
import Card from "./Card";
import { Link, useNavigate } from "react-router-dom";
import SkeletonBookCard from "./SkeletonBooksCards.js";

const AllCards = ({ stadiums, searchCity,isLoading}) => {
    console.log(stadiums,1);
    const [visibleCards, setVisibleCards] = useState(5);

    const handleShowMore = () => {
        setVisibleCards(prevVisibleCards => prevVisibleCards + 3);
    };

    const navigate = useNavigate();


    const filteredStadiums = stadiums.filter(stadium =>
        stadium?.location?.toLowerCase().includes(searchCity.toLowerCase())
    );
    console.log(filteredStadiums);
    const maxCards = filteredStadiums.length;

    const handleCardClick = (stadiumData) => {
        
        navigate(`/staduemBook/${stadiumData._id}`);
    };

    return (
        <>
            <div className="allCards col row gx-2 gx-md-4 row-cols-2 row-cols-md-3 row-cols-sm-2 row-cols-xl-3" style={{ padding: "40px" }}>
                {isLoading ? (
                    Array.from({ length: visibleCards }).map((index) => (
                        <div key={index} className="col-12 col-md-6 col-lg-4 col mb-5">
                            <SkeletonBookCard />
                        </div>
                    ))
                ) : (
                    filteredStadiums.slice(0, visibleCards).map((stadium, index) => (
                        <Card key={index} onClick={handleCardClick} stadiumData={stadium} />
                    ))
                )}
            </div>
            <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                {visibleCards < maxCards && (
                    <button onClick={handleShowMore} type="button" className="btn" style={{ color: "#31C15A", width: "200px", height: "50px" }}>
                        See More Stadiums
                    </button>
                )}
            </div>
        </>
    );
};

export default AllCards;

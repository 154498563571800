import Header from './Header.js'
import InputForm from './InputForm';

function AddStadium() {
  return (
    
    <div className='mt-5'>
    <Header text ={'Enter'}/>
    <InputForm />
    </div>
  )
}

export default AddStadium;
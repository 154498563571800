import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { StadiumContext } from '../../../../Contexts/StadiumContixt.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import './InputForm.css';
import FileUploadComponent from './AddImage.js';
import StadiumNameInput from './Shaerd/StadiumNameInput.js';
import AmenitiesSelector from './Shaerd/AmenitiesSelector.js';
import AboutStadiumTextarea from './Shaerd/AboutStadiumTextarea.js';
import LocationSelector from './Shaerd/LocationSelector.js';
import StadiumFormatButtons from './Shaerd/StadiumFormatButtons.js';
import PhoneNumberInput from './Shaerd/PhoneNumberInput.js';
import ImagePreviewList from './Shaerd/ImagePreviewList.js';

const InputForm = () => {
    const { t } = useTranslation();
    const { stadiumData } = useContext(StadiumContext);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({});
    const [phone, setPhone] = useState('');
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({});
    const [selectedStadiumFormat, setSelectedStadiumFormat] = useState('');
    const [stadiumName, setStadiumName] = useState('');
    const [aboutStadium, setAboutStadium] = useState('');
    const [images, setImages] = useState([]);
    const [locations, setLocations] = useState([]);
    const [files, setFiles] = useState([]);

    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/location/getLocation`);
                const locationOptions = response.data.locData.map(location => ({
                    value: location._id,
                    label: location.locatioName
                }));
                setLocations(locationOptions);
            } catch (error) {
                console.error("Error fetching locations:", error);
            }
        };

        fetchLocations();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            if(stadiumData){
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/staduemBook/${stadiumData?.id}`);

                console.log(response);
                if (response.data && response.data.stadium) {
                    setInitialValues(response.data.stadium);
                } else {
                    console.error("Expected 'stadium' to be an object, but received:", response.data.stadium);
                    setInitialValues({});
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                setInitialValues({});
            }
        }
        };

        fetchData();
    }, [stadiumData]);

    const handleImageDelete = (index) => {
        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        setImages(updatedImages);
    };

    const handleAmenityChange = (event) => {
        const selectedAmenity = event.target.value.trim();
        if (selectedAmenity && !selectedAmenities.includes(selectedAmenity)) {
            setSelectedAmenities(prevAmenities => [...prevAmenities, selectedAmenity]);
        }
    };

    const removeAmenity = (index) => {
        const updatedAmenities = [...selectedAmenities];
        updatedAmenities.splice(index, 1);
        setSelectedAmenities(updatedAmenities);
    };

    const submitForm = async (event) => {
        event.preventDefault();
    
        if (!validateForm()) {
            return;
        }
    
        const formData = new FormData();
        formData.append('phoneNumber', phone);
        formData.append('Amenities', JSON.stringify(selectedAmenities)); 
        formData.append('location', selectedLocation.label);
        formData.append('stadiumFormat', selectedStadiumFormat);
        formData.append('stadiumName', stadiumName);
        formData.append('aboutStadium', aboutStadium);
        files.forEach((file) => {
            formData.append('image', file);
        });
        if(stadiumData){
        try {
            const response = await axios.patch(`${process.env.REACT_APP_BEURL}/stadium/addStadiumInfo/${stadiumData?.id}`, formData);
            navigate('/stadiumCalender');
            console.log(response.data);
        } catch (error) {
            console.error("Error submitting form:", error);
        }
    }
    };

    const validateForm = () => {
        if (initialValues) {
            return true;
        }
        if (!phone || !stadiumName) {
            return false;
        }
        return true;
    };

    const formComponents = [
        [
            { component: <StadiumNameInput stadiumName={stadiumName} setStadiumName={setStadiumName} t={t} />, className: "col-md" },
            { component: <AmenitiesSelector selectedAmenities={selectedAmenities} handleAmenityChange={handleAmenityChange} removeAmenity={removeAmenity} t={t} />, className: "col-md" },
        ],
        [
            { component: <AboutStadiumTextarea aboutStadium={aboutStadium} setAboutStadium={setAboutStadium} t={t} />, className: "col-md" },
            { component: <LocationSelector selectedLocation={selectedLocation} setSelectedLocation={setSelectedLocation} locations={locations} t={t} />, className: "col-md" },
        ],
        [
            { component: <StadiumFormatButtons selectedStadiumFormat={selectedStadiumFormat} setSelectedStadiumFormat={setSelectedStadiumFormat} t={t} />, className: "col-md" },
            { component: <PhoneNumberInput phone={phone} setPhone={setPhone} t={t} />, className: "col-md" },
        ],
        [
            { component: <FileUploadComponent files={files} setFiles={setFiles} onFileSelect={setImages} />, className: "col-12" },
        ],
        [
            { component: initialValues?.stadiumName && <ImagePreviewList images={initialValues?.image||images} handleImageDelete={handleImageDelete} t={t} />, className: "col-12" },
        ],
        [
            { component: <button type="submit" className="btn btn-success">{initialValues.stadiumName ? t("AddStadiumTranslation:EditButton") : t("AddStadiumTranslation:SubmitButton")}</button>, className: "col-md" }
        ]
    ];



    return (
        <div className="container">
            <form onSubmit={submitForm}>
                {formComponents.map((row, rowIndex) => (
                    <div key={rowIndex} className="row mt-4">
                        {row.map((item, itemIndex) => (
                            <div key={itemIndex} className={item.className}>
                                {item.component}
                            </div>
                        ))}
                    </div>
                ))}
            </form>
        </div>
    );
}

export default InputForm;

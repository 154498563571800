import React from 'react';
import Draggable from 'react-draggable';

const Event = ({ event, removeEvent, moveEvent, update }) => {
  
    return (
      <Draggable onStop={(e, data) => moveEvent(data, event.day, event.start, event._id)}>
      <div style={{ backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "5px", boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)", cursor: "move" }}>
        <button className='btn btn-primary mr-2' style={{ backgroundColor: "#007bff", border: "none" }} onClick={() => update(event)}>Update</button>
        <button className='btn btn-danger' style={{ backgroundColor: "#dc3545", border: "none" }} onClick={() => removeEvent(event._id)}>Remove</button>
      </div>
    </Draggable>
    
    );
  };

export default Event;

import React, { useState, useEffect, useContext } from 'react';
import './Sidebar.css';
import { Link, Outlet, useLocation, useParams } from 'react-router-dom'; // Import useLocation hook
import Footer from '../Footer/Footer.js';
import { StadiumContext } from '../Contexts/StadiumContixt.js';

function Sidebar({ user }) {


    let { stadiumData, loading } = useContext(StadiumContext);

    if(user == "adminDashboard") {
        stadiumData=1
         loading=0
    }

    useEffect(()=>{

    },[loading])
    

    const [stadiumAddedInfo, setStadiumAddedInfo] = useState(0);

    const stadiumDashboardContent = stadiumAddedInfo ? [
        { name: 'Dashboard', to: '', icon: 'fa-tachometer-alt' },
        { name: 'Calender', to: 'stadiumCalender', icon: 'bi bi-calendar-event' },
        { name: 'Stadium Info', to: `staduemBook/${stadiumData.id}`, icon: 'bi bi-square' },
        { name: 'Reservations', to: 'reservation', icon: 'bi bi-bookmark-check-fill' }
    ] : [
        { name: 'Add Info', to: '', icon: 'fa-solid fa-plus' },
        { name: 'Calender', to: 'stadiumCalender', icon: 'bi bi-calendar-event' },
    ];

    const AdminDashboardContent = [
        { name: 'Dashboard', to: '', icon: 'fas fa-tachometer-alt fa-fw me-3' },
        { name: 'Users', to: 'users', icon: 'bi bi-people' },
        { name: 'Stadiums', to: 'stadiums', icon: 'bi bi-square' },
        { name: 'Add Stadium', to: 'addStadium', icon: 'fa-solid fa-plus' },
        { name: 'Locations', to: 'location', icon: 'fa-solid fa-plus' }
    ];

    const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
    const location = useLocation(); 

    useEffect(() => {
        const handleResize = () => {
            setIsSidebarCollapsed(window.innerWidth < 750);
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sidebarClass = ` d-lg-block sidebar  ${isSidebarCollapsed ? ' ml-5' : ''}`;


    useEffect(() => {
        if (stadiumData?.name) {
            setStadiumAddedInfo(1);
        }
    }, [stadiumData]);
    
    if(loading){
        return <p>loading</p>
    }
    return (
        <>
            <div className="sidebar-container">
                <nav id="sidebarMenu" className={sidebarClass}>
                    <div className="position-sticky">
                        <div className="list-group list-group-flush mx-3 mt-4 ">
                            {user === 'adminDashboard' ?
                                AdminDashboardContent.map((item, index) => (
                                    <Link key={index} to={item.to} className={`list-group-item list-group-item-action py-2 button-side-bar ripple${location.pathname === '/' + item.to ? ' active' : ''}`}>
                                        <i className={`fas ${item.icon} fa-fw me-3`} />
                                        {!isSidebarCollapsed && <span>{item.name}</span>}
                                    </Link>
                                )) :
                                stadiumDashboardContent.map((item, index) => (
                                    <Link key={index} to={item.to} className={`list-group-item list-group-item-action button-side-bar py-2 ripple${location.pathname === '/' + item.to ? ' active' : ''}`}>
                                        <i className={`fas ${item.icon} fa-fw me-3`} />
                                        {!isSidebarCollapsed && <span>{item.name}</span>}
                                    </Link>
                                ))
                            }
                        </div>
                    </div>
                </nav>
            </div>
            <div className="main-content mt-5">
                <Outlet />
                <Footer />
            </div>
        </>
    );
}

export default Sidebar;

import axios from 'axios';
import './EmailForm.css'
import {useState} from 'react';
import {useTranslation} from "react-i18next";
import Cookies from 'universal-cookie';


const EmailForm = ({ email, setEmail ,isUser,id}) => {
    const {t}=useTranslation()
    const cookies = new Cookies();
    const [newEmail, setNewEmail] = useState(email);
    const updateEmail = async () => {
        try {
            const token = cookies.get('userToken');
            if (!token) {
                
                return;
            }

            const url = `${process.env.REACT_APP_BEURL}/auth/sendUpdateEmailConfirmation`;
            const updateEmailUser = await axios.post(url, {
                isUser,
                newEmail,
            },
            {
                headers: {
                    authorization: `PRIVATE_USER_HIDDEN__${token.token}`
                }
            });
            console.log(updateEmailUser);
        } catch (error) {
            console.error("Error updating email:", error);
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        updateEmail();
    }


    return (
        <div className="card-border">
            <div id='emailDark' className="emailDark">
            <div className="card-header border-bottom">
                <h4 className="card-header-title-email">{t('SettingsTranslation:UpdateEmail')}</h4>
                <p className="mb-0">{t('SettingsTranslation:CurrentEmail')}<span className="text-primary">{email}</span></p>
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit}>
                    <label className="form-label">{t('SettingsTranslation:EnterEmail') }<span className="text-danger">*</span></label>
                    <input
                        type="email"
                        className="form-control"
                        placeholder={t('SettingsTranslation:EnterEmail') }
                        value={newEmail}
                        onChange={(e) => setNewEmail(e.target.value)}
                    />
                    <div className="text-end mt-3">
                        <button className="btn btn-primary mb-0 settings-button" type="submit" >{t('SettingsTranslation:SaveEmail') }</button>
                    </div>
                </form>
            </div>
            </div>
        </div>
    );
};

export default EmailForm;




import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import Cookies from 'universal-cookie';
import {useTranslation} from "react-i18next";
import './DeleteProfile.css'

const DeleteProfile = ({isUser, id}) => {
    const {t}=useTranslation()
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const cookies = new Cookies();
    const logOut=()=>{
        cookies.remove("userToken");
        navigate("/");
        window.location.reload();
    };

    const navigate = useNavigate();
    const handleDelete = async () => {
        
        try {
            const url = isUser
                ? `${process.env.REACT_APP_BEURL}/user/${id}`
                : `${process.env.REACT_APP_BEURL}/stadium/${id}`;
            
            const deleteAccount=await axios.delete(url);
            
            logOut()
            
        } catch (error) {
            console.error("Error deleting profile:", error);
        }
    }

    return (
        <div className="card border">

            <div className="cardHeadDark   card-header border-bottom">
                <h4 className="card-header-title">{t('SettingsTranslation:DeleteAccount') }</h4>
            </div>
            <div className="deleteDark card-body">
                <h6>{t('SettingsTranslation:Before') }</h6>
                <ul>
                    <li>{t('SettingsTranslation:If') }</li>
                </ul>
                <div className="form-check form-check-md my-4">
                    <input
                        className="form-check-input"
                        type="checkbox"
                        value={isChecked}
                        onChange={handleCheckboxChange}
                        id="deleteaccountCheck"
                    />
                    <label className="form-check-label" htmlFor="deleteaccountCheck">
                    {t('SettingsTranslation:Yes') }
                    </label>
                </div>
                <button className="btn btn-success-soft btn-sm mb-2 mb-sm-0 keep-button" onClick={() => {
                    navigate('/')
                }}>
                    {t('SettingsTranslation:Keep') }
                </button>
                <button
                    className="btn btn-danger btn-sm m-2"
                    disabled={!isChecked} 
                    onClick={handleDelete}
                >
                    {t('SettingsTranslation:DeleteMY') }
                </button>
            </div>
       
        </div>
    );
};

export default DeleteProfile;

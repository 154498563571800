import React from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';

const UserRow = ({ avatarSrc, userName, email, totalSpent, showBookingInfo, bookingInfo, privateID, setDeletedUser, deletedUser,action }) => {
    const badgeStyle = {
        backgroundColor: bookingInfo === "Booked-Not Full" ? "rgba(17, 115, 180, 0.2)" : "rgba(180, 41, 17, 0.6)"
    };

    const deleteUser = async () => {
        // Display confirmation alert
        const confirmDelete = window.confirm("Are you sure you want to delete this user?");

        if (confirmDelete) {
            try {
                const data = await axios.delete(`${process.env.REACT_APP_BEURL}/user/${privateID}`);
                // Notify user of successful deletion with custom configuration
                setDeletedUser(!deletedUser);
                // Show toast notification after a delay
                toast.success('User deleted successfully', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            } catch (error) {
                console.error('Error while deleting the user:', error);
                // Notify user of deletion failure
                toast.error('Failed to delete user', {
                    position: "bottom-right"
                });
            }
        }
    };

    return (
        <>
            <td>
                <div className="d-flex align-items-center">
                    <img
                        src={avatarSrc}
                        alt=""
                        style={{ width: 45, height: 45 }}
                        className="rounded-circle"
                    />
                    <div className="ms-3">
                        <p className="fw-bold mb-1">{userName}</p>
                    </div>
                </div>
            </td>
            <td>{email}</td>
            <td>{totalSpent}</td>
            {showBookingInfo && (
                <td>
                    <span className="badge rounded-pill tabelBadge text-dark" style={badgeStyle}>{bookingInfo}</span>
                </td>
            )}
            <td>
                {
                  action &&(<button onClick={deleteUser} className="btn btn-danger">Delete</button>)
                }
                
                <Toaster />
            </td>
        </>
    );
};

export default UserRow;

import React, { useState } from 'react'
import './Icon.css'
import { GoogleLogin } from "@react-oauth/google";
import {jwtDecode} from "jwt-decode";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Cookies from 'universal-cookie';

export default function Icon() {

  const navigate = useNavigate();
  const cookies = new Cookies();
  const [invalidData, setInvalidData] = useState(false);
  const location = useLocation();

  const create = async (user) => {
        try {
          console.log(user)
          const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/signUpWithGoogle`, {
            signUpUserName: user.given_name,
            signUpEmail: user.email,
            signUpPassword: user.sub,
            confirmPassword: user.sub,
            gender: "Male",
            age: 20
          });
          // console.log(response,5);
          if (response?.data.message == "signed up succesfuly") {
            toast.success('sign up successfuly', {
              position: "top-right",
              autoClose: 500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
            navigate('/moreInfo')
          }
          else if (response?.data.message == "email is already exists") {
            setInvalidData(true);
          }
    
        } catch (error) {
          console.error('Login error:', error);
          toast.error('Failed to login. Please try again.', {
            position: "top-right",
            autoClose: 500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
        }
      };
   
  const log = async (user) => {
    try {
      console.log(user)
      const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/signInWithGoogle`, { signInEmail: user.email, signInPassword: user.sub });
      console.log(response)
      if (response?.data.message == " loged in successfully ") {
        cookies.set('userToken', {token : response.data.token,role :response.data.role}, { path: '/', secure: true, serverOnly: true }); // response.data.token
        toast.success('Login successful', {
          position: "top-right",
          autoClose: 500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        // Delay navigation and refresh after toast display
        setTimeout(() => {
          navigate('/');
          window.location.reload();
        }, 1000); // Adjust the delay time as needed
        if (response.data.role=="stadiumDashboard")
          {
            navigate('/addStadiumInfo');
          }
          else{
        navigate('/');
          }
        window.location.reload();
      }
      else if (response?.data.message == "plz confirm your email") {
        navigate('/confirmEmail', { state: { email: user.email } });
      }
      else if (response?.data.message == "invalid data") {
        setInvalidData(true);
      }
    } catch (error) {
      console.error('Login error:', error);
      toast.error('Failed to login. Please try again.', {
        position: "top-right",
        autoClose: 500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };


  return (
    <div className=' d-flex  raw justify-content-center align-content-center  col-sm-10 mx-auto' >
      {/* <button className='square  button4 '   >
        <i className="fa-brands fa-facebook icon2"></i>
      </button>
      <button className='square   button4 ' >
        <i className=" fa-brands fa-apple icon3"></i>
      </button> */}
      {/* <button className='square  button4 ' onClick={} >
        <svg stroke="currentColor" className='uu' fill="currentColor" stroke-width="0" version="1.1" x="0px" y="0px" class="google-icon icon" viewBox="0 0 48 48" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
          <path fill="#FFC107" d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
	c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24
	c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"></path>
          <path fill="#FF3D00" d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657
	C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"></path>
          <path fill="#4CAF50" d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36
	c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"></path>
          <path fill="#1976D2" d="M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571
	c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"></path>
        </svg>
      </button> */}
       <GoogleLogin
      onSuccess={(credentialResponse) => {
        const credentialResponseDecoded = jwtDecode(credentialResponse.credential);
        location.pathname == "/login" ? log(credentialResponseDecoded)
         : create(credentialResponseDecoded);
        
      }}
      
      onError={() => {
        console.log("Login Failed");
      }}
    />
     {
            invalidData && (
              <p className="text-center text-danger">email already exists</p>
            )
          }
    </div>
 
   
    

  )
}


// onSubmit: values => {
//   
// }


// aud
// : 
// "122388831154-22a1d32n9l6ucgo6jtmd1lsmc1ta3srk.apps.googleusercontent.com"
// azp
// : 
// "122388831154-22a1d32n9l6ucgo6jtmd1lsmc1ta3srk.apps.googleusercontent.com"
// email
// : 
// "hossam.15.2004@gmail.com"
// email_verified
// : 
// true
// exp
// : 
// 1716211554
// family_name
// : 
// "Shehadeh"
// given_name
// : 
// "Hossam"
// iat
// : 
// 1716207954
// iss
// : 
// "https://accounts.google.com"
// jti
// : 
// "9db99fd5ba290a447defc09603df3cf56db29351"
// name
// : 
// "Hossam Shehadeh"
// nbf
// : 
// 1716207654
// picture
// : 
// "https://lh3.googleusercontent.com/a/ACg8ocKf5eMYQ75axrMD9YNLq4gHxSwTqWXm0v-Jx3khF45YhwxQBA=s96-c"
// sub
// : 
// "117989183145280159699"
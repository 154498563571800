import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { useTranslation } from 'react-i18next';
const Header = ({text}) => {
    const { t } = useTranslation();
    return (
        <div className="container">
            <div className="row justify-content">
                <div className="col-12 col-md-6">
                    <p className="h2 text mt-4 ml-10">{text} {t("AddStadiumTranslation:title")}</p>
                    <hr style={{ borderColor: 'green', borderStyle: 'dashed', borderWidth: '2px' }} />
                </div>
            </div>
        </div>
    );
}

export default Header;

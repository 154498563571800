import React from "react";
import "./HomeBody.css";
import { useTranslation } from 'react-i18next'

const HomeBody = () => {
  const {t} = useTranslation();
  return (
    <>
    <section className="d-md-flex align-items-md-center justify-content-md-around">
      <div className="container-img pt-md-5">
        <img src="assets/Soccer-bro.svg" alt="about img"/>
      </div>

      <div className="container-text pt-5">
        <h1 className="pb-1">{t("HomeBodyTranslation:about")} <span className="text-success">Co-Play</span></h1>
        <p>{t("HomeBodyTranslation:title4")}</p>
        <p>{t("HomeBodyTranslation:title5")}</p>
        <p>{t("HomeBodyTranslation:title6")}</p>
      </div>
    </section>
    </>
  );
};

export default HomeBody;

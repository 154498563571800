import React, { useContext } from 'react';
import TableOfUsers from '../TableOfUsers/TableOfUsers.js';
import { DashboardHeader } from './DashboardHeader.js';
import { StadiumContext } from '../../../Contexts/StadiumContixt.js';
import  AddStadiumInfo from './AddstadiumInfo/AddStadium.js';
import { useLocation } from 'react-router-dom';

const Dashboard = () => {
  const { stadiumData, loading } = useContext(StadiumContext);

 

  
  if ( !stadiumData?.name ) {
    return <AddStadiumInfo />;
  }

  return (
    <>
      <div className='pt-5'> <DashboardHeader stadiumData={stadiumData} /></div>
      {/* <TableOfUsers title="This Week Reservation" showBookingInfo={true} /> */}
    </>
  );
};

export default Dashboard;
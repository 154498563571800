import React from 'react';
import { Rate } from './Rate.js';
import { useTranslation } from 'react-i18next';


const AmenitiesItem = ({ text }) => (

  <div className='col'>
    <div><i className='bi bi-check-circle-fill' style={{ color: "green" }}></i> {text}</div>
  </div>
);

const Down = ({ stadiumAmenities ,aboutStadium }) => {
  const {t}=useTranslation();

  const amenities = stadiumAmenities || {}

  return (
    <div className='container d-flex flex-wrap' style={{marginBottom: "15px", marginLeft: "20px" }}>
      <div className='left container-fluid col-md-6 mb-5' style={{ marginLeft: "0px" }}>
        <Rate/>
        <div className='row'>
          <div className='col' style={{ fontWeight: "bold" }}>
          {t("StadiumPageTranslation:Amenities")}
          </div>
        </div >
        {amenities.map((amenity, index) => (
          <div key={index} className='row'>
            <AmenitiesItem text={amenity} />
          </div>
        ))}
      </div>
      <div className='right container-fluid col-md-6' style={{ marginBottom: "0px" }}>
        <div style={{ fontWeight: "bold" }}>{t("StadiumPageTranslation:Aboutstadium")}</div>
        <ul>
          <li>{aboutStadium}</li>
        </ul>
      </div>
    </div>
  );
};

export default Down;
import React from 'react';

const ImagePreviewList = ({ images, handleImageDelete, t }) => {
    return (
        <div className="d-flex flex-wrap">
            {images.map((image, index) => (
                <div key={image?.public_id ?image?.public_id : index } className="image-preview-container me-3 mb-3">
                    <div className="image-container mt-3" style={{ position: 'relative', display: 'inline-block' }}>
                        <img src={image.secure_url} alt={`Image ${index + 1}`} className="image-preview" style={{ width: '250px', height: '200px' }} />
                        <button type="button" className="btn btn-danger btn-sm delete-image-button" style={{ position: 'absolute', top: '5px', right: '5px' }} onClick={() => handleImageDelete(image?.public_id)}>{t("AddStadiumTranslation:Delete")}</button>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ImagePreviewList;

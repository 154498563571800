import React, { useEffect, useRef } from 'react';
import './AddImage.css';
import { useTranslation } from 'react-i18next';

const FileUploadComponent = ({ onFileSelect, files, setFiles }) => {
    const { t } = useTranslation();
    const uploadZoneRef = useRef(null);

    useEffect(() => {
        const preventDefaults = (event) => event.preventDefault();
        const highlight = (event) => event.currentTarget.classList.add('highlight');
        const unhighlight = (event) => event.currentTarget.classList.remove('highlight');

        const handleDrop = (event) => {
            event.preventDefault();
            const selectedFiles = [...event.dataTransfer.files];
            setFiles(selectedFiles);
            onFileSelect(selectedFiles);
            previewFiles(selectedFiles);
        };

        const handleInputChange = (event) => {
            const selectedFiles = [...event.target.files];
            const totalFiles = files.length + selectedFiles.length;

            // Check if the total number of files exceeds 5
            if (totalFiles > 5) {
                // Notify the user that they can only select up to 5 images
                alert("You can only upload up to 5 images.");
                return;
            }

            // Check if the total number of files is less than or equal to 5
            if (totalFiles <= 5) {
                // Add the selected files to the state
                setFiles(prevFiles => [...prevFiles, ...selectedFiles]);
                // Call the onFileSelect callback with the selected files
                onFileSelect(selectedFiles);
                // Preview the selected files
                previewFiles(selectedFiles);
            }
        };

        const setupEventHandlers = () => {
            const zone = uploadZoneRef.current;
            if (!zone) return;

            ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
                zone.addEventListener(eventName, preventDefaults);
                zone.addEventListener(eventName, highlight);
            });

            ['dragleave', 'drop'].forEach((eventName) => {
                zone.addEventListener(eventName, unhighlight);
            });

            zone.addEventListener('drop', handleDrop);
            zone.querySelector('input[type="file"]').addEventListener('change', handleInputChange);
        };

        const isImageFile = (file) =>
            ['image/jpeg', 'image/png', 'image/svg+xml'].includes(file.type);

        const previewFile = (file, imageElement) => {
            if (isImageFile(file)) {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onloadend = () => {
                    imageElement.className = 'upload_img mt-2';
                    imageElement.setAttribute('alt', file.name);
                    imageElement.src = reader.result;
                };
            } else {
                console.log('Not an image, ', file.type);
            }
        };

        const previewFiles = (selectedFiles) => {
            if (!uploadZoneRef.current) return;
            const gallery = uploadZoneRef.current.querySelector('.upload_gallery');

            selectedFiles.forEach((file) => {
                const img = document.createElement('img');
                gallery.appendChild(img);
                previewFile(file, img);
            });
        };

        setupEventHandlers();

        return () => {
            const zone = uploadZoneRef.current;
            if (zone) {
                ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
                    zone.removeEventListener(eventName, preventDefaults);
                    zone.removeEventListener(eventName, highlight);
                });

                ['dragleave', 'drop'].forEach((eventName) => {
                    zone.removeEventListener(eventName, unhighlight);
                });

                zone.removeEventListener('drop', handleDrop);
                zone.querySelector('input[type="file"]').removeEventListener('change', handleInputChange);
            }
        };
    }, [onFileSelect, files]);

    return (
        <div className='container'>
            <form>
                <fieldset className="upload_dropZone text-center mb-3 p-4" ref={uploadZoneRef}>
                    <legend className="visually-hidden">{t("AddStadiumTranslation:ImageUploader")}</legend>
                    <svg className="upload_svg" width="60" height="60" aria-hidden="true"></svg>
                    <p className="small my-2">{t("AddStadiumTranslation:ImageUploaderText")}<br /><i>{t("AddStadiumTranslation:choose")}</i></p>
                    <input
                        id="upload_image_background"
                        data-post-name="image_background"
                        className="position-absolute invisible"
                        type="file"
                        multiple
                        accept="image/jpeg, image/png, image/svg+xml"
                    />
                    <label className="btn btn-upload mb-3" htmlFor="upload_image_background">{t("AddStadiumTranslation:ChooseFile")}</label>
                    <div className="upload_gallery d-flex flex-wrap justify-content-center gap-3 mb-0"></div>
                </fieldset>
            </form>
        </div>
    );
}

export default FileUploadComponent;

import React from 'react'
import Base from '../Base/Base.js'

function ForgetPassword() {
  return (
    <div>
        <Base inputType={'ForgetPasswords'}/>
    </div>

  )
}

export default ForgetPassword;
import React, { createContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from 'axios';
import { logOut } from "../Navbar/Navbar.js";
const cookies = new Cookies();

export const StadiumContext = createContext(null);

function StadiumContextProvider({ children }) {
  const [stadiumData, setStadiumData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userToken = cookies.get('userToken');
        if (userToken) {
          const response = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/specificStadium`, {
            headers: {
              authorization: `PRIVATE_USER_HIDDEN__${userToken.token}`
            }
          });
          const stadium = response.data.stadium;
          updateStadiumData({
            id: stadium?._id,
            name: stadium?.stadiumName,
            email: stadium?.email,
            image: stadium?.image[0]?.secure_url
          });
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching Stadium:', error);
        if (error.response && error.response.status === 401) {
          try {
            const refreshToken = cookies.get('refreshToken');
            if (refreshToken) {
              const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/refreshToken`, {
                refreshToken
              });
              cookies.set('userToken', { token: response.data.token, role: response.data.role }, { path: '/', secure: true, serverOnly: true });
              const refreshedResponse = await axios.get(`${process.env.REACT_APP_BEURL}/stadium/specificStadium`, {
                headers: {
                  authorization: `PRIVATE_USER_HIDDEN__${response.data.token}`
                }
              });
              const refreshedStadium = refreshedResponse.data.stadium;
              updateStadiumData({
                id: refreshedStadium?._id,
                name: refreshedStadium?.stadiumName,
                email: refreshedStadium?.email,
                image: refreshedStadium?.image[0]?.secure_url
              });
              setLoading(false);
            } else {
              console.error('No refresh token available');
              logOut();
            }
          } catch (refreshError) {
            console.error('Error refreshing token:', refreshError);
            // Handle refresh token error
          }
        }
      }
    };

    fetchData();
  }, []);

  const updateStadiumData = (newData) => {
    setStadiumData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  return (
    <StadiumContext.Provider value={{ stadiumData, loading }}>
      {children}
    </StadiumContext.Provider>
  );
}

export default StadiumContextProvider;

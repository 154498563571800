import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import './EditProfile.css';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Contexts/UserContext.js';
import { useTranslation } from 'react-i18next';
import Loading from '../../../../Loading/Loading.js';

const EditProfile = ({ setUser }) => {
    const { t } = useTranslation();

    const { userData, loading } = useContext(UserContext);
    const defaultProfilePic = 'https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg';
    const defaultCoverPic = 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9kKxFORe9pa0OhDDuriKT29YP5il_2X_Z-7FvXTDtIA&s.jpg';
    const [deleteCov, setDeleteCover] = useState(false);
    const [deletePro, setDeletePro] = useState(false);
    const navigate = useNavigate();
    const [coverPreview, setCoverPreview] = useState(null);
    const [profilePreview, setProfilePreview] = useState(null);

    useEffect(() => {
        if (!loading) {
            formik.setValues({
                userName: userData?.name || "",
                position: userData?.position || "",
                location: userData?.location || ""
            });
        }
    }, [loading, userData]);

    const handleSubmit = async (values) => {
        if (!userData?.id) {
            console.error('User ID is not defined');
            return;
        }

        const formData = new FormData();
        formData.append('userName', values.userName);
        formData.append('position', values.position);
        formData.append('location', values.location);

        if (values.ProfilePicture) {
            formData.append('profilePic', values.ProfilePicture);
        }
        if (values.CoverPicture) {
            formData.append('coverPic', values.CoverPicture);
        }
        if (deleteCov) {
            formData.append('deleteCover', 'true');
        }
        if (deletePro) {
            formData.append('deleteProfile', 'true');
        }

        try {
            const response = await axios.patch(`${process.env.REACT_APP_BEURL}/user/${userData.id}`, formData);
            navigate(`/profile/${userData.id}`);
            console.log('User data updated successfully:', response.data);
        } catch (error) {
            console.error('Error updating user information:', error);
        }
    };

    const formik = useFormik({
        initialValues: {
            userName: "",
            position: "",
            location: "",
            ProfilePicture: null,
            CoverPicture: null
        },
        onSubmit: handleSubmit,
    });

    const handleCoverChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setCoverPreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            formik.setFieldValue('CoverPicture', file);
            setDeleteCover(false);
        }
    };

    const handleProfileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setProfilePreview(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
            formik.setFieldValue('ProfilePicture', file);
            setDeletePro(false);
        }
    };

    if (loading) {
        return <Loading/>;
    } else {
        return (
            <div className="container bootstrap snippets bootdey">
                <h1 className="text-primary">{t("EditProfileTranslation:editProfile")}</h1>
                <hr />
                <div className="row">
                    <div className="col-md-10">
                        <div className="text-center">
                            <div className="cover-photo">
                                {deleteCov ? (
                                    <img src={defaultCoverPic} className="img-fluid" alt="cover" />
                                ) : (
                                    <>
                                        {coverPreview && <img src={coverPreview} className="img-fluid" alt="cover" />}
                                        {!coverPreview && <img src={userData?.coverPic?.secure_url || defaultCoverPic} className="img-fluid" alt="cover" />}
                                    </>
                                )}
                            </div>
                            <h6>{t("EditProfileTranslation:uploadCoverPhoto")}</h6>
                            <input name="CoverPicture" type="file" className="form-control" onChange={handleCoverChange} />
                            <button className='btn btn-danger' onClick={() => { setDeleteCover(true); }}>{t("EditProfileTranslation:delete")}</button>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="text-center">
                            <div className="profile-photo">
                                {deletePro ? (
                                    <img src={defaultProfilePic} className="rounded-circle" alt="avatar" />
                                ) : (
                                    <>
                                        {profilePreview && <img src={profilePreview} className="rounded-circle" alt="avatar" />}
                                        {!profilePreview && <img src={userData?.profilePic?.secure_url || defaultProfilePic} className="rounded-circle" alt="avatar" />}
                                    </>
                                )}
                            </div>
                            <h6>{t("EditProfileTranslation:uploadProfilePhoto")}</h6>
                            <input name="ProfilePicture" type="file" className="form-control" onChange={handleProfileChange} />
                            <button className='btn btn-danger' onClick={() => { setDeletePro(true); }}>{t("EditProfileTranslation:delete")}</button>
                        </div>
                    </div>
                    <div className="col-md-6 personal-info">
                        <h3>{t("EditProfileTranslation:personalInfo")}</h3>
                        <form className="form-horizontal" onSubmit={formik.handleSubmit} encType='multipart/form-data'>
                            <div className="form-group">
                                <label htmlFor="userName">{t("EditProfileTranslation:name")}</label>
                                <input
                                    id="userName"
                                    name="userName"
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.userName}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="position">{t("EditProfileTranslation:position")}</label>
                                <input
                                    id="position"
                                    name="position"
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.position}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="location">{t("EditProfileTranslation:location")}</label>
                                <input
                                    id="location"
                                    name="location"
                                    type="text"
                                    className="form-control"
                                    onChange={formik.handleChange}
                                    value={formik.values.location}
                                />
                            </div>
                            <button type="submit" className="btn btn-success">{t("EditProfileTranslation:save")}</button>
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default EditProfile;

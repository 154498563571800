import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

const PaymentModal = ({ isOpen, onClose, total }) => {
  const [formData, setFormData] = useState({
    name: '',
    cardNumber: '',
    expiration: '',
    securityCode: '',
    zipCode: ''
  });

  const [formErrors, setFormErrors] = useState({
    name: '',
    cardNumber: '',
    expiration: '',
    securityCode: '',
    zipCode: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  
    setFormErrors({
      ...formErrors,
      [name]: ''
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (validateForm()) {
     
      onClose(1);
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {};
    const { name, cardNumber, expiration, securityCode, zipCode } = formData;

    if (!name) {
      errors.name = 'Please enter the name on card';
      isValid = false;
    }

    if (!cardNumber) {
      errors.cardNumber = 'Please enter the card number';
      isValid = false;
    }

    if (!expiration) {
      errors.expiration = 'Please enter the expiration date';
      isValid = false;
    }

    if (!securityCode) {
      errors.securityCode = 'Please enter the security code';
      isValid = false;
    }

    if (!zipCode) {
      errors.zipCode = 'Please enter the ZIP/Postal code';
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  return (
    <Modal show={isOpen} onHide={() => onClose(0)}>
      <Modal.Header closeButton>
        <Modal.Title>Pay Invoice</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit} noValidate>
          <div className="form-group text-center">
          </div>
          <div className="form-group">
            <label htmlFor="cc-name" className="control-label">Name on card</label>
            <input
              id="cc-name"
              name="name"
              type="text"
              className={`form-control ${formErrors.name ? 'is-invalid' : ''}`}
              value={formData.name}
              onChange={handleInputChange}
              required
            />
            <div className="invalid-feedback">{formErrors.name}</div>
          </div>
          <div className="form-group">
            <label htmlFor="cc-number" className="control-label">Card number</label>
            <input
              id="cc-number"
              name="cardNumber"
              type="tel"
              className={`form-control ${formErrors.cardNumber ? 'is-invalid' : ''}`}
              value={formData.cardNumber}
              onChange={handleInputChange}
              required
            />
            <div className="invalid-feedback">{formErrors.cardNumber}</div>
          </div>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="cc-exp" className="control-label">Expiration</label>
                <input
                  id="cc-exp"
                  name="expiration"
                  type="tel"
                  className={`form-control ${formErrors.expiration ? 'is-invalid' : ''}`}
                  value={formData.expiration}
                  onChange={handleInputChange}
                  required
                />
                <div className="invalid-feedback">{formErrors.expiration}</div>
              </div>
            </div>
            <div className="col-6">
              <label htmlFor="x_card_code" className="control-label">Security code</label>
              <div className="input-group">
                <input
                  id="x_card_code"
                  name="securityCode"
                  type="tel"
                  className={`form-control ${formErrors.securityCode ? 'is-invalid' : ''}`}
                  value={formData.securityCode}
                  onChange={handleInputChange}
                  required
                />
                <div className="input-group-addon">
                  <span className="fa fa-question-circle fa-lg"></span>
                </div>
                <div className="invalid-feedback">{formErrors.securityCode}</div>
              </div>
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="x_zip" className="control-label">Postal code</label>
            <input
              id="x_zip"
              name="zipCode"
              type="text"
              className={`form-control ${formErrors.zipCode ? 'is-invalid' : ''}`}
              value={formData.zipCode}
              onChange={handleInputChange}
              required
            />
            <div className="invalid-feedback">{formErrors.zipCode}</div>
          </div>
          <button type="submit" className="btn btn-lg btn-success btn-block">
            Pay ${total}.00
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default PaymentModal;

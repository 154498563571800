import React from 'react';

const AmenitiesSelector = ({ selectedAmenities, handleAmenityChange, removeAmenity, t }) => {
    const amenitiesList = [
        { label: 'Parking', value: 'Parking' },
        { label: 'Drinking Water', value: 'Drinking Water' },
        { label: 'Changing Room', value: 'Changing Room' },
        { label: 'First Aid', value: 'First Aid' }
    ];

    return (
        <div className="card-body">
            <h5 className="card-title">{t("AddStadiumTranslation:Amenities")}</h5>
            <select className="form-select green-border mb-3 mb-md-0 custom-input" onChange={handleAmenityChange}>
                <option value="">{t("AddStadiumTranslation:ChooseAmenities")}</option>
                {amenitiesList.map((amenity, index) => (
                    <option key={index} value={amenity.value}>{amenity.label}</option>
                ))}
            </select>
            <ul className="list-unstyled mt-3">
                {selectedAmenities.map((amenity, index) => (
                    <li key={index} className="d-flex justify-content-between align-items-center mb-2">
                        <span>{amenity}</span>
                        <button type="button" className="btn btn-sm btn-danger ms-2" onClick={() => removeAmenity(index)}>{t("AddStadiumTranslation:RemoveAmenities")}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default AmenitiesSelector;

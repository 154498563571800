import React, { useState } from 'react';
import "./Images.css";

function Images({ stadiumData }) {
    // List of image URLs
    const images = stadiumData || [];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const handlePrevClick = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNextClick = () => {
        setCurrentImageIndex(prevIndex => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <>
            <div className="custom-container" >
                <div className="custom-row">
                    <div id="custCarousel" className="carousel slide" data-ride="carousel" align="center">
                        {/* Slides */}
                        <div className="big-image container position-relative mt-4 mr-4" style={{ overflow: 'hidden', borderRadius: '20px' }}>
                            {images.length > 1 && (
                                <button className="btn btn-primary position-absolute start-0 translate-middle-y border-0 right-left-buttonss" onClick={handlePrevClick} style={{ zIndex: '1', opacity: '1', width: '50px', height: '100%', marginLeft: "11px", backgroundColor: "transparent", borderRadius: '20px' }}>
                                    <i className="bi bi-arrow-left"></i>
                                </button>
                            )}
                            <img src={images[currentImageIndex]?.secure_url} alt="Current" className="img-fluid" style={{ width: '100%', height: '100%', borderRadius: '20px' }} />
                            {images.length > 1 && (
                                <button className="btn btn-primary position-absolute end-0 translate-middle-y border-0 right-left-buttonss" onClick={handleNextClick} style={{ zIndex: '1', opacity: '1', width: '50px', height: '100%', marginRight: "11px", backgroundColor: "transparent", borderRadius: '20px' }}>
                                    <i className="bi bi-arrow-right"></i>
                                </button>
                            )}
                        </div>
                        {/* Thumbnails */}
                        {images.length > 1 && (
                            <ol className="carousel-indicators custom-list-inline mx-auto" >
                                {images.map((image, index) => (
                                    <li key={index} className={`list-inline-item ${index === currentImageIndex ? 'active' : ''}`}>
                                        <a
                                            id={`carousel-selector-${index}`}
                                            data-slide-to={index}
                                            data-target="#custCarousel"
                                            onClick={() => setCurrentImageIndex(index)}
                                        >
                                            <img src={image.secure_url} className="img-fluid" alt={image.alt} style={{ cursor: 'pointer', width: '100%', height: '100%', borderRadius: '10px' }} />
                                        </a>
                                    </li>
                                ))}
                            </ol>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Images;

import React, { useEffect, useState } from 'react';
import './Badmintion.css';
import { useLocation, useParams } from 'react-router-dom';
import { ListItem } from './ListItem.js';
import { ListItemWithProfile } from './ListItemWithProfile.js';
import axios from 'axios';
import Loading from '../../../../Loading/Loading.js';
import { FormatTimeRange } from '../Play Page/FormatTimeRange.js';



const Badminton = ({ user }) => {

    const { idReservation } = useParams();
    const location = useLocation();
    const [listPlayers, setListPlayers] = useState(null);
    const { stadiumName, stadiumLocation, startDate, endDate, lengthPlayerJoined, userName, profilePic, idList, userId } = location.state;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/playPage/listOfPlayers/${idReservation}`);
                if (response.data && response.data.listOfPlayers) {
                    setListPlayers(response.data.listOfPlayers);
                } else {
                    console.error("Expected 'listOfPlayers' to be an object, but received:", response.data);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchData();
    }, [idReservation]);

    if (!listPlayers) {
        return <Loading />;
    }

    const positions = [
        { name: 'Goalkeeper', playersLeft: listPlayers.Goalkeeper },
        { name: 'Defender', playersLeft: listPlayers.Defender },
        { name: 'Midfielder', playersLeft: listPlayers.Midfielder },
        { name: 'Attacker', playersLeft: listPlayers.Attacker },
        { name: 'Other', playersLeft: 5 }
    ];
    return (
        <div className="badminton-info mt-5">
            <h2 className='Badminton'>{stadiumName}</h2>
            <ul className='ul-Badmintion'>
                <ListItem icon="clock" text={FormatTimeRange(endDate, startDate)} />
                <ListItem icon="location" text={stadiumLocation} />
                <ListItem icon="players" text={lengthPlayerJoined} />
                <ListItemWithProfile
                    imageUrl={profilePic}
                    playerName={userName}
                    positions={positions}
                    idList={idList}
                    user={user}
                    userId={userId}
                />
            </ul>
        </div>
    );
}


export default Badminton;

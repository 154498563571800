import * as yup from 'yup';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).*$/;

export const loginSchema = yup.object({

    email: yup.string().required("email is required").email("Invalid email format"),
    password: yup.string()
        .required("password is required")
        .matches(passwordRegex, "Password must contain at least one capital letter, one small letter, and one symbol")
        .min(6, "min is 6")
        .max(50, "max is 50"),
});

export const registerSchema = yup.object().shape({
    userName: yup.string().required("Username is required").min(3, "Minimum length is 3").max(30, "Maximum length is 30"),
    email: yup.string().required("Email is required").email("Invalid email format"),
    password: yup.string()
        .required("Password is required")
        .matches(passwordRegex, "Password must contain at least one capital letter, one small letter, and one symbol")
        .min(6, "Minimum length is 6")
        .max(50, "Maximum length is 50"),
    confirmPassword: yup.string()
        .required("Confirm password is required")
        .oneOf([yup.ref('password'), null], "Passwords must match"),

});

export const MoreInfo = yup.object({
    bio: yup.string().min(3, "Min length is 3").max(30, "Max length is 30"),
    location: yup.string().min(3, "Min length is 3").max(30, "Max length is 30"),
    phone: yup.string().min(3, "Min length is 3").max(30, "Max length is 30"),
});

export const StadiumSchema = yup.object({
    email: yup.string().required("email is required ").email(),
    password: yup.string().required("password is required").min(6, "min is 6").max(50, "max is 50"),
})

export const ForgetPassword = yup.object({
    email: yup.string().required("email is required").email("Invalid email format")
});


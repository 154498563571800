import React, { createContext, useState, useEffect } from "react";
import Cookies from "universal-cookie";
import axios from 'axios';
import { logOut } from "../Navbar/Navbar.js";
import FingerprintJS from '@fingerprintjs/fingerprintjs';

export const UserContext = createContext(null);

function UserContextProvider({ children }) {
  const [visitorId, setVisitorId] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const cookies = new Cookies();

  useEffect(() => {
    const fetchVisitorId = async () => {
      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();
        setVisitorId(result.visitorId);
      } catch (error) {
        console.error('Failed to get fingerprint:', error);
      }
    };

    fetchVisitorId();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      if (!visitorId) return;

      try {
        const userToken = cookies.get('userToken');
        if (userToken) {
          const response = await axios.get(`${process.env.REACT_APP_BEURL}/user/profile`, {
            headers: {
              authorization: `PRIVATE_USER_HIDDEN__${userToken.token}`,
              visitorId
            }
          });
          const user = response.data?.user;
          updateUserData({
            id: user?._id,
            position: user?.position,
            location: user?.location,
            name: user?.userName,
            profilePic: user?.profilePic,
            coverPic: user?.coverPic,
            verified: user?.verified,
            createdAt: user?.createdAt,
            email: user?.email,
          });

          if (response.data.message === "catch error") {
            logOut();
          }
        }
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [visitorId]);

  const handleError = async(error) => {
    if (error.response && error.response.status === 401) {
      try {
        const refreshToken = cookies.get('refreshToken');
        if (refreshToken) {
          const response = await axios.post(`${process.env.REACT_APP_BEURL}/auth/refreshToken`, {
            refreshToken
          });

          updateTokens(response.data.token, response.data.refreshToken);

          const userDataResponse = await axios.get(`${process.env.REACT_APP_BEURL}/user/profile`, {
            headers: {
              authorization: `PRIVATE_USER_HIDDEN__${response.data.token}`
            }
          });
          setUserData(userDataResponse.data.user);
        } else {
          logOut();
        }
      } catch (refreshError) {
        console.log('Error refreshing token:', refreshError);
        logOut();
      }
    } else {
      console.log('Error fetching user profile:', error);
    }
  };

  const updateTokens = (token, refreshToken) => {
    cookies.set('userToken', { token, role: userData.role }, { path: '/', secure: true, sameSite: 'strict' });
    cookies.set('refreshToken', refreshToken, { path: '/', secure: true, sameSite: 'strict' });
  };
  

  const updateUserData = (newData) => {
    setUserData(prevData => ({
      ...prevData,
      ...newData
    }));
  };

  return (
    <UserContext.Provider value={{ userData, loading ,visitorId}}>
      {children}
    </UserContext.Provider>
  );
}

export default UserContextProvider;

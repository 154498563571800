import { useContext, useState ,useEffect} from "react";
import Sidebar from "./SidebarProfile/SidebarProfile.js";
import EmailForm from "./EmailForm/EmailForm.js";
import PasswordForm from "./PasswordForm/PasswordForm .js";
import PaymentDetails from "./PaymentDetails/PaymentDetails .js";
import DeleteProfile from "./DeleteProfile/DeleteProfile.js";
import {StadiumContext} from "../Contexts/StadiumContixt.js";
import {UserContext} from "../Contexts/UserContext.js";
import { useTranslation } from 'react-i18next';
import "./Settings.css";


const Settings = () => {
    const {t} =useTranslation();
    const stadiumContext = useContext(StadiumContext);
    const userContext = useContext(UserContext);
    const userData = userContext ? userContext.userData : null; 
    const stadiumData = stadiumContext? stadiumContext.stadiumData : null; 
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [profilePicture, setProfilePicture] = useState('')
    const [id,setId]=useState('');
    const initialActiveForm = t('SettingsTranslation:Security');
    const [activeForm, setActiveForm] = useState(initialActiveForm);
    const [isUser, setIsUser] = useState(true)
    
    useEffect(() => {
        if (userData) {
            setEmail(userData?.email);
            setName(userData?.name);
            setProfilePicture(userData?.profilePic?.secure_url);
            setId(typeof userData.id === 'object' ? userData.id._id : userData.id);
            setIsUser(true)
        } else if (stadiumData) {
            setEmail(stadiumContext?.stadiumData?.email);
            setName(stadiumContext?.stadiumData?.name);
            setProfilePicture(stadiumData?.image);
            setId(typeof stadiumData.id === 'object' ? stadiumData.id._id : stadiumData.id);
            setIsUser(false)
        }
    }, [userData, stadiumData]);
    
    return (
        <div className="container settings-profile">
            <div className="row">
                <Sidebar activeForm={activeForm} setActiveForm={setActiveForm} email={email} name={name} profilePicture={profilePicture}/>
                <div className="col-lg-8 col-xl-9">
                    <div className="d-grid mb-0 d-lg-none w-100">
                        <button
                            className="btn btn-primary mb-4 settings-button"
                            type="button"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#offcanvasSidebar"
                            aria-controls="offcanvasSidebar"
                        >
                            <i className="fas fa-sliders-h" /> {t('SettingsTranslation:Menu')}
                        </button>
                    </div>
                    <div className="vstack gap-4">
                        {activeForm === t('SettingsTranslation:Security') && (
                            <>
                                <EmailForm email={email} setEmail={setEmail} isUser={isUser} id={id} />
                                <PasswordForm email={email} isUser={isUser} id={id} />
                                
                            </>
                        )}
                        {activeForm === t('SettingsTranslation:PaymentDetails') && <PaymentDetails />}
                        {activeForm === t('SettingsTranslation:deleteProfile') && <DeleteProfile isUser={isUser} id={id} />}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Settings;


// import './Settings.css'
// const Settings = () => {

//     return (
//         <div className="container settings-profile">
//             <div className="row">
//                 {/* Sidebar START */}
//                 <div className="col-lg-4 col-xl-3">
//                     {/* Responsive offcanvas body START */}
//                     <div
//                         className="offcanvas-lg offcanvas-end"
//                         tabIndex={-1}
//                         id="offcanvasSidebar"
//                     >
//                         {/* Offcanvas header */}
//                         <div className="offcanvas-header justify-content-end pb-2">
//                             <button
//                                 type="button"
//                                 className="btn-close"
//                                 data-bs-dismiss="offcanvas"
//                                 data-bs-target="#offcanvasSidebar"
//                                 aria-label="Close"
//                             />
//                         </div>
//                         {/* Offcanvas body */}
//                         <div className="offcanvas-body p-3 p-lg-0">
//                             <div className="card bg-light w-100">
//                                 {/* Edit profile button */}
//                                 <div className="position-absolute top-0 end-0 p-3">
//                                     <a
//                                         href="#"
//                                         className="text-primary-hover"
//                                         data-bs-toggle="tooltip"
//                                         data-bs-title="Edit profile"
//                                     >
//                                         <i className="bi bi-pencil-square" />
//                                     </a>
//                                 </div>
//                                 {/* Card body START */}
//                                 <div className="card-body p-3">
//                                     {/* Avatar and content */}
//                                     <div className="text-center mb-3">
//                                         {/* Avatar */}
//                                         <div className="avatar avatar-xl mb-2">
//                                             <img
//                                                 className="avatar-img profile-img rounded-circle border border-2 border-white"
//                                                 src="assets/a.jpeg"
//                                                 alt="User avatar"
//                                             />
//                                         </div>
//                                         <h6 className="mb-0">Amro Eid</h6>
//                                         <a href="#" className="text-reset text-primary-hover small">
//                                             amro.eidd@gmail.com
//                                         </a>
//                                         <hr />
//                                     </div>
//                                     {/* Sidebar menu item START */}
//                                     <ul className="nav nav-pills-primary-soft flex-column">
//                                         <li className="nav-item">
//                                             <a className="nav-link active" href="account-profile.html">
//                                                 <i className="bi bi-person fa-fw me-2" />
//                                                 My Profile
//                                             </a>
//                                         </li>

//                                         <li className="nav-item">
//                                             <a className="nav-link" href="account-payment-details.html">
//                                                 <i className="bi bi-wallet fa-fw me-2" />
//                                                 Payment Details
//                                             </a>
//                                         </li>

//                                         <li className="nav-item">
//                                             <a className="nav-link" href="account-settings.html">
//                                                 <i className="bi bi-gear fa-fw me-2" />
//                                                 Settings
//                                             </a>
//                                         </li>
//                                         <li className="nav-item">
//                                             <a className="nav-link" href="account-delete.html">
//                                                 <i className="bi bi-trash fa-fw me-2" />
//                                                 Delete Profile
//                                             </a>
//                                         </li>
//                                         <li className="nav-item">
//                                             <a
//                                                 className="nav-link text-danger bg-danger-soft-hover"
//                                                 href="#"
//                                             >
//                                                 <i className="fas fa-sign-out-alt fa-fw me-2" />
//                                                 Sign Out
//                                             </a>
//                                         </li>
//                                     </ul>
//                                     {/* Sidebar menu item END */}
//                                 </div>
//                                 {/* Card body END */}
//                             </div>
//                         </div>
//                     </div>
//                     {/* Responsive offcanvas body END */}
//                 </div>
//                 {/* Sidebar END */}
//                 {/* Main content START */}
//                 <div className="col-lg-8 col-xl-9">
//                     {/* Offcanvas menu button */}
//                     <div className="d-grid mb-0 d-lg-none w-100">
//                         <button
//                             className="btn btn-primary mb-4 "
//                             type="button"
//                             data-bs-toggle="offcanvas"
//                             data-bs-target="#offcanvasSidebar"
//                             aria-controls="offcanvasSidebar"
//                         >
//                             <i className="fas fa-sliders-h" /> Menu
//                         </button>
//                     </div>
//                     <div className="vstack gap-4">

//                         {/* Update email START */}
//                         <div className="card border">
//                             {/* Card header */}
//                             <div className="card-header border-bottom">
//                                 <h4 className="card-header-title">Update email</h4>
//                                 <p className="mb-0">
//                                     Your current email address is{" "}
//                                     <span className="text-primary">example@gmail.com</span>
//                                 </p>
//                             </div>
//                             {/* Card body START */}
//                             <div className="card-body">
//                                 <form>
//                                     {/* Email */}
//                                     <label className="form-label">
//                                         Enter your new email id<span className="text-danger">*</span>
//                                     </label>
//                                     <input
//                                         type="email"
//                                         className="form-control"
//                                         placeholder="Enter your email id"
//                                     />
//                                     <div className="text-end mt-3">
//                                         <button className="btn btn-primary mb-0 settings-button">
//                                             Save Email
//                                         </button>
//                                     </div>
//                                 </form>
//                             </div>
//                             {/* Card body END */}
//                         </div>
//                         {/* Update email END */}
//                         {/* Update Password START */}
//                         <div className="card border">
//                             {/* Card header */}
//                             <div className="card-header border-bottom">
//                                 <h4 className="card-header-title">Update Password</h4>
//                                 <p className="mb-0">
//                                     Your current email address is{" "}
//                                     <span className="text-primary">example@gmail.com</span>
//                                 </p>
//                             </div>
//                             {/* Card body START */}
//                             <form className="card-body">
//                                 {/* Current password */}
//                                 <div className="mb-3">
//                                     <label className="form-label">Current password</label>
//                                     <input
//                                         className="form-control"
//                                         type="password"
//                                         placeholder="Enter current password"
//                                     />
//                                 </div>
//                                 {/* New password */}
//                                 <div className="mb-3">
//                                     <label className="form-label"> Enter new password</label>
//                                     <div className="input-group">
//                                         <input
//                                             className="form-control fakepassword"
//                                             placeholder="Enter new password"
//                                             type="password"
//                                             id="psw-input"
//                                         />
//                                         <span className="input-group-text p-0 bg-transparent">
//                                             <i className="fakepasswordicon fas fa-eye-slash cursor-pointer p-2" />
//                                         </span>
//                                     </div>
//                                 </div>
//                                 {/* Confirm */}
//                                 <div className="mb-3">
//                                     <label className="form-label">Confirm new password</label>
//                                     <input
//                                         className="form-control"
//                                         type="password"
//                                         placeholder="Confirm new password"
//                                     />
//                                 </div>
//                                 <div className="text-end">
//                                     <button className="btn btn-primary mb-0 settings-button">
//                                         Change Password
//                                     </button>
//                                 </div>
//                             </form>
//                             {/* Card body END */}
//                         </div>
//                         {/* Update Password END */}
//                     </div>
//                 </div>
//                 {/* Main content END */}
//             </div>
//         </div>

//     )
// }

// export default Settings

import React, { useState, useEffect } from 'react';
import ProfileHeader from '../../Player/Profile/ProfileHeader/ProfileHeader.js'
import "./ProfilePostsPage.css"
import { Outlet } from 'react-router-dom';

function ProfilePostsPage() {
    const [darkMode, setDarkMode] = useState(false);

    const toggleDarkMode = () => {
        setDarkMode(!darkMode);
    };

    useEffect(() => {
        if (darkMode) {
            document.body.style.backgroundColor = '#18191a'; // #18191a
        } else {
            document.body.style.backgroundColor = '#eee';  // #eee
        }
    }, [darkMode]);

    return (
        <>
            <div className={`all-posts-page container-fluid ${darkMode ? 'dark-mode' : ''}`}>
                <div className="row my-3">
                    <div className="col text-center">
                        <ProfileHeader darkMode={darkMode} />
                        <Outlet darkMode={darkMode} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default ProfilePostsPage;

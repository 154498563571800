import axios from 'axios';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


function ConfirmEmail() {
  const { t } = useTranslation()

  const location = useLocation();
  const email = location.state?.email;
  console.log({ "confirmEmail page": email });
  const [resendMessage, setResendMessage] = useState('');


  function openEmailApp() {
    window.open('https://mail.google.com', '_blank');
  }

  const handleResendClick = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BEURL}/auth/sendEmailFunc/${email}`);
      console.log(response);
      if (response.data.message == "successful") {
        setResendMessage('Confirmation email resent successfully!');
      } else {
        setResendMessage('Failed to resend confirmation email. Please try again.');
      }
    } catch (error) {
      console.error('Error resending confirmation email:', error);
      setResendMessage('An error occurred. Please try again.');
    }
  };
  return (
    <div>
      <div className="card rounded-5 col-sm-12 col-md-12 mt-5" style={{ backgroundColor: "var(--chat_post_back)" }}>
        <h2 className="card-title text-center mt-4 text-success">{t('InputesTranslation:Hi')}</h2>
        <p className='text-center' style={{ color: "gray" }}>{t('InputesTranslation:confirmEmailReminder')}</p>
        <i className="fa fa-envelope fa-2x text-success d-flex justify-content-center mt-3"></i>
        <h4 className="text-center mt-2" style={{ color: "var(--chat_text_color)" }} >{t('InputesTranslation:confirmEmail_plzCheck')}</h4>
        <button type="submit" className="d-flex flex-column align-items-center mt-3 btn btn-success w-50 mx-auto" onClick={openEmailApp}>{t('InputesTranslation:Open_email_App')}</button>
        <div className='mt-4'>
          <p className='text-center' style={{ color: "var(--chat_text_color)" }}>{t('InputesTranslation:confirmEmail_dontRecieve')} <button className='text-success btn btn-light' onClick={handleResendClick}>{t('InputesTranslation:click_to_resend')}</button> </p>
        </div>
        {resendMessage && <p className='text-center mt-3' style={{ color: "green" }}>{resendMessage}</p>}
        <p className='text-center mt-4 text-success' > <i className="fa-solid fa-arrow-left text" ></i><Link className='text-success' to='/login'>{t('InputesTranslation:backToLogin')}</Link></p>
      </div>
    </div>
  );
}

export default ConfirmEmail;
import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
const AddLocationModal = ({ isOpen, onClose ,locations}) => {
    
    const [locatioName, setLocationName] = useState('');
    const [timeZone, setTimeZone] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const {t} = useTranslation();
    const validationForm = () => {
        let isValid = true;
        let Errors = {};

        locations.map((location) => {
            if(location.locatioName==locatioName) {
                Errors.locatioName = t("AdminLocationTranslation:locationNameErr1");
                isValid = false;
                
            }
        });
        if (!locatioName.trim() || locatioName.trim().length < 3|| locatioName.trim().length >25){
            const temp =locatioName.trim().length;
            if(temp<=3){
            Errors.locatioName = t("AdminLocationTranslation:locationNameErr2");
            }
            else if(temp>=25){
            Errors.locatioName = t("AdminLocationTranslation:locationNameErr3");
            } 
            isValid = false;
        }
        if (!timeZone.trim() || isNaN(timeZone) || Math.abs(Number(timeZone)) >= 20) {
            Errors.timeZone = t("AdminLocationTranslation:timeZoneErr");
            isValid = false;
        }
        setErrors(Errors);
        return isValid;
    };
    const handleSubmit  = async (e) => {
        e.preventDefault();
        if(!validationForm()){return;}
        try {
            const response = await axios.post(`${process.env.REACT_APP_BEURL}/location/postLocation`, {locatioName, timeZone});
            onClose(); 
            setErrors({});
            navigate('/location'); 
        } catch (err) {
            console.log(err);
        }
    };
    if (!isOpen) return null;

    return (
        <div id='locModal'>
            <div id='locModalContent'>
                <span className="close" onClick={()=>onClose(setErrors)}>&times;</span>
                <form onSubmit={handleSubmit}>
                    <h2>{t("AdminLocationTranslation:addLocation")}</h2>
                    <div className="mb-2">
                        <label>{t("AdminLocationTranslation:locationName")}</label>
                        <input
                            type="text"
                            placeholder={t("AdminLocationTranslation:locationNamePlaceHolder")}
                            className="form-control"
                            onChange={(e) => setLocationName(e.target.value)}
                        />
                            {errors.locatioName && <div className="text-danger">{errors.locatioName}</div>}
                    </div>
                    <div className="mb-2">
                        <label>{t("AdminLocationTranslation:timeZone")}</label>
                        <input
                            type="number"
                            placeholder={t("AdminLocationTranslation:timeZonePlaceHolder")}
                            className="form-control"
                            onChange={(e) => setTimeZone(e.target.value)}
                        />
                        {errors.timeZone && <div className="text-danger">{errors.timeZone}</div>}
                    </div>
                    <button type='submit' className="btn btn-success">Submit</button>
                </form>
            </div>
        </div>
    );
};

export default AddLocationModal;

import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { MoreInfo } from "../Validation/Validation.js";
import Input from "../Input/Input.js";
import axios from "axios";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import "./../LogIn/Login.css"


function InputInformation() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [locations, setLocations] = useState([]);
    const location = useLocation();
    const email = location.state?.email;
    useEffect(() => {
        const fetchLocations = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BEURL}/location/getLocation`);
                const locationOptions = response.data.locData.map(location => ({
                    value: location.locatioName,
                    label: location.locatioName
                }));
                setLocations(locationOptions);

            } catch (error) {
                console.error("Error in fetching locations:", error);
            }
        };

        fetchLocations();
    }, []);

    const formik = useFormik({
        initialValues: {
            bio: "",
            phone: "",
            location: "",
            gender: "",
            dateOfBirth: "",
            image: null,
        },
        validationSchema: MoreInfo,
        onSubmit: async values => {
            const formData = new FormData();
            formData.append("bio", values.bio);
            formData.append("phone", values.phone);
            formData.append("location", values.location);
            formData.append("gender", values.gender);
            formData.append("dateOfBirth", values.dateOfBirth);
            formData.append("profilePic", values.image);
            // for (const [key, value] of formData.entries()) {
            //   console.log(`${key}:`, value);
            // }
            try {
                const response = await axios.patch(`${process.env.REACT_APP_BEURL}/auth/moreInfo/${email}`, formData, {});
                console.log(response);
                if (response?.data.message === "Input Information is done") {
                    toast.success('Input Information is done', {
                        position: "top-right",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                    navigate("/login");
                } else if (response?.data.message === "User not found") {
                    toast.error('Failed to add info. Please try again.', {
                        position: "top-right",
                        autoClose: 500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "dark",
                    });
                }
            } catch (error) {
                console.error('Adding info error:', error);
                toast.error('Failed to add info. Please try again.', {
                    position: "top-right",
                    autoClose: 500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                });
            }
        }
    });


    const handleLocationChange = selectedOption => {
        formik.setFieldValue("location", selectedOption ? selectedOption.value : "");
    };

    const handleFileChange = event => {
        formik.setFieldValue("image", event.currentTarget.files[0]);
    };

    const renderInput = [
        {
            id: "bio",
            type: "text",
            name: "bio",
            title: t("InputesTranslation:bio"),
        },
        {
            id: "phone",
            type: "text",
            name: "phone",
            title: t("InputesTranslation:phone"),
        },
    ].map((ele, index) => (
        <Input
            value={formik.values[ele.name]}
            type={ele.type}
            id={ele.id}
            title={ele.title}
            name={ele.name}
            onchange={ele.onchange || formik.handleChange} // Changed onchange to ele.onchange if present
            errors={formik.errors}
            touched={formik.touched}
            key={index}
        />
    ));
    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: 'black',
        }),
        singleValue: (provided) => ({
            ...provided,
            color: 'black',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected ? 'gray' : 'white',
        }),
        placeholder: (provided) => ({
            ...provided,
            color: 'black',
        }),
    };
    return (
        <div className="card rounded-5 mt-3 ooooo" style={{ backgroundColor: "var(--chat_post_back) !important" }}>
            <h2 className="card-title text-center mt-3 h2 text-success">
                {t("InputesTranslation:moreInformation")}
            </h2>
            <form onSubmit={formik.handleSubmit}>
                {renderInput}
                <div className="Regester-form-control form-control w-75 p-md-3 container" >
                    <label className="card-title text-center h5 text-success" htmlFor="location">
                        {t("InputesTranslation:location")}
                    </label>
                    <Select
                        id="location"
                        name="location"
                        options={locations}
                        onChange={handleLocationChange}
                        value={locations.find(option => option.value === formik.values.location)}
                        classNamePrefix="react-select"
                        isClearable
                        styles={customStyles}
                    />
                    {formik.errors.location ? <div className="text-danger">{formik.errors.location}</div> : null}
                </div>
                <div className="Regester-form-control form-control w-75 p-md-3 container">
                    <label className="card-title text-center h5 text-success" htmlFor="image">{t("InputesTranslation:Profile_Picture")}</label>
                    <input
                        type="file"
                        id="image"
                        name="image"
                        accept="image/*"
                        onChange={handleFileChange}
                        className="form-control kkk"
                        placeholder={t("InputesTranslation:userImage")}
                    />
                </div>
                <div className="Regester-form-control form-control w-75 p-md-3 container">
                    <label className="card-title text-center h5 text-success" htmlFor="gender">{t("InputesTranslation:Gender")}</label>
                    <select
                        id="gender"
                        name="gender"
                        value={formik.values.gender}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control kkk"
                        style={{ color: "var(--body_color)" }}
                    >
                        <option value="">{t("InputesTranslation:Select_Gender")}</option>
                        <option value="male">{t("InputesTranslation:Male")}</option>
                        <option value="female">{t("InputesTranslation:Female")}</option>
                    </select>
                    {formik.errors.gender ? <div className="text-danger">{formik.errors.gender}</div> : null}
                </div>
                <div className="Regester-form-control form-control w-75 p-md-3 container">
                    <label className="card-title text-center h5 text-success" htmlFor="dateOfBirth">{t("InputesTranslation:Date_of_Birth")}</label>
                    <input
                        id="dateOfBirth"
                        name="dateOfBirth"
                        type="date"
                        value={formik.values.dateOfBirth}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        className="form-control"
                    />
                    {formik.errors.dateOfBirth ? <div className="text-danger">{formik.errors.dateOfBirth}</div> : null}
                </div>
                <p className="text-center">
                    <Link to="/Login" className="text-success">
                        {t("InputesTranslation:skipNow")}
                    </Link>
                </p>
                <button
                    type="submit"
                    className="d-flex mt-5 mb-4 flex-column align-items-center mt-6 btn btn-success w-75 p-md-3 mx-auto"
                >
                    {t("InputesTranslation:createAccount")}
                </button>
            </form>
        </div>
    );
}

export default InputInformation;
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

const ShareModal = ({ isOpen, onShare, onClose, text }) => {
    const [newPostText, setNewPostText] = useState("");

    // Update newPostText whenever the text prop changes
    useEffect(() => {
        setNewPostText(text);
    }, [text]);

    const handleInputChange = (event) => {
        setNewPostText(event.target.value);
    };

    const handleShare = () => {
        if (newPostText.trim() !== "") {
            onShare(newPostText);
            setNewPostText("");
            onClose(); // Close the modal after sharing
        }
    };

    return (
        <Modal
            show={isOpen}
            onHide={onClose}
            contentLabel="Share Modal"
        >
            <Modal.Header closeButton>
                <Modal.Title>Share</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <textarea
                    className="w-100"
                    value={newPostText}
                    onChange={handleInputChange}
                    placeholder="Write something..."
                ></textarea>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-danger" onClick={onClose}>Close</button>
                <button className="btn btn-success" onClick={handleShare}>Share</button>
            </Modal.Footer>
        </Modal>
    );
};

export default ShareModal;

import React from 'react'
import Table_book from '../../../Player/Book/BookNow/BigCalender/Table_book.js'
import TableOfUsers from '../../TableOfUsers/TableOfUsers.js'

const Reservation = () => {
  return (
    <>
    <div className="container p-4">
    <Table_book/>
    {/* <TableOfUsers title="This Week Reservation" showBookingInfo={true}/> */}
    </div>
    </>
  )
}

export default Reservation
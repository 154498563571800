// Header.js
import  { useState } from 'react';
import './Header.css';
import {useTranslation} from 'react-i18next';

const Header = ({ onSearch, stadiumsCount,locations}) => {
    const {t} = useTranslation()
    const [city, setCity] = useState("");
    const [location, setLocation] = useState("");
    const handleSearch = () => {
        onSearch(city);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();
        }
    };
    const handleLocationChange = (e) => {
        setLocation(e.target.value);
        onSearch(e.target.value);
    };
    return (
        <header className="bookingHeader d-flex py-3">
            <ul className="nav nav-pills">
                <li className="nav-item ml-auto">
                    {t("BookTranslation:football")} ({stadiumsCount})
                </li>
            </ul>
            <div className="group ml-auto">
                <svg className="iconSearch" aria-hidden="true" viewBox="0 0 24 24">
                    <g>
                        <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z" />
                    </g>
                </svg>
                <select
                    className="input inputBookingSearch"
                    value={location}
                    onChange={handleLocationChange}
                >
                <option value="">Select Location</option>
                    {locations.map((loc) => (
                        <option key={loc.value} value={loc.value}>
                            {loc.label}
                        </option>
                    ))}
                </select>
            </div>
        </header>
    );
}

export default Header;
